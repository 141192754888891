import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Progress, Center, Text, Flex, Heading, Image, Divider, Spacer, HStack, Badge, Tabs, Tab, TabList, TabPanels, TabPanel, SimpleGrid, Modal, ModalBody, ModalFooter, ModalHeader, ModalCloseButton, ModalOverlay, useDisclosure, ModalContent } from '@chakra-ui/react'
import { isEmpty } from 'lodash'
import { BsStarFill, BsStar, BsFillPersonFill, BsHandThumbsUp, BsHandThumbsDown } from 'react-icons/bs'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const Profile = (props) => {
    let mobile = props.isMobile;
    let cart = props.cart

    const AccountWidget = () => {
        return (
            <Box bg={"rgba(0,0,0,.5)"} borderRadius={10}  p={4} m={3} >
                <Flex>
                    <Box flex={1} bg={"rgba(0,0,0,.5)"} borderRadius={40}  p={4} m={3} >
                        <BsFillPersonFill size={'lg'} />
                    </Box>
                    <Box flex={4} p={4} >
                        <Flex flexDirection={'column'}>
                            <Text fontSize={'xl'}>Hi User123,</Text>
                            <Text fontSize={'4xl'} fontWeight={'bold'}>Welcome back!</Text>
                        </Flex>
                    </Box>
                </Flex>
                <Flex>
                    {!mobile && <Box flex={1} />}
                    <Box flex={1} p={2}>
                        <Flex flexDirection={'column'} textAlign={'center'}>
                            <Text>Flower</Text>
                            <Center bg={"rgba(0,0,0,.5)"} borderRadius={20} p={4} h={'100%'} w={'100%'}>
                                <BsStarFill size={'lg'}/>
                            </Center>
                            <Center p={2}>
                                <Badge ml='1' fontSize='0.8em' colorScheme='green' w={10}>
                                    x4
                                </Badge>
                            </Center>
                        </Flex>
                    </Box>
                    <Box flex={1} p={2}>
                        <Flex flexDirection={'column'} textAlign={'center'}>
                            <Text>Edibles</Text>
                            <Center bg={"rgba(0,0,0,.5)"} borderRadius={20} p={4} h={'100%'} w={'100%'}>
                                <BsStarFill size={'lg'}/>
                            </Center>
                            <Center p={2}>
                                <Badge ml='1' fontSize='0.8em' colorScheme='purple' w={10}>
                                    x2
                                </Badge>
                            </Center>
                        </Flex>
                    </Box>
                    <Box flex={1} p={2}>
                        <Flex flexDirection={'column'} textAlign={'center'}>
                            <Text>Vapes</Text>
                            <Center bg={"rgba(0,0,0,.5)"} borderRadius={20} p={4} h={'100%'} w={'100%'} opacity={.3}>
                                <BsStarFill size={'lg'}/>
                            </Center>
                            <Center p={2}>
                                <Badge ml='1' fontSize='0.8em' colorScheme='green' w={10} opacity={0}>
                                    x2
                                </Badge>
                            </Center>
                        </Flex>
                    </Box>
                    <Box flex={1} p={2}>
                        <Flex flexDirection={'column'} textAlign={'center'}>
                            <Text>Topicals</Text>
                            <Center bg={"rgba(0,0,0,.5)"} borderRadius={20} p={4} h={'100%'} w={'100%'} opacity={.3}>
                                <BsStarFill size={'lg'}/>
                            </Center>
                            <Center p={2}>
                                <Badge ml='1' fontSize='0.8em' colorScheme='green' w={10} opacity={0}>
                                    x2
                                </Badge>
                            </Center>
                        </Flex>
                    </Box>
                    {!mobile && <Box flex={1} />}
                </Flex>
            </Box>
        )
    }

    const OrderTimeline = () => {
        return (
            <VerticalTimeline>
                <VerticalTimelineElement

                    contentStyle={{ webkitBackdropFilter: 'blur(40px)', backdropFilter: 'blur(40px)', backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
                    contentArrowStyle={{ borderRight: '7px solid  rgba(255, 255, 255, 0.2)' }}
                    date="January 2, 2022"
                    iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                    icon={<BsStar />}
                >
                    <Box>
                        <Text>RootsRx</Text>
                        <Text>Aspen, CO</Text>
                    </Box>
                    <Flex>
                        <Box>
                            <Flex flexDir={'column'}>
                                <Center p={8}>
                                    <Image src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png"/>
                                </Center>
                                <Center>
                                    <Text fontSize={'4xl'}>Orange Tangie</Text>
                                </Center>
                                <Center>
                                    <Text>Focusing Flower</Text>
                                </Center>
                                <Center>
                                    <Flex>
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStar />
                                    </Flex>    
                                </Center>
                            </Flex>
                        </Box>
                        <Box>
                            <Flex flexDir={'column'}>
                                <Center  p={8}>
                                    <Image src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png"/>
                                </Center>
                                <Center>
                                    <Text fontSize={'4xl'}>Lemon Haze</Text>
                                </Center>
                                <Center>
                                    <Text>Uplifting Flower</Text>
                                </Center>
                                <Center>
                                    <Flex>
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                    </Flex>    
                                </Center>
                            </Flex>
                        </Box>
                    </Flex>
                    
                </VerticalTimelineElement>
              
                <VerticalTimelineElement

                    contentStyle={{ webkitBackdropFilter: 'blur(40px)', backdropFilter: 'blur(40px)', backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
                    contentArrowStyle={{ borderRight: '7px solid  rgba(255, 255, 255, 0.2)' }}
                    date="January 2, 2022"
                    iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                    icon={<BsStar />}
                >
                    <Box>
                        <Text>RootsRx</Text>
                        <Text>Aspen, CO</Text>
                    </Box>
                    <Flex>
                        <Box>
                            <Flex flexDir={'column'}>
                                <Center p={8}>
                                    <Image src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png"/>
                                </Center>
                                <Center>
                                    <Text fontSize={'4xl'}>Orange Tangie</Text>
                                </Center>
                                <Center>
                                    <Text>Focusing Flower</Text>
                                </Center>
                                <Center>
                                    <Flex>
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStar />
                                    </Flex>    
                                </Center>
                            </Flex>
                        </Box>
                        <Box>
                            <Flex flexDir={'column'}>
                                <Center  p={8}>
                                    <Image src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png"/>
                                </Center>
                                <Center>
                                    <Text fontSize={'4xl'}>Lemon Haze</Text>
                                </Center>
                                <Center>
                                    <Text>Uplifting Flower</Text>
                                </Center>
                                <Center>
                                    <Flex>
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                    </Flex>    
                                </Center>
                            </Flex>
                        </Box>
                    </Flex>
                    
                </VerticalTimelineElement>
              <VerticalTimelineElement

                    contentStyle={{ webkitBackdropFilter: 'blur(40px)', backdropFilter: 'blur(40px)', backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
                    contentArrowStyle={{ borderRight: '7px solid  rgba(255, 255, 255, 0.2)' }}
                    date="January 2, 2022"
                    iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                    icon={<BsStar />}
                >
                    <Box>
                        <Text>RootsRx</Text>
                        <Text>Aspen, CO</Text>
                    </Box>
                    <Flex>
                        <Box>
                            <Flex flexDir={'column'}>
                                <Center p={8}>
                                    <Image src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png"/>
                                </Center>
                                <Center>
                                    <Text fontSize={'4xl'}>Orange Tangie</Text>
                                </Center>
                                <Center>
                                    <Text>Focusing Flower</Text>
                                </Center>
                                <Center>
                                    <Flex>
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStar />
                                    </Flex>    
                                </Center>
                            </Flex>
                        </Box>
                        <Box>
                            <Flex flexDir={'column'}>
                                <Center  p={8}>
                                    <Image src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png"/>
                                </Center>
                                <Center>
                                    <Text fontSize={'4xl'}>Lemon Haze</Text>
                                </Center>
                                <Center>
                                    <Text>Uplifting Flower</Text>
                                </Center>
                                <Center>
                                    <Flex>
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                        <BsStarFill />
                                    </Flex>    
                                </Center>
                            </Flex>
                        </Box>
                    </Flex>
                    
                </VerticalTimelineElement>
                <VerticalTimelineElement

                contentStyle={{ webkitBackdropFilter: 'blur(40px)', backdropFilter: 'blur(40px)', backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
                contentArrowStyle={{ borderRight: '7px solid  rgba(255, 255, 255, 0.2)' }}
                date="January 2, 2022"
                iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                icon={<BsStar />}
                >
                <Box>
                    <Text>RootsRx</Text>
                    <Text>Aspen, CO</Text>
                </Box>
                <Flex>
                    <Box>
                        <Flex flexDir={'column'}>
                            <Center p={8}>
                                <Image src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png"/>
                            </Center>
                            <Center>
                                <Text fontSize={'4xl'}>Orange Tangie</Text>
                            </Center>
                            <Center>
                                <Text>Focusing Flower</Text>
                            </Center>
                            <Center>
                                <Flex>
                                    <BsStarFill />
                                    <BsStarFill />
                                    <BsStarFill />
                                    <BsStarFill />
                                    <BsStar />
                                </Flex>    
                            </Center>
                        </Flex>
                    </Box>
                    <Box>
                        <Flex flexDir={'column'}>
                            <Center  p={8}>
                                <Image src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png"/>
                            </Center>
                            <Center>
                                <Text fontSize={'4xl'}>Lemon Haze</Text>
                            </Center>
                            <Center>
                                <Text>Uplifting Flower</Text>
                            </Center>
                            <Center>
                                <Flex>
                                    <BsStarFill />
                                    <BsStarFill />
                                    <BsStarFill />
                                    <BsStarFill />
                                    <BsStarFill />
                                </Flex>    
                            </Center>
                        </Flex>
                    </Box>
                </Flex>

                </VerticalTimelineElement>

                <VerticalTimelineElement
                    contentStyle={{ webkitBackdropFilter: 'blur(40px)', backdropFilter: 'blur(40px)', backgroundColor: 'rgba(255, 255, 255, 0.2)' }}
                    contentArrowStyle={{ borderRight: '7px solid  rgba(255, 255, 255, 0.2)' }}
                    iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                    icon={<BsStar />}
                >
                    First Visit!
                </VerticalTimelineElement>
            </VerticalTimeline>
        )
    }

    const AchievementWidget = () => {
        return (
            <Box>
                <Center>
                    <Text fontSize={'large'} fontWeight={'bold'}>
                        Rookie
                    </Text>
                </Center>
                <SimpleGrid columns={mobile ? 2 : 4} spacing={mobile ? 4 : 10}>
              
                    <Center flex={1}  p={2}>
                        <Flex flexDirection={'column'} textAlign={'center'}>
                            <Text>Flowerly Member</Text>
                            <Center bg={"rgba(0,0,0,.5)"} borderRadius={20} p={4} h={20} w={20}>
                                <BsStarFill size={'lg'}/>
                            </Center>
                            <Center  p={2}>
                                <Badge ml='1' fontSize='0.8em' colorScheme='green'>
                                    +10 points
                                </Badge>
                            </Center>
                        </Flex>
                    </Center>
                    <Center flex={1} p={2}>
                        <Flex flexDirection={'column'} textAlign={'center'}>
                            <Text>First Purchase</Text>
                            <Center bg={"rgba(0,0,0,.5)"} borderRadius={20} p={4} h={20} w={20}>
                                <BsStarFill size={'lg'}/>
                            </Center>
                            <Center p={2}>
                                <Badge ml='1' fontSize='0.8em' colorScheme='purple'>
                                +5 points
                                </Badge>
                            </Center>
                        </Flex>
                    </Center>
                    <Center flex={1} p={2}>
                        <Flex flexDirection={'column'} textAlign={'center'}>
                            <Text>Second Purchase</Text>
                            <Center bg={"rgba(0,0,0,.5)"} borderRadius={20} p={4} h={20} w={20} opacity={.3}>
                                <BsStarFill size={'lg'}/>
                            </Center>
                            <Center p={2}>
                                <Badge ml='1' fontSize='0.8em' colorScheme='green' opacity={0}>
                                    x2
                                </Badge>
                            </Center>
                        </Flex>
                    </Center>
                    <Center flex={1} p={2}>
                        <Flex flexDirection={'column'} textAlign={'center'}>
                            <Text>Third Purchase</Text>
                            <Center bg={"rgba(0,0,0,.5)"} borderRadius={20} p={4} h={20} w={20} opacity={.3}>
                                <BsStarFill size={'lg'}/>
                            </Center>
                            <Center p={2}>
                                <Badge ml='1' fontSize='0.8em' colorScheme='green' opacity={0}>
                                    x2
                                </Badge>
                            </Center>
                        </Flex>
                    </Center>
                    
                    <Center flex={1} p={2}>
                        <Flex flexDirection={'column'} textAlign={'center'}>
                            <Text>Flower</Text>
                            <Center bg={"rgba(0,0,0,.5)"} borderRadius={20} p={4}  h={20} w={20} opacity={.3}>
                                <BsStarFill size={'lg'}/>
                            </Center>
                            <Center p={2}>
                                <Badge ml='1' fontSize='0.8em' colorScheme='green' w={10} opacity={0}>
                                    x4
                                </Badge>
                            </Center>
                        </Flex>
                    </Center>
                    <Center flex={1} p={2}>
                        <Flex flexDirection={'column'} textAlign={'center'}>
                            <Text>Edibles</Text>
                            <Center bg={"rgba(0,0,0,.5)"} borderRadius={20} p={4}  h={20} w={20} opacity={.3}>
                                <BsStarFill size={'lg'}/>
                            </Center>
                            <Center p={2}>
                                <Badge ml='1' fontSize='0.8em' colorScheme='purple' w={10} opacity={0}>
                                    x2
                                </Badge>
                            </Center>
                        </Flex>
                    </Center>
                    <Center flex={1} p={2}>
                        <Flex flexDirection={'column'} textAlign={'center'}>
                            <Text>Vapes</Text>
                            <Center bg={"rgba(0,0,0,.5)"} borderRadius={20} p={4}  h={20} w={20} opacity={.3}>
                                <BsStarFill size={'lg'}/>
                            </Center>
                            <Center p={2}>
                                <Badge ml='1' fontSize='0.8em' colorScheme='green' w={10} opacity={0}>
                                    x2
                                </Badge>
                            </Center>
                        </Flex>
                    </Center>
                    <Center flex={1} p={2}>
                        <Flex flexDirection={'column'} textAlign={'center'}>
                            <Text>Topicals</Text>
                            <Center bg={"rgba(0,0,0,.5)"} borderRadius={20} p={4}  h={20} w={20} opacity={.3}>
                                <BsStarFill size={'lg'}/>
                            </Center>
                            <Center p={2}>
                                <Badge ml='1' fontSize='0.8em' colorScheme='green' w={10} opacity={0}>
                                    x2
                                </Badge>
                            </Center>
                        </Flex>
                    </Center>
                  
                </SimpleGrid>
            </Box>
            
        )
    }

    const SuggestionWidget = () => {
        return (
            <>
                <Flex flexDir={'column'}>
                    <Box>
                        <Text fontSize={"2xl"} fontWeight={'bold'}>
                            Just for you
                        </Text>
                        <Text fontSize={"lg"} fontWeight={'semibold'}>
                            You haven't tried this!
                        </Text>
                    </Box>
                    <Box borderRadius={20} className="glassEffect" marginTop={4}>
                        <Flex>
                            <Box flex={1} p={2}>
                                <Box>
                                    <Flex flexDir={'column'}>
                                        <Center p={8}>
                                            <Image src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png"/>
                                        </Center>
                                        <Center>
                                            <Text fontSize={'xl'}>Orange Tangie</Text>
                                        </Center>
                                        <Center>
                                            <Text>Focusing Flower</Text>
                                        </Center>
                                        
                                    </Flex>
                                </Box>
                            </Box>
                            <Center flex={1} p={2}>
                                <Text textAlign={'center'} fontWeight={'bold'}>
                                    Best new strain for creative and focused flower
                                </Text>
                            </Center>
                        </Flex>
                    </Box>
                    <Box borderRadius={20} className="glassEffect" marginTop={4}>
                        <Flex>
                            <Box flex={1} p={2}>
                                <Box>
                                    <Flex flexDir={'column'}>
                                        <Center p={8}>
                                            <Image src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png"/>
                                        </Center>
                                        <Center>
                                            <Text fontSize={'xl'}>Orange Tangie</Text>
                                        </Center>
                                        <Center>
                                            <Text>Focusing Flower</Text>
                                        </Center>
                                        
                                    </Flex>
                                </Box>
                            </Box>
                            <Center flex={1} p={2}>
                                <Text textAlign={'center'} fontWeight={'bold'}>
                                    Best new strain for creative and focused flower
                                </Text>
                            </Center>
                        </Flex>
                    </Box>
                    <Box borderRadius={20} className="glassEffect" marginTop={4}>
                        <Flex>
                            <Box flex={1} p={2}>
                                <Box>
                                    <Flex flexDir={'column'}>
                                        <Center p={8}>
                                            <Image src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png"/>
                                        </Center>
                                        <Center>
                                            <Text fontSize={'xl'}>Orange Tangie</Text>
                                        </Center>
                                        <Center>
                                            <Text>Focusing Flower</Text>
                                        </Center>
                                        
                                    </Flex>
                                </Box>
                            </Box>
                            <Center flex={1} p={2}>
                                <Text textAlign={'center'} fontWeight={'bold'}>
                                    Best new strain for creative and focused flower
                                </Text>
                            </Center>
                        </Flex>
                    </Box>
                </Flex>
            </>
        )
    }

    const ProfilePreferences = () => {
        return (
            <Box bg={"rgba(0,0,0,.5)"} borderRadius={10}  p={2} m={3} >
                <Flex>
                    <Text fontSize={'2xl'} fontWeight={'bold'}>Profile Preferences</Text>
                    <Spacer />
                    <Button variant={'link'}>
                        edit
                    </Button>
                </Flex>
                <Flex>
                    <Box flex={1} p={6} >
                        <Flex flexDir={'column'}>
                            <Center fontSize={'large'} fontWeight={'bold'}>
                                shorter
                            </Center>
                            <Progress colorScheme='green' size='md' value={20} borderRadius={10}/>
                            <Text>duration</Text>
                        </Flex>
                    </Box>
                    <Box flex={1} p={6}>
                        <Flex flexDir={'column'}>
                            <Center fontSize={'large'} fontWeight={'bold'}>
                                stronger
                            </Center>
                            <Progress colorScheme='green' size='md' value={80} borderRadius={10}/>
                            <Text>strength</Text>
                        </Flex>
                    </Box>
                    <Box flex={1} p={6}>
                        <Flex flexDir={'column'}>
                            <Center fontSize={'large'} fontWeight={'bold'}>
                                moderate
                            </Center>
                            <Progress colorScheme='green' size='md' value={60} borderRadius={10}/>
                            <Text>experience</Text>
                        </Flex>
                    </Box>
                </Flex>
            </Box>
        )
    }

    const YourGoTos = () => {
        return (
            <Box bg={"rgba(0,0,0,.5)"} borderRadius={10}  p={2} m={3} >
                <Flex>
                    <Text fontSize={'2xl'} fontWeight={'bold'}>Your Go To's</Text>
                    <Spacer />
                    <Button variant={'link'}>
                        adjust
                    </Button>
                </Flex>
                <Flex>
                    <Box flex={1} p={6} >
                        <Flex >
                            <Text fontSize={'8xl'} fontWeight={'bold'} p={4}>
                                1
                            </Text>
                            <Center>
                                <Text fontSize={'large'} fontWeight={'bold'}>Uplifting flower for creative mornings</Text>
                            </Center>
                        </Flex>
                    </Box>
                    <Box flex={1} p={6}>
                        <Flex >
                            <Text fontSize={'8xl'} fontWeight={'bold'} p={4}>
                                2
                            </Text>
                            <Center>
                                <Text fontSize={'large'} fontWeight={'bold'}>Calming edibles for relaxing afternoons</Text>
                            </Center>
                        </Flex>
                    </Box>
                    <Box flex={1} p={6}>
                        <Flex >
                            <Text fontSize={'8xl'} fontWeight={'bold'} p={4}>
                                3
                            </Text>
                            <Center>
                                <Text fontSize={'large'} fontWeight={'bold'}>Relaxing flower for peaceful evenings</Text>
                            </Center>
                        </Flex>
                    </Box>
                </Flex>
            </Box>
        )
    }

    const ProfileSummary = () => {
        return (
            <Box bg={"rgba(0,0,0,.5)"} borderRadius={10}  p={2} m={3} >
                <Flex>
                    <Center>
                        <Text fontSize={'8xl'} fontWeight={'bold'} p={4}>
                            04
                        </Text>
                    </Center>
                    <Center>
                        <Text fontSize={'4xl'} fontWeight={'semibold'}>
                            recent orders
                        </Text>
                    </Center>
                </Flex>
                <Flex>
                    <Box flex={1}>
                        <Flex flexDir={'column'}>
                            <Text fontSize={'2xl'} fontWeight={'bold'} p={4}>
                                Top categories
                            </Text>
                            <Flex>
                                {!mobile && <Box flex={1} />}
                                <Box flex={1} p={2}>
                                    <Flex flexDirection={'column'} textAlign={'center'}>
                                        <Text>Flower</Text>
                                        <Center bg={"rgba(0,0,0,.5)"} borderRadius={20} p={4} h={'100%'} w={'100%'}>
                                            <BsStarFill size={'lg'}/>
                                        </Center>
                                        <Center p={2}>
                                            <Badge ml='1' fontSize='0.8em' colorScheme='green' w={10}>
                                                x4
                                            </Badge>
                                        </Center>
                                    </Flex>
                                </Box>
                                <Box flex={1} p={2}>
                                    <Flex flexDirection={'column'} textAlign={'center'}>
                                        <Text>Edibles</Text>
                                        <Center bg={"rgba(0,0,0,.5)"} borderRadius={20} p={4} h={'100%'} w={'100%'}>
                                            <BsStarFill size={'lg'}/>
                                        </Center>
                                        <Center p={2}>
                                            <Badge ml='1' fontSize='0.8em' colorScheme='purple' w={10}>
                                                x2
                                            </Badge>
                                        </Center>
                                    </Flex>
                                </Box>
                                <Box flex={1} p={2}>
                                    <Flex flexDirection={'column'} textAlign={'center'}>
                                        <Text>Vapes</Text>
                                        <Center bg={"rgba(0,0,0,.5)"} borderRadius={20} p={4} h={'100%'} w={'100%'} opacity={.3}>
                                            <BsStarFill size={'lg'}/>
                                        </Center>
                                        <Center p={2}>
                                            <Badge ml='1' fontSize='0.8em' colorScheme='green' w={10} opacity={0}>
                                                x2
                                            </Badge>
                                        </Center>
                                    </Flex>
                                </Box>
                                <Box flex={1} p={2}>
                                    <Flex flexDirection={'column'} textAlign={'center'}>
                                        <Text>Topicals</Text>
                                        <Center bg={"rgba(0,0,0,.5)"} borderRadius={20} p={4} h={'100%'} w={'100%'} opacity={.3}>
                                            <BsStarFill size={'lg'}/>
                                        </Center>
                                        <Center p={2}>
                                            <Badge ml='1' fontSize='0.8em' colorScheme='green' w={10} opacity={0}>
                                                x2
                                            </Badge>
                                        </Center>
                                    </Flex>
                                </Box>
                                {!mobile && <Box flex={1} />}
                            </Flex>
                            <Text fontSize={'2xl'} fontWeight={'bold'} p={4}>
                                Top feelings
                            </Text>
                            <Flex>
                                <Box flex={1} />
                                <Center flex={6} color={'white'}>
                                    <Badge variant='outline' fontSize='1.2em' m={4} colorScheme='green'>focus</Badge>
                                    <Badge variant='outline' fontSize='1.2em' m={4} colorScheme='yellow'>creativity</Badge>
                                    <Badge variant='outline' fontSize='1.2em' m={4} colorScheme='orange'>energy</Badge>
                                    <Badge variant='outline' fontSize='1.2em' m={4} colorScheme='blue'>relax</Badge>
                                    <Badge variant='outline' fontSize='1.2em' m={4} colorScheme='purple'>calming</Badge>
                                </Center>
                                <Box flex={1} />
                            </Flex>
                        </Flex>

                    </Box>
                    
                </Flex>
            </Box>
        )
    }

    const TabNav = () => {
        return (
            <Box bg={"rgba(0,0,0,.5)"} borderRadius={10}  p={2} m={3} >
                <Tabs isFitted={mobile} variant='unstyled'>
                    <TabList>
                        <Tab _selected={{ color: 'white', textDecoration: 'underline', textDecorationThickness: '.2rem', fontWeight: 'bold' }}>Orders</Tab>
                        <Tab _selected={{ color: 'white', textDecoration: 'underline', textDecorationThickness: '.2rem', fontWeight: 'bold'}}>Achievements</Tab>
                        <Tab _selected={{ color: 'white', textDecoration: 'underline', textDecorationThickness: '.2rem', fontWeight: 'bold'}}>Suggestions</Tab>
                 
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            <OrderTimeline />
                        </TabPanel>
                        <TabPanel>
                            <AchievementWidget />
                        </TabPanel>
                        <TabPanel>
                            <SuggestionWidget />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        )
    }

    const PopOver = () => {
        const OverlayOne = () => (
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px)'
          />
        )
      
        const { isOpen, onOpen, onClose } = useDisclosure()
        const [overlay, setOverlay] = useState(<OverlayOne />)
        const [isOrderReviewed, setOrderReviewed] = useState(cart.length > 0 ? true : false)
        let [currentReviewStage, setCurrentReviewStage] = useState(0)
        let [currentReviewItem, setCurrentReviewItem] = useState(0)
        

        const handleNextStage = () => {
            console.log('next stage bitch')
            setCurrentReviewStage(currentReviewStage = ++currentReviewStage)
        }
        
        const handleNextItem = () => {
            console.log('next item bitch')
            setCurrentReviewItem(currentReviewItem = ++currentReviewItem)
        }

        const completeOrderReview = () => {
            setOrderReviewed(false)
        }

        const stageZero = () => {
            return (
                <Flex flexDir={'column'}>
                    <Flex>
                    {cart.map(item => {
                        let tempImage = item.productAttributes.productImages[0];
                        return (
                            <Center flex={1} p={8}>
                                <Flex flexDir={'column'}>
                                    <Image h={16} src={ tempImage === "" ? "https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png" : tempImage}/> 
                                    <Text>{item.productType}</Text>
                                </Flex>
                            </Center>
                        )
                    })}
                    </Flex>
                    <Center>
                    <Heading>Rate your order</Heading>
                    </Center>
                    <Center>
                    <Text>{cart.length > 0 && `from ${cart[0]['locationName']}`}</Text>
                    </Center>

                    <Center p={4} textAlign={'center'}>
                        <Text>Leave feedback about your experience to unlock achievements and rewards.</Text>
                    </Center>
                    <Center p={4}>
                        <Button isFullWidth color={'black'} onClick={handleNextStage}>Continue</Button>
                    </Center>
                    <Center p={4}>
                        <Button onClick={completeOrderReview} variant={'ghost'}>Not now</Button>
                    </Center>
                </Flex>
            )
        }

        const stageOne = () => {
            return (
                <Flex flexDir={'column'}>
                    
                    <Center p={4} paddingTop={8} textAlign={'center'}>
                        <Heading>How was your overall experience with {cart.length > 0 && cart[0]['locationName']}?</Heading>
                    </Center>
                

                
                    <Center p={4}>
                        <Button color={'black'} size={'lg'} borderRadius={30} m={2} onClick={handleNextStage}>
                            <BsHandThumbsDown style={{fontSize: '2rem'}}/>
                        </Button>
                        <Button color={'black'} size={'lg'} borderRadius={30} m={2} onClick={handleNextStage}>
                            <BsHandThumbsUp style={{fontSize: '2rem'}}/>
                        </Button>
                    </Center>
                    <Center p={4}>
                        <Button variant={'link'} onClick={handleNextStage}>Skip</Button>

                    </Center>
                </Flex>
            )
        }

        const stageTwo = () => {
        
            return (
                <Flex flexDir={'column'}>                    
                    <Flex>
                        {
                           cart.length > 0 && cart.map((item, i ) => {
                            let tempImage = item.productAttributes.productImages[0];
                            console.log('iterating', i)
                            let tempIterator = i
                      
                            return currentReviewItem === i && (
                                    <Center flex={1} p={8}>
                                        <Flex flexDir={'column'}>
                                            <Image h={16} src={ tempImage === "" ? "https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png" : tempImage}/> 
                                            <Text>{item.productType}</Text>
                                        </Flex>
                                        <Button onClick={i === cart.length - 1 ? handleNextStage : handleNextItem}>Next</Button>
                                    </Center>
                                )
                            }) 
                        }
                    </Flex>
                </Flex>
            )
        }

        const stageThree = () => {
            return(
                <Flex flexDir={'column'}>                    
                    <Center p={4} paddingTop={8} textAlign={'center'}>
                        <Heading>Done.</Heading>
                    </Center>
                    <Center p={4}>
                        <Button variant={'link'} onClick={completeOrderReview}>end</Button>
                    </Center>
                </Flex>
            )
        }

        let stageSelector = {
            0: stageZero(),
            1: stageOne(),
            2: stageTwo(),
            3: stageThree()
        }
        
        return (
          <Center opacity={isOrderReviewed ? 1 : 0}>
            <Button
                variant={'outline'}
                onClick={() => {
                setOverlay(<OverlayOne />)
                onOpen()
              }}
            >
             Review Last Order
            </Button>
       
            <Modal isCentered isOpen={isOrderReviewed} onClose={completeOrderReview} >
              {overlay}
              <ModalContent bg={"rgba(0,0,0,.7)"} color={'white'} borderRadius={10} m={4}>
                {stageSelector[currentReviewStage]}         
        
              </ModalContent>
            </Modal>
          </Center>
        )
      }

    const MobileProfile = () => {
        return (
            <Box paddingTop={4} color={"white"}>
                <Flex flexDir={'column'}>
                    <AccountWidget />
                    <PopOver />
                    <ProfilePreferences />
                    <TabNav />
                </Flex>
            </Box>
        )
    }
    
    const DesktopProfile = () => {
        return (
 
            <Flex color={'white'} fontSize={'sm'}>
                <Box flex={2} >
                    <AccountWidget />
                    <PopOver />
                    <ProfileSummary />
                </Box>
                <Box flex={3} >
                    <ProfilePreferences />
                    <YourGoTos />
                    <TabNav />
                </Box>      
            </Flex>
       
        )
    }
    console.log('test', cart)
    return (
        <>
        {mobile ? <MobileProfile /> : <DesktopProfile /> }
        </>
    );
  }

export default Profile;
  