import { Button, Center } from '@chakra-ui/react';
import UniversalSelectMultiple from './UniversalSelectMultiple';
import { technicalOptions } from './../../utilities/menuOptions'

const TechnicalEffects = (props) => {
    const detailText = "Select up to three"
    const route = "/mostImportant"
  
    return  <>
                <UniversalSelectMultiple 
                        isMobile={props.isMobile} 
                        handleAddUserData={props.handleAddUserData} 
                        firstLineText={technicalOptions.firstLineText}
                        secondLineText={technicalOptions.secondLineText} 
                        answers={technicalOptions.options}
                        extraColumns={3}
                        extraSpacing={6}
                        detailText={detailText}
                        route={route}
                        progressValue={technicalOptions.progressValue}
            
                />
                
            </>
  }
  
  export default TechnicalEffects;