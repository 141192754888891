import UniversalQuestion from './UniversalQuestion';
import { ageOptions } from './../../utilities/menuOptions'

const AgeVerify = (props) => {
    return <UniversalQuestion 
              isMobile={props.isMobile} 
              handleAddUserData={props.handleAddUserData} 
              firstLineText={ageOptions.firstLineText}
              secondLineText={ageOptions.secondLineText} 
              answers={ageOptions.options}
              progressValue={ageOptions.progressValue}
  
    />
  }
  
export default AgeVerify;