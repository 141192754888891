import { Link } from "react-router-dom";
import { Box, Button, Container, Center, Text, Flex, Heading, Image, Divider, Spacer, HStack, Badge } from '@chakra-ui/react'

const Checkout = (props) => {
    let mobile = props.isMobile;
    let cart = props.cart
    let subtotal = 0
    cart.forEach(item => {
        subtotal += (Number(item.pricePerUnit) * Number(item.quantityInCart))
    })

    const MobileCheckout = () => {
        return (
            <Box  >
                <Flex flexDir={'column'}>
                    <Box color={'white'} fontSize={'2xl'} fontWeight={'black'} p={4} marginBottom={-6}>
                        Cart
                    </Box>
                    <Box bg={"rgba(0,0,0,.5)"} borderRadius={10} color={'white'} p={4} m={3} fontSize={'sm'}>
                        
                            {cart.length === 0 ? 
                                (
                                    <Box textAlign={'center'}>
                                        <Text fontSize={'large'} fontWeight={'bold'}>
                                            Your cart's empty. Add some products first!
                                        </Text>
                                        <Center p={4}>
                                            <Image w={'96px'} src="https://flowerly-app-storage.s3.amazonaws.com/assets/ageVerification/ageWarning.webp"/>
                                        </Center>
                                        <Box flex={1} p={2}>
                                            <Link to={'/summary'}>
                                                <Button variant={'outline'} isFullWidth>Browser Products</Button>
                                            </Link>
                                        </Box>
                                    </Box>
                                ) : ( 
                                cart.map(( item, i ) => {
                                    let quantity = item.quantityInCart
                                    let formatted_data = item
                                   
                                    return (
                                        <Box className="glassEffect" borderRadius={10} marginTop={i !== 0 && 4}>
                                            <Flex>
                                                <Center flex={1} marginLeft={4} >
                                                    <Flex>
                                                        {/* <Box flex={1}  p={2}>
                                                            <Flex flexDir={'column'} >
                                                                <Center>
                                                                    <Button isDisabled borderRadius={100} variant={'outline'} size={'xs'}> - </Button>
                                                                </Center>    
                                                            </Flex>
                                                            
                                                        </Box> */}
                                                        <Box flex={1} >
                                                            {/* <Flex flexDir={'column'}> */}
                                                               
                                                                <Badge paddingLeft={2} paddingRight={2} fontSize={'lg'} textAlign={'center'}>{quantity ? quantity : 1}</Badge>
                                                            {/* </Flex> */}
                                                        </Box>
                                                        {/* <Box flex={1}  p={2}>
                                                            <Flex flexDir={'column'} >
                                                                <Center>
                                                                    <Button isDisabled borderRadius={100} variant={'outline'} size={'xs'}> + </Button>
                                                                </Center>    
                                                            </Flex>
                                                            
                                                        </Box> */}
                                                        
                                                    </Flex>
                                                    
                                                </Center>
                                                <Center flex={1}>
                                                    <Image p={2} src={formatted_data.productAttributes && formatted_data.productAttributes.productImages[0] ? formatted_data.productAttributes.productImages[0] : "https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png"} />
                                                </Center>
                                                <Center flex={3}>
                                                    <Flex flexDir={'column'}>
                                                        <Text fontSize={'lg'} fontWeight={'black'}>{`${formatted_data.productName}`} </Text>
                                                        <Text fontSize={'xs'} fontWeight={'bold'}>{`${formatted_data.strainType}   ${formatted_data.category}`}</Text>
                                                    </Flex>
                                                </Center>
                                                <Center flex={1} fontWeight={'bold'} p={2} marginRight={2}>
                                                    
                                                    <Flex flexDir={'column'}>
                                                        <Center>
                                                            <Text fontSize={'xl'} fontWeight={'bold'}>${formatted_data.pricePerUnit}</Text>
                                                        </Center>
                                                        <Center>
                                                            <Text fontSize={'xs'} fontWeight={'medium'}>{formatted_data.units} {formatted_data.productType}</Text>
                                                        </Center>
                                                    </Flex>
                                                </Center>
                                            </Flex>
                                        </Box>
                                    )
                                })
                            )}
                    </Box>
                    <Box color={'white'} fontSize={'2xl'} fontWeight={'black'} p={4} marginBottom={-6} marginTop={-4}>
                        Summary
                    </Box>
                    <Box flex={2} bg={"rgba(0,0,0,.5)"} borderRadius={10} color={'white'} p={4} m={3} fontSize={'sm'}>
                        <Flex flexDir={'column'}>
                            <Box>
                                <Flex>
                                    <Center flex={1} p={2}>
                                        <Center fontSize={'xl'} fontWeight={'bold'} backgroundColor={'gray.500'} borderRadius={10} p={4} w={'100%'}>
                                            Pickup
                                        </Center>
                                    </Center>
                                    <Center flex={1} p={2}>
                                        <Button fontSize={'xl'} fontWeight={'bold'} h={'100%'} borderRadius={10} p={4} variant={'outline'} isDisabled isFullWidth>
                                            Delivery
                                        </Button>
                                    </Center>
                                </Flex>
                            </Box>
                            <Center >
                                <Divider p={2}/>
                            </Center >
                            <Box p={2} >
                                <Flex >
                                    <Box>
                                        <Text>Subtotal</Text>
                                    </Box>
                                    <Spacer />
                                    <Box >
                                        <Text>${subtotal.toFixed(2)}</Text>
                                    </Box>
                                </Flex>
                                <Flex >
                                    <Box>
                                        <Text>Processing fee</Text>
                                    </Box>
                                    <Spacer />
                                    <Box >
                                        <Badge colorScheme='purple'>Free</Badge>
                                    </Box>
                                </Flex>
                                <Flex >
                                    <Box>
                                        <Text>Est. taxes</Text>
                                    </Box>
                                    <Spacer />
                                    <Box >
                                        <Text>calculated at payment</Text>
                                    </Box>
                                </Flex>
                                <Flex fontSize={'lg'} fontWeight={'bold'}>
                                    <Box>
                                        <Text>Estimated total</Text>
                                    </Box>
                                    <Spacer />
                                    <Box >
                                        <Text>${subtotal.toFixed(2)}</Text>
                                    </Box>
                                </Flex>
                            </Box >
                            <Center >
                                <Divider p={2} marginTop={-2}/>
                            </Center >
                            <Box marginTop={2}>

                                    <Box flex={1} p={2}>
                                        <Link to={'/checkout/placeOrder'}>
                                            <Button bgColor={'green.500'} isFullWidth isDisabled={cart.length === 0} >Proceed to Checkout</Button>
                                        </Link>
                                    </Box>
                                    <Box flex={1} p={2}>
                                        <Link to={'/summary'}>
                                            <Button variant={'outline'} isFullWidth>Continue Shopping</Button>
                                        </Link>
                                    </Box>
                                
                            </Box>
                          
                        </Flex>
                    </Box>
                </Flex>
            </Box>
        )
    }
    
    const DesktopCheckout = () => {
        return (
            <Box p={6}>
            {/* <Heading>Cart</Heading> */}
            <Flex>
                    <Box flex={1}/>
                    <Box flex={6} >
                        
                        <Flex color={'white'} fontSize={'2xl'} fontWeight={'black'} p={4} marginBottom={-6}>
                        <Box flex={4} >Cart</Box>
                        <Box flex={2}>Summary</Box>
                        </Flex>
                        <Flex>
                            <Box flex={4} bg={"rgba(0,0,0,.5)"} borderRadius={10} color={'white'} p={4} m={3} fontSize={'sm'}>
                                <Flex flexDir={'column'}>
                                    {cart.length === 0 ? 
                                        (
                                            <Box textAlign={'center'} p={8} fontSize={'lg'} fontWeight={'bold'}>
                                                Your cart's empty. Find something good!
                                                <Center p={4}>
                                                    <Image w={'96px'} src="https://flowerly-app-storage.s3.amazonaws.com/assets/ageVerification/ageWarning.webp"/>
                                                </Center>
                                                <Box flex={1} p={4}>
                                                    <Link to={'/summary'}>
                                                        <Button variant={'outline'} isFullWidth>Browse Products</Button>
                                                    </Link>
                                                </Box>
                                            </Box>
                                        ) : (
                                        cart.map(( item, i ) => {
                                            let quantity = item.quantityInCart;
                                            let formatted_data = item;
                                            return (
                                                <Box p={2} flex={1} className="glassEffect" borderRadius={10} m={3}>
                                                    <Flex>
                                                        <Center flex={1} >
                                                            <Flex>
                                                                <Box flex={1}  p={2}>
                                                                    <Flex flexDir={'column'} >
                                                                        <Center>
                                                                            <Button isDisabled borderRadius={100} variant={'outline'} size={'xs'}> - </Button>
                                                                        </Center>    
                                                                    </Flex>
                                                                    
                                                                </Box>
                                                                <Box flex={1} >
                                                                    <Flex flexDir={'column'}>
                                                                        <Text>quantity</Text>
                                                                        <Text textAlign={'center'}>{quantity ? quantity : 1}</Text>
                                                                    </Flex>
                                                                </Box>
                                                                <Box flex={1}  p={2}>
                                                                    <Flex flexDir={'column'} >
                                                                        <Center>
                                                                            <Button isDisabled borderRadius={100} variant={'outline'} size={'xs'}> + </Button>
                                                                        </Center>    
                                                                    </Flex>
                                                                    
                                                                </Box>
                                                                
                                                            </Flex>
                                                            
                                                        </Center>
                                                        <Center flex={1} >
                                                            <Image p={2}  src={formatted_data.productAttributes && formatted_data.productAttributes.productImages[0] ? formatted_data.productAttributes.productImages[0] : "https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png"}/>
                                                        </Center>
                                                        {/* <Spacer /> */}
                                                        <Center flex={3}>
                                                            <Flex flexDir={'column'}>
                                                                <Text fontSize={'lg'} fontWeight={'black'}>{`${formatted_data.productName}`} <Text fontSize={'sm'} fontWeight={'medium'}>{formatted_data.units} {formatted_data.productType}</Text></Text>
                                                                <Text fontSize={'xs'} fontWeight={'bold'}>{`${formatted_data.strainType}   ${formatted_data.category}`}</Text>
                                                            </Flex>
                                                        </Center>
                                                        <Center flex={1} fontSize={'xl'} fontWeight={'bold'} p={2} marginRight={2}>${formatted_data.pricePerUnit}</Center>
                                                    </Flex>
                                                </Box>
                                            )
                                        })
                                    )}
                                </Flex>
                            </Box>
                              
                            <Box flex={2} bg={"rgba(0,0,0,.5)"} borderRadius={10} color={'white'} p={4} m={3} fontSize={'sm'}>
                                <Flex flexDir={'column'}>
                                    <Box>
                                        <Flex>
                                            <Box flex={1} p={2}>
                                                <Center fontSize={'xl'} fontWeight={'bold'} backgroundColor={'gray.500'} borderRadius={10} p={4} marginTop={2} >
                                                    Pickup
                                                </Center>
                                            </Box>
                                            <Box flex={1} p={2}>
                                                <Button fontSize={'xl'} fontWeight={'bold'} h={'90%'} borderRadius={10} p={4} marginTop={2} variant={'outline'} isDisabled isFullWidth>
                                                    Delivery
                                                </Button>
                                            </Box>
                                        </Flex>
                                    </Box>
                                    <Center >
                                        <Divider p={2}/>
                                    </Center >
                                    <Box p={2} >
                                        <Flex >
                                            <Box>
                                                <Text>Subtotal</Text>
                                            </Box>
                                            <Spacer />
                                            <Box >
                                                <Text>${subtotal.toFixed(2)}</Text>
                                            </Box>
                                        </Flex>
                                        <Flex >
                                            <Box>
                                                <Text>Processing fee</Text>
                                            </Box>
                                            <Spacer />
                                            <Box >
                                                <Badge colorScheme='purple'>Free</Badge>
                                            </Box>
                                        </Flex>
                                        <Flex >
                                            <Box>
                                                <Text>Est. taxes</Text>
                                            </Box>
                                            <Spacer />
                                            <Box >
                                                <Text>calculated at payment</Text>
                                            </Box>
                                        </Flex>
                                        <Flex fontSize={'lg'} fontWeight={'bold'}>
                                            <Box>
                                                <Text>Estimated total</Text>
                                            </Box>
                                            <Spacer />
                                            <Box >
                                                <Text>${subtotal.toFixed(2)}</Text>
                                            </Box>
                                        </Flex>
                                    </Box >
                                    <Center >
                                        <Divider p={2}/>
                                    </Center >
                                    <Box p={2} marginTop={2}>
                                        <Link to={'/checkout/placeOrder'}>
                                            <Button bgColor={'GrayText'} isFullWidth isDisabled={cart.length === 0}>Proceed to Checkout</Button>
                                        </Link>
                                    </Box>
                                    <Box p={2}>
                                        <Link to={'/summary'}>
                                            <Button variant={'outline'} isFullWidth >Continue Shopping</Button>
                                        </Link>
                                    </Box>
                                </Flex>
                            </Box>
                        </Flex>
                    </Box>
                    <Box flex={1}/>
            </Flex>
        </Box>
        )
    }
    
    return (
        <>
        {mobile ? <MobileCheckout /> : <DesktopCheckout /> }
        </>
    );
  }

export default Checkout;
  