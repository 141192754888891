const formatOptions = {
    firstLineText: "What format",
    secondLineText: "are you looking for?",
    progressValue: 90,
    options: [
      {route: "/summaryPreview", saveDataEntryValue: "flower", saveDataEntryName: "format", displayName: "Flower"},
      {route: "/summaryPreview", saveDataEntryValue: "preroll", saveDataEntryName: "format", displayName: "Pre-Roll"},
      {route: "/summaryPreview", saveDataEntryValue: "vaporizor", saveDataEntryName: "format", displayName: "Vaporizor"},
      {route: "/summaryPreview", saveDataEntryValue: "concentrates", saveDataEntryName: "format", displayName: "Concentrates"},
      {route: "/summaryPreview", saveDataEntryValue: "edibles", saveDataEntryName: "format", displayName: "Edibles"},
      {route: "/summaryPreview", saveDataEntryValue: "tinctures", saveDataEntryName: "format", displayName: "Tinctures"},
      {route: "/summaryPreview", saveDataEntryValue: "topicals", saveDataEntryName: "format", displayName: "Topicals"},
    //   {route: "/summaryPreview", saveDataEntryValue: "cbd", saveDataEntryName: "format", displayName: "CBD"}

    ]
}

const consumptionOptions = {
    firstLineText: "What consumption method",
    secondLineText: "are you looking for?",
    progressValue: 90,
    options: [
        {route: "/summaryPreview", saveDataEntryValue: "smoke", saveDataEntryName: "consumptionMethod", displayName: "inhale"},
        {route: "/summaryPreview", saveDataEntryValue: "vape", saveDataEntryName: "consumptionMethod", displayName: "vape"},
        {route: "/summaryPreview", saveDataEntryValue: "eat", saveDataEntryName: "consumptionMethod", displayName: "eat"},
        {route: "/summaryPreview", saveDataEntryValue: "drink", saveDataEntryName: "consumptionMethod", displayName: "drink"},
        {route: "/summaryPreview", saveDataEntryValue: "rub", saveDataEntryName: "consumptionMethod", displayName: "rub"},
        {route: "/summaryPreview", saveDataEntryValue: "drops", saveDataEntryName: "consumptionMethod", displayName: "drops"},
        {route: "/summaryPreview", saveDataEntryValue: "patches", saveDataEntryName: "consumptionMethod", displayName: "patches"},
        // {route: "/summaryPreview", saveDataEntryValue: "cbd", saveDataEntryName: "consumptionMethod", displayName: "CBD"}

    ]
}

const ageOptions = {
    firstLineText: "Hello there!",
    secondLineText: "Before we start, can we verify your age?",
    progressValue: 15,
    options: [
        {route: "/underAge", saveDataEntryValue: "under_18", saveDataEntryName: "ageGroup", displayName: "Under 18"},
        {route: "/visitReason", saveDataEntryValue: "18-40", saveDataEntryName: "ageGroup", displayName: "18 - 40"},
        {route: "/visitReason", saveDataEntryValue: "41-65", saveDataEntryName: "ageGroup", displayName: "40 - 65"},
        {route: "/visitReason", saveDataEntryValue: "66+", saveDataEntryName: "ageGroup", displayName: "Over 65"},

    ]
}

const intentionsOptions = {
    firstLineText: "How are you",
    secondLineText: "looking to feel?",
    progressValue: 50,
    options: [
        {route: "/timeOfDay", saveDataEntryValue: "euphoric", saveDataEntryName: "intentionFeelings", displayName: "euphoric"},
        {route: "/timeOfDay", saveDataEntryValue: "focused", saveDataEntryName: "intentionFeelings", displayName: "focused"},
        {route: "/timeOfDay", saveDataEntryValue: "creative", saveDataEntryName: "intentionFeelings", displayName: "creative"},
        {route: "/timeOfDay", saveDataEntryValue: "happy", saveDataEntryName: "intentionFeelings", displayName: "happy"},
        {route: "/timeOfDay", saveDataEntryValue: "calm", saveDataEntryName: "intentionFeelings", displayName: "calm"},
        {route: "/timeOfDay", saveDataEntryValue: "relaxed", saveDataEntryName: "intentionFeelings", displayName: "relaxed"},
        {route: "/timeOfDay", saveDataEntryValue: "giggly", saveDataEntryName: "intentionFeelings", displayName: "giggly"},
        {route: "/timeOfDay", saveDataEntryValue: "hungry", saveDataEntryName: "intentionFeelings", displayName: "hungry"},
        {route: "/typeOfEffect", saveDataEntryValue: "sleep", saveDataEntryName: "intentionFeelings", displayName: "sleepy"}
    ]
}

const medicalOptions = {
    firstLineText: "What type of relief",
    secondLineText: "or ailments are you treating?",
    progressValue: 35,
    options: [
        {route: "/experience", saveDataEntryValue: "nausea", saveDataEntryName: "medicalIssues", displayName: "Nausea Relief"},
        {route: "/experience", saveDataEntryValue: "pain", saveDataEntryName: "medicalIssues", displayName: "Pain Relief"},
        {route: "/experience", saveDataEntryValue: "sleep", saveDataEntryName: "medicalIssues", displayName: "Improve Sleep"},
        {route: "/experience", saveDataEntryValue: "anxiety", saveDataEntryName: "medicalIssues", displayName: "Control Anxiety"},
        {route: "/experience", saveDataEntryValue: "appetite", saveDataEntryName: "medicalIssues", displayName: "Stimulate Appetite"},
        {route: "/experience", saveDataEntryValue: "seriousCondition", saveDataEntryName: "medicalIssues", displayName: "Serious Condition"},
    ]
}

const mostImportantOptions = {
    firstLineText: "What do you prioritize",
    secondLineText: "when looking for products?",
    progressValue: 70,
    options: [
        {route: "/format", saveDataEntryValue: "brand", saveDataEntryName: "mostImportant", displayName: "Brand"},
        {route: "/format", saveDataEntryValue: "price", saveDataEntryName: "mostImportant", displayName: "Price"},
        {route: "/format", saveDataEntryValue: "effect", saveDataEntryName: "mostImportant", displayName: "Effect"},
        {route: "/format", saveDataEntryValue: "strength", saveDataEntryName: "mostImportant", displayName: "Strength"},
        {route: "/format", saveDataEntryValue: "quality", saveDataEntryName: "mostImportant", displayName: "Quality"},
        {route: "/format", saveDataEntryValue: "lab_data", saveDataEntryName: "mostImportant", displayName: "Lab Data"},
    ]
}

const technicalOptions = {
    firstLineText: "What cannabinoids",
    secondLineText: "or terpenes are you looking for?",
    progressValue: 50,
    options: [
        {route: "/mostImportant", saveDataEntryValue: "THC", saveDataEntryName: "preferredCannaTerps", displayName: "THC"},
        {route: "/mostImportant", saveDataEntryValue: "CBD", saveDataEntryName: "preferredCannaTerps", displayName: "CBD"},
        {route: "/mostImportant", saveDataEntryValue: "CBN", saveDataEntryName: "preferredCannaTerps", displayName: "CBN"},
        {route: "/mostImportant", saveDataEntryValue: "CBG", saveDataEntryName: "preferredCannaTerps", displayName: "CBG"},
        {route: "/mostImportant", saveDataEntryValue: "caryophyllene", saveDataEntryName: "preferredCannaTerps", displayName: "B-caryophyllene"},
        {route: "/mostImportant", saveDataEntryValue: "pinene", saveDataEntryName: "preferredCannaTerps", displayName: "B-pinene"},
        {route: "/mostImportant", saveDataEntryValue: "limonene", saveDataEntryName: "preferredCannaTerps", displayName: "Limonene"},
        {route: "/mostImportant", saveDataEntryValue: "linalool", saveDataEntryName: "preferredCannaTerps", displayName: "Linalool"},
        {route: "/mostImportant", saveDataEntryValue: "myrcene", saveDataEntryName: "preferredCannaTerps", displayName: "Myrcene"}
    ]
}

const customerType = {
    firstLineText: "What brings you",
    secondLineText: "to the [best] cannabis store?",
    progressValue: 30,
    options: [
        {route: "/medical", saveDataEntryValue: "medical", saveDataEntryName: "visitReason", displayName: "Medical"},
        {route: "/experience", saveDataEntryValue: "recreational", saveDataEntryName: "visitReason", displayName: "Recreational"},  
    ]
}

const experienceType = {
    firstLineText: "What experience",
    secondLineText: "have you had with dispensaries?",
    progressValue: 40,
    options: [
        {route: "/intentions", saveDataEntryValue: "first_time", saveDataEntryName: "experienceLevel", displayName: "First Time"},
        {route: "/intentions", saveDataEntryValue: "beginner", saveDataEntryName: "experienceLevel", displayName: "Beginner"},
        {route: "/intentions", saveDataEntryValue: "moderate", saveDataEntryName: "experienceLevel", displayName: "Moderate"},
        {route: "/technicalEffects", saveDataEntryValue: "experienced", saveDataEntryName: "experienceLevel", displayName: "Experienced", disabled: true, subText: 'coming soon'},
    ]
}

const strengthOptions = {
    options: [
        {saveDataEntryValue: "lightest", saveDataEntryName: "preferredStrength", displayName: "lightest"},
        {saveDataEntryValue: "lighter", saveDataEntryName: "preferredStrength", displayName: "lighter"},
        {saveDataEntryValue: "stronger", saveDataEntryName: "preferredStrength", displayName: "stronger"},
        {saveDataEntryValue: "strongest", saveDataEntryName: "preferredStrength", displayName: "strongest"},
    ]
}

const durationOptions = {
    options: [
        {saveDataEntryValue: "shortest", saveDataEntryName: "preferredDuration", displayName: "shortest"},
        {saveDataEntryValue: "shorter", saveDataEntryName: "preferredDuration", displayName: "shorter"},
        {saveDataEntryValue: "longer", saveDataEntryName: "preferredDuration", displayName: "longer"},
        {saveDataEntryValue: "longest", saveDataEntryName: "preferredDuration", displayName: "longest"},
    ]
}

const timeOfDayOptions = {
    options: [
        {saveDataEntryValue: "morning", saveDataEntryName: "preferredTimeOfDay", displayName: "morning"},
        {saveDataEntryValue: "day", saveDataEntryName: "preferredTimeOfDay", displayName: "day"},
        {saveDataEntryValue: "afternoon", saveDataEntryName: "preferredTimeOfDay", displayName: "afternoon"},
        {saveDataEntryValue: "evening", saveDataEntryName: "preferredTimeOfDay", displayName: "evening"},
        {saveDataEntryValue: "night", saveDataEntryName: "preferredTimeOfDay", displayName: "night"}
    ]
}


export { formatOptions, consumptionOptions, ageOptions, intentionsOptions, 
    medicalOptions, mostImportantOptions, technicalOptions, customerType, experienceType,
    strengthOptions, durationOptions, timeOfDayOptions
}; 