import UniversalQuestion from './UniversalQuestion';
import {consumptionOptions} from './../../utilities/menuOptions'

const ConsumptionMethod = (props) => {
  
    return <UniversalQuestion 
              isMobile={props.isMobile} 
              handleAddUserData={props.handleAddUserData} 
              firstLineText={consumptionOptions.firstLineText}
              secondLineText={consumptionOptions.secondLineText} 
              answers={consumptionOptions.options}
              progressValue={consumptionOptions.progressValue}
    />
  }
  
export default ConsumptionMethod;