import { Link } from "react-router-dom";
import { FaShoppingCart } from 'react-icons/fa'
import { Box, Flex, Heading, Spacer, Button, Center, Text, Image, } from '@chakra-ui/react'


const Nav = (props) => {
    return (
      <Box bg={"rgba(0,0,0,.5)"} borderRadius={10} padding={props.isMobile ? 1 : 3} margin={3} marginBottom={props.isMobile && -3}>
        <Flex>
          <Center style={{paddingLeft: "3px"}}>
            <Link to="/">
              <Image src='https://flowerly-app-storage.s3.amazonaws.com/logos/3D-Flowerly-Icon-md.webp' height={8}/>
            </Link>
          </Center>
          <Center p="1">
            <Heading size='md' color="white">
              <Flex>
                <Link to="/summary">
                Flowerly AI &nbsp;
                </Link>
                <Text fontSize={'sm'} bgGradient='linear(to-l, blue.300, green.300, purple.400)' bgClip={'text'}>beta</Text>
              </Flex>
            </Heading>
          </Center>
          <Spacer />
          <Box style={{margin: "auto", paddingRight: "6px"}} >
            {props.cart.length > 0 && <Link to="/checkout"><Button variant='ghost' size='md' color="white">{props.totalItemsInCart}{`  `}<FaShoppingCart/></Button></Link>}
          </Box>
          <Box style={{margin: "auto", paddingRight: "6px"}} >
            {props.cart.length === 0 &&  
              <Link to="/authenticate">
                <Button variant='ghost' size='md' color="white">Log in</Button>
              </Link>}
          </Box>
        </Flex>
      </Box>
    )
  }

  export default Nav;