import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Container, Center, Text,Modal, ModalBody, ModalFooter, ModalHeader, ModalCloseButton, ModalOverlay, useDisclosure, ModalContent, Flex, List, ListIcon, ListItem } from '@chakra-ui/react'
import { BsCheckCircle } from 'react-icons/bs'

const IntroPopOver = (mobile) => {
  const OverlayOne = () => (
    <ModalOverlay
      bg='blackAlpha.300'
      backdropFilter='blur(20px)'
    />
  )

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [overlay, setOverlay] = useState(<OverlayOne />)

  
  return (
    <Center>
      
        <Button size='lg' variant='outline' onClick={() => {
          setOverlay(<OverlayOne />)
          onOpen()
        }}>
          Get started
        </Button>
    
      
 
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        {overlay}
        <ModalContent bg={"rgba(0,0,0,.7)"} color={'white'} borderRadius={10} m={mobile ? 4 : 0}>
          <Flex flexDir={'column'}>
            <Box flex={1}>
              <Center p={4} fontSize={'2xl'} fontWeight={'bold'}>
                Getting Started
              </Center>
            </Box>
            <Box flex={1} p={4}>
              <List spacing={3}>
                <ListItem>
                  <ListIcon as={BsCheckCircle} color='green.500' />
                    Flowerly AI is currently in beta! This software is a work in progress 👷🚧🦺😀
                </ListItem>
                <ListItem>
                  <ListIcon as={BsCheckCircle} color='green.500' />
                    1 - 2 minute understanding your cannabis knowledge
                </ListItem>
                <ListItem>
                  <ListIcon as={BsCheckCircle} color='green.500' />
                  Answer questions honestly, this is how we recommend the best products for you
                </ListItem>
                <ListItem>
                  <ListIcon as={BsCheckCircle} color='green.500' />
                  Privacy is important, your data is never sold to third-parties and remains securely stored for your protection
                </ListItem>
              
              </List>
            </Box>
            <Center flex={1} p={4}>
              <Link to="/ageVerify">
                <Button variant='outline'> Next </Button>
              </Link>
            </Center>
            
            
          </Flex>
        </ModalContent>
      </Modal>
    </Center>
  )
}

const Welcome = (props) => {
    let mobile = props.isMobile;
  
    return (
      <>
        <Container className='animate__animated animate__zoomIn animate__fast'>
            <Center>
              <Box w='100%' p={4} color='white' borderRadius='lg' style={{textAlign: "center"}}>
                {mobile ? (
                   <Container style={{paddingTop: "14vh"}}>
                      <Text fontSize='4xl'>Let’s find the <br/> 
                      <a style={{fontWeight: "800"}}> perfect cannabis </a>
                      <br/>
                      for you
                      </Text>
                      <div style={{paddingTop: "50px"}} >
                        <IntroPopOver mobile={mobile}/>
                      </div>
                    </Container>
                  ) : (
                    <Container style={{paddingTop: "20vh"}}>
                      <Text fontSize='5xl'>Let’s find the 
                      <a style={{fontWeight: "900"}}> perfect <br/> cannabis </a>
                      for you
                      </Text>
                      <div style={{paddingTop: "60px"}} >
                        
                        <IntroPopOver />
                      </div>
                  </Container>
                )}
               
              </Box>
            </Center>
          </Container>  
      </>
    );
  }

export default Welcome;
  