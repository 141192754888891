import { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Map from './Map';
import { Box, Button, Container, Center, Text, Flex, Heading, Image, Divider, Spacer, Progress, Badge } from '@chakra-ui/react'
import {BsCheckCircle} from 'react-icons/bs'

const CompleteOrder = (props) => {
    let [stage, setOrderStage] = useState(0)
    let mobile = props.isMobile;
    let cart = props.cart;
    let user = props.user;
    let subtotal = 0
    cart.forEach(item => {
        subtotal += (Number(item.pricePerUnit) * Number(item.quantityInCart))
    })

    const orderStages = {
        0: 'Confirmed 📲',
        1: 'Ready for Pickup 🚀',
        2: 'Order Complete ✅'
    }

    const advanceOrderStage = () => {
        return stage === 2 ? setOrderStage(0) : setOrderStage(++stage)
    }

    const SimulateNextStage = () => {
        console.log('wtf1')
        return (
            <Flex>
                <Button color={'white'} variant={'outline'} onClick={advanceOrderStage}> simulate next step </Button>
            </Flex>
        )
    }

    const SimulateUserProfile = () => {
        console.log('wtf')
        return (
            <Box marginTop={2}>
                 <Box  bg={"rgba(0,0,0,.5)"} borderRadius={10} p={4} m={3}  >
                    <Center p={3}>
                        <Text color='white' fontSize={'2xl'} fontWeight={'black'}> First Order Complete!</Text>
                    </Center>       
                     <Flex >
                         <Box flex={1}/>
                         <Center color='white' flex={1} p={6}>
                             <Flex >
                                
                                <BsCheckCircle className={'animate__animated animate__heartBeat'} style={{fontSize: '10rem'}}/>
                             </Flex>
                         </Center>
                         <Box flex={1}/>
                     </Flex>
                 </Box>
                <Center>
                    <Link to={'/profile'}>
                        <Button color={'white'} variant={'outline'} > Proceed to Account Profile </Button>
                    </Link>
                </Center>
            
            </Box>
        )
    }

    const MobileCompleteOrder = () => {
        return (
            <Box>
                <Flex flexDir={'column'}>
                    
                    {
                            stage === 2 ? <SimulateUserProfile /> : (
                            <>
                                <Box marginBottom={'-4'} marginTop={2}>
                                    <Flex color={'white'} fontSize={'2xl'} fontWeight={'black'} p={4} m={1}>
                                        <Box>Order status:</Box>
                                        <Spacer />
                                        <Box>
                                            <Badge p={2} fontWeight={'bold'} fontSize={'lg'} colorScheme={stage === 0 ? 'green' : 'yellow'} borderRadius={22} >
                                                {orderStages[stage]}
                                            </Badge>
                                        </Box>
                                    </Flex>
                                </Box>
                                <Box  bg={"rgba(0,0,0,.5)"} borderRadius={10} color={'white'} p={4} m={3} fontSize={'sm'}>    
                                    <Flex flexDir={'column'}>
                                        <Box marginTop={2}>
                                            <Flex fontWeight={'bold'}>
                                            <Center>
                                                <Text>Estimated Pickup Time</Text>
                                            </Center>
                                            <Spacer />
                                            
                                            </Flex>
                                        </Box>
                                        <Center >
                                            <Divider p={2}/>
                                        </Center >
                                        <Center marginTop={6}>
                                            <Heading fontSize={'6xl'} bgClip='text' bgGradient={['linear(to-b, orange.100, purple.300)',]}>
                                                {moment().add(2, 'h').format("h:mm") }{moment().hour() > 12 ? ' pm' : ' am'}
                                            </Heading>
                                        </Center>
                                        <Progress marginTop={6} size='sm' borderRadius={12} colorScheme={'purple'} isIndeterminate />
                                        <Box marginTop={6}>
                                        
                                            <Center>
                                                <Text fontWeight={'bold'}>Well update you via email.</Text>
                                            </Center>
                                        </Box>
                                    </Flex>
                                </Box>
                                <Box color={'white'} fontSize={'2xl'} fontWeight={'black'} p={4} marginBottom={-6}>
                                    Store Information
                                </Box>
                                <Box flex={4} bg={"rgba(0,0,0,.5)"} borderRadius={10} color={'white'} p={4} m={3} fontSize={'sm'}>
                                    <Flex flexDir={'column'}>
                                        <Box marginTop={2} flex={1}>
                                            <Center fontWeight={'bold'}>
                                                <Text textAlign={'center'}> Head to the store when your order is ready.</Text>
                                            </Center>
                                        </Box>
                                        <Box flex={1} p={4} >
                                            <Center borderRadius={10} backgroundColor={'gray.700'} h={'120px'} m={1} marginTop={4}>
                                                <Map user={user} mobile={mobile}/>
                                            </Center>
                                        </Box>
                                        
                                        <Box flex={1}>
                                            <Flex flexDir={'column'} p={4} >
                                                <Box flex={1}>
                                                    Pickup From: Dispensary Name
                                                </Box>
                                                <Box flex={1}>
                                                Los Angeles - Venice (Abbot Kinney)
                                                </Box>
                                                <Box flex={1}>
                                                1310 Abbot Kinney Blvd Venice, California 90291
                                                </Box>
                                                <Box flex={1}>
                                                (424) 330-7232
                                                </Box>
                                                <Box flex={1}>
                                                Estimated Pickup Time: {moment().add(2, 'h').hour()}:{moment().minutes()} {moment().hour() > 12 ? 'pm' : 'am'} | Curbside Pickup
                                                </Box>
                                            </Flex>
                                        </Box>
                                    </Flex>   
                                </Box> 
                                <Center>
                                    <SimulateNextStage/>
                                </Center>
                            </>
                        )
                    }
                  
                </Flex>
            </Box>
                     
        )
    }

    const DesktopCompleteOrder = () => {
        return (
            <Box p={6}>
                <Flex>
                    <Box flex={1}/>
                    <Box flex={6} >
                        <Box  bg={"rgba(0,0,0,.5)"} borderRadius={10} p={4} m={3}  >
                            <Flex color={'white'} fontSize={'2xl'} fontWeight={'bold'} marginBottom={-2} >
                                <Center  >Order status:</Center>
                                <Spacer />
                                <Center>
                                    <Badge p={2} fontWeight={'bold'} fontSize={'lg'} colorScheme={stage === 2 ? 'green' : 'yellow'} borderRadius={22} m={2}>
                                        {orderStages[stage]}
                                    </Badge>
                                </Center>
                            </Flex>
                        </Box>
                        {
                             stage === 2 ? (<SimulateUserProfile />) : (
                             <>
                                <Flex>
                                    <Box flex={4} bg={"rgba(0,0,0,.5)"} borderRadius={10} color={'white'} p={4} m={3} fontSize={'sm'}>
                                    <Flex flexDir={'column'}>
                                        <Box marginTop={2} flex={1}>
                                            <Center fontWeight={'bold'} fontSize={'lg'}>
                                                <Text> Head to the store when your order is ready for pickup.</Text>
                                            </Center>
                                        </Box>
                                        <Box flex={1}>
                                            <Flex>
                                                <Box flex={1}>
                                                    <Flex flexDir={'column'} p={4} >
                                                        <Box flex={1}>
                                                            Pickup From: Dispensary Name
                                                        </Box>
                                                        <Box flex={1}>
                                                        Los Angeles - Venice (Abbot Kinney)
                                                        </Box>
                                                        <Box flex={1}>
                                                        1310 Abbot Kinney Blvd Venice, California 90291
                                                        </Box>
                                                        <Box flex={1}>
                                                        (424) 330-7232
                                                        </Box>
                                                        <Box flex={1}>
                                                        Estimated Pickup Time: {moment().add(2, 'h').hour()}:{moment().minutes()} {moment().hour() > 12 ? 'pm' : 'am'} | Curbside Pickup
                                                        </Box>
                                                    </Flex>
                                                </Box>
                                                <Box flex={1} p={4} >
                                                    <Center borderRadius={10} backgroundColor={'gray.700'} h={'100%'} >
                                                        <Map user={user}/>
                                                    </Center>
                                                </Box>
                                            </Flex>
                                        </Box>
                                    </Flex>   
                                    </Box>
                                    <Box  bg={"rgba(0,0,0,.5)"} borderRadius={10} color={'white'} p={4} m={3} fontSize={'sm'}>    
                                        <Flex flexDir={'column'}>
                                            <Box marginTop={2}>
                                                <Flex fontWeight={'bold'}>
                                                <Center>
                                                    <Text>Estimated Pickup Time</Text>
                                                </Center>
                                                <Spacer />
                                                
                                                </Flex>
                                            </Box>
                                            <Center >
                                                <Divider p={2}/>
                                            </Center >
                                            <Center marginTop={6}>
                                                <Heading fontSize={'6xl'} bgClip='text' bgGradient={['linear(to-b, orange.100, purple.300)',]}>
                                                    {moment().add(2, 'h').format("h:mm") }{moment().hour() > 12 ? ' pm' : ' am'}
                                                </Heading>
                                            </Center>
                                            <Progress marginTop={6} size='sm' borderRadius={12} colorScheme={'purple'} isIndeterminate />
                                            <Box marginTop={6}>
                                            
                                                <Center>
                                                    <Text fontWeight={'bold'}>Well update you via email.</Text>
                                                </Center>
                                            </Box>
                                        </Flex>
                                    </Box>
                                </Flex>
                                <Center>
                                    <SimulateNextStage />
                                </Center>
                            </>
                            )
                        }
                        
                    </Box>
                    <Box flex={1}/>
                </Flex>
            </Box>
        )
    }
    
    console.log(stage)
    return (
        <>
            {mobile ? <MobileCompleteOrder /> : <DesktopCompleteOrder /> }
        </>
    );
  }

export default CompleteOrder;
  