import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Container, Center, Text,Modal, ModalBody, ModalFooter, ModalHeader, ModalCloseButton, ModalOverlay, useDisclosure, ModalContent, Flex, List, ListIcon, ListItem } from '@chakra-ui/react'
import { BsCheckCircle } from 'react-icons/bs'


const AutheticatedUser = (props) => {
    let mobile = props.isMobile;
  
    return (
      <>
        <Container className='animate__animated animate__zoomIn animate__fast'>
            <Center>
              <Box w='100%' p={4} color='white' borderRadius='lg' style={{textAlign: "center"}}>
                {mobile ? (
                   <Container style={{paddingTop: "14vh"}}>
                      <Text fontSize='4xl'>Welcome to your<br/> 
                      <a style={{fontWeight: "800"}}> perfect cannabis </a>
                      <br/>
                     
                      </Text>
               
                    </Container>
                  ) : (
                    <Container style={{paddingTop: "20vh"}}>
                      <Text fontSize='5xl'>Welcome to your
                      <a style={{fontWeight: "900"}}> perfect <br/> cannabis </a>
                      </Text>
                    
                  </Container>
                )}
               
              </Box>
            </Center>
          </Container>  
      </>
    );
  }

export default AutheticatedUser;
  