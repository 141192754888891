import { useState, useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Box, Flex, Heading, Spacer, Button, Stat, Progress, useToast, Center, Image, Text, SimpleGrid, Divider, Skeleton, Select, Input, HStack, InputGroup, InputLeftAddon, InputLeftElement, Stack, Menu, MenuItem, MenuList, MenuButton, Modal, ModalOverlay, useDisclosure, ModalContent, List , ListIcon, ListItem} from '@chakra-ui/react';

// const handlePopoverStatus = () => {
//     console.log('yo bitch')
//     setPopoverStatus(popoverStatus = !popoverStatus)
// } 

const SummaryPreview = (props) => {
        const {user, mobile} = props;
        let { visitReason = '', consumptionMethod = '', intentionFeelings = '', preferredStrength = '', preferredTimeOfDay = ''} = user


        const OverlayOne = () => (
          <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(20px)'
          />
        )
      
        const { isOpen, onOpen, onClose } = useDisclosure()
        const [overlay, setOverlay] = useState(<OverlayOne />)
      
        console.log('wtf')
        return (
          <Center>

            <Modal isCentered isOpen={true} onClose={onClose}>
              {overlay}
              <ModalContent bg={"rgba(0,0,0,.7)"} color={'white'} borderRadius={10} m={mobile ? 4 : 0}>
                {
                    visitReason === '' 
                    ? (
                    <Flex flexDir={'column'}>
                        <Box flex={1}>
                        <Center p={4} fontSize={'2xl'} fontWeight={'bold'}>
                            Oh No!
                        </Center>
    
                        </Box>
                        <Center flex={1} p={4}>
                            <Text textAlign={'center'} fontSize={'4xl'} fontWeight={'extrabold'}>
                            Something went wrong! Lets try that again... 
                            </Text>
                        </Center>
                        {/* <Box paddingRight={4} paddingLeft={4} >
                            <Progress size='lg' isIndeterminate borderRadius={8} colorScheme='orange'/>
                        </Box> */}
                        <Center flex={1} p={4}>
                            <Link to='/ageVerify'>
                                <Button variant='outline'> Start the quiz </Button>
                            </Link>
                        
                        </Center>
                    </Flex>     
                    ) 
                    
                    : (
                    <Flex flexDir={'column'}>
                        <Box flex={1}>
                        <Center p={4} fontSize={'2xl'} fontWeight={'bold'}>
                            Finding results for
                        </Center>
    
                        </Box>
                        <Box flex={1} p={4}>
                        {visitReason !== '' && <Text fontSize={'4xl'} fontWeight={'extrabold'}>{visitReason[0].toUpperCase() + visitReason.substring(1)} {consumptionMethod} with the {preferredStrength} effect for {intentionFeelings} {preferredTimeOfDay}s.</Text> } 
                        </Box>
                        <Box paddingRight={4} paddingLeft={4} >
                            <Progress size='lg' isIndeterminate borderRadius={8} colorScheme='orange'/>
                        </Box>
                        <Center flex={1} p={4}>
                            <Link to='/summary'>
                                <Button variant='outline'> Show Recommendations </Button>
                            </Link>
                        
                        </Center>
                    </Flex>)
                }
              </ModalContent>
            </Modal>
          </Center>
        )
    }

    export default SummaryPreview;