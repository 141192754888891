import axios from "axios";

const getInventory = async () => {
    return new Promise((resolve, reject) => {
        axios.get('https://r7jtbkicgc.execute-api.us-east-1.amazonaws.com/dev/flowerly/getInventory')
        .then(function (response) {
            // console.log(response);
            resolve(response)
        })
        .catch(function (error) {
            console.log(error);
            reject(error)
        })
    })
   
}

const findProduct = ( productId, storeId) => {
    const data = {
        "body": { productId: productId, storeId: storeId}
    };
    // console.log("INSIDE FIND PRODUCT WHAT IS THAT DATA", data)
    // const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify({ productId: productId, storeId: storeId})
    // };
    // fetch('https://r7jtbkicgc.execute-api.us-east-1.amazonaws.com/dev/flowerly/findProduct', requestOptions)
    //     .then(response => response.json())
    //     .then(data => {
    //         return data;
    //     });
    
    return new Promise((resolve, reject) => {
        axios.post('https://r7jtbkicgc.execute-api.us-east-1.amazonaws.com/dev/flowerly/findProduct', data)
        .then(function (response) {
            // console.log("IN FIND PRODUCT SUCCESS", response.data);
            resolve(response.data)
        })
        .catch(function (error) {
            console.log("IN FIND PRODUCT FAAIL", error);
            reject(error)
        })
    })
}

export { getInventory, findProduct }; 