import UniversalQuestion from './UniversalQuestion';
import { experienceType } from './../../utilities/menuOptions'

const WhatExperience = (props) => {
  
    return <UniversalQuestion 
              isMobile={props.isMobile} 
              handleAddUserData={props.handleAddUserData} 
              firstLineText={experienceType.firstLineText}
              secondLineText={experienceType.secondLineText} 
              answers={experienceType.options}
              progressValue={experienceType.progressValue}
  
    />
  }


  export default WhatExperience;