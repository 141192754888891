import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Progress, Button, Flex, Heading, Spacer, Container, Center, Text, Slider, SliderThumb, SliderTrack, SliderFilledTrack, SliderMark, Tooltip, Image } from '@chakra-ui/react';
import MostImportant from "./MostImportant";

const TypeOfEffect = (props) => {
    const route = (props.user && (props.user.experienceLevel === "experienced")) ? "/MostImportant" :  "/consumptionMethod"// check if user is experienced or not then send them to type of effect or most important
    const [strengthValue, setStrengthValue] = useState("strength")
    const [durationValue, setDurationValue] = useState("duration")
    const [sliderValue, setSliderValue] = useState(5)
    const [showTooltip, setShowTooltip] = useState(false)

    const firstLineText = "What type";
    const secondLineText = "of effect are you looking for?"
    const detailText = "Select your preferred time of day "
    
    const saveUserData = () => {
        props.handleAddUserData(strengthValue, "preferredStrength");
        props.handleAddUserData(durationValue, "preferredDuration");
        return;
    }

    const handleTranslatedStrengthUpdate = (value) => {
        console.log('handleTranslatedStrengthUpdate', value)
        if (value <= 25) {
            console.log('handleTranslatedStrengthUpdate', 'lightest')
            setStrengthValue("lightest")
        } else if (value > 26 && value < 50) {
            setStrengthValue(() => "lighter")
            console.log('handleTranslatedStrengthUpdate', 'lighter')
        } else if (value >= 50 && value < 75) {
            setStrengthValue(() => "stronger")
            console.log('handleTranslatedStrengthUpdate', 'stronger')
        } else if (value >= 76 && value < 100) {
            setStrengthValue(() => "strongest")
            console.log('handleTranslatedStrengthUpdate', 'strongest')
        }
    }

    const handleTranslatedDurationUpdate = (value) => {
        console.log('handleTranslatedDurationUpdate', value)
        if (value <= 25) {
            console.log('handleTranslatedDurationUpdate', 'shortest')
            setDurationValue("shortest")
        } else if (value > 26 && value < 50) {
            setDurationValue(() => "shorter")
            console.log('handleTranslatedDurationUpdate', 'shorter')
        } else if (value >= 50 && value < 75) {
            setDurationValue(() => "longer")
            console.log('handleTranslatedDurationUpdate', 'longer')
        } else if (value >= 76 && value < 100) {
            setDurationValue(() => "longest")
            console.log('handleTranslatedDurationUpdate', 'longest')
        }
    }

    return (
      <div style={{paddingBottom: "80px" }}>
        <div className={'animate__animated animate__bounceInLeft'} >
            <Center paddingTop={props.isMobile ? 8 : 6}>
              <Progress w={props.isMobile ? '200px' : '40%' } colorScheme={'green'} size={props.isMobile && 'lg'} height={props.isMobile ? "1rem" : "32px"} value={70} borderRadius={20} bg={"rgba(0, 0, 0, 0.4);"}/>
            </Center>
          <Flex paddingTop={props.isMobile ? 4 : 10} >
            <Box minW={props.isMobile ? 3 : 100} >
            </Box>
            <Box h='25vh' flex={1} bg={"rgba(0, 0, 0, 0.4);"} maxW='sm' color="white" style={{borderRadius: '2rem'}}>
              <Container style={{paddingTop: props.isMobile ? '60px' : '7vh'}}>
                <Heading fontSize='4xl'>{firstLineText}</Heading>
                <Text fontSize='2xl'>{secondLineText}</Text>
              </Container>
            </Box>
            <Box minW={3}>
            </Box>
          </Flex>
  
          <Flex paddingTop={4} >
            <Box >
            </Box>
            {props.isMobile ? null : <Spacer />}
            <Box h='20vh' flex={1} minW='3' color="white" style={{borderRadius: '2rem', paddingLeft: '10px'}} textAlign={"-webkit-center"}>
              <Box flex={1} bg={"rgba(0, 0, 0, 0.4);"} maxW='sm' color="white" style={{borderRadius: '2rem'}} marginBottom={8}>
                <Flex paddingTop={4} padding={8} display={"inline-block"}>
                    <Box >
                    </Box>
                    <Center
                     fontWeight='bold'
                     letterSpacing='wide'
                     fontSize='xl'>
                        <Text>{(durationValue === "duration" || strengthValue === "strength") && "Adjust to your preference"}</Text>
                        <Text fontSize={'lg'}>{(strengthValue !== "strength" && durationValue !== "duration") && `${strengthValue} effect,  
                        ${durationValue} length` }</Text> 

                    </Center>
                    <Box >
                    </Box>
                </Flex>  
              </Box>
              <Center
                 fontWeight='semibold'
                 letterSpacing='wide'
                 fontSize='lg'>
                  <Text>cannabis strength</Text>
              </Center>
              <Box flex={1} bg={"rgba(0, 0, 0, 0.4);"} maxW='sm' color="white" style={{borderRadius: '2rem'}} marginBottom={8}>
                <Flex paddingTop={4} padding={8} display={"inline-block"}>
                    <Box >
                    </Box>
                    <Center>
                    <Slider
                        
                        width={325}
                        id='slider'
                        defaultValue={50}
                        min={0}
                        max={100}
                        
                        colorScheme='teal'
                        onChange={(v) => setSliderValue(v)}
                        onChangeEnd={(v) => handleTranslatedStrengthUpdate(v)}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                        >
                        <SliderMark value={3} mt='1' ml='-2.5' fontSize='sm' paddingTop={2}>
                            light
                        </SliderMark>
                
                        <SliderMark value={90} mt='1' ml='-2.5' fontSize='sm' paddingTop={2}>
                            strong
                        </SliderMark>
                        <SliderTrack>
                            <Box position='relative'></Box>
                            <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb boxSize={6}/>
                        </Slider>
                      </Center>
                    <Box >
                    </Box>
                </Flex>  
              </Box>
              <Center
                fontWeight='semibold'
                letterSpacing='wide'
                fontSize='lg'>
                  <Text>onset speed, duration</Text>
              </Center>
              <Box flex={1} bg={"rgba(0, 0, 0, 0.4);"} maxW='sm' color="white" style={{borderRadius: '2rem'}} >
                <Flex paddingTop={4} padding={8} display={"inline-block"}>
                    <Box >
                    </Box>
                    <Center>
                    <Slider
                        
                        width={325}
                        id='slider'
                        defaultValue={50}
                        min={0}
                        max={100}
                        
                        colorScheme='teal'
                        onChange={(v) => setSliderValue(v)}
                        onChangeEnd={(v) => handleTranslatedDurationUpdate(v)}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                        >
                        <SliderMark value={3} mt='1' ml='-2.5' fontSize='sm' paddingTop={2}>
                            quick, short
                            
                        </SliderMark>
                
                        <SliderMark value={80} mt='1' ml='-35.5' fontSize='sm' paddingTop={2}>
                            delayed, longer
                        </SliderMark>
                        <SliderTrack>
                            <Box position='relative'></Box>
                            <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb boxSize={6}/>
                        </Slider>
                      </Center>
                    <Box >
                    </Box>
                </Flex>  
              </Box>
            </Box>
            <Box minW={props.isMobile ? 3 : 100}>
            </Box>
          </Flex>
          <Center style={{paddingTop: "240px"}}>
            <Link to={route}>
                <Button width={props.isMobile ? "70vw" : "30vw"} style={{color: "white"}}_hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} variant={'outline'} colorScheme='grey' isDisabled={durationValue === "duration" || strengthValue === "strength"} onClick={ saveUserData }>Continue</Button>
            </Link>
          </Center>
        </div>
      </div> 
    )
  }

  export default TypeOfEffect;