import { Link } from "react-router-dom";
import Map from './Map';
import { Box, Button, Container, Center, Text, Flex, Heading, Image, Divider, Spacer } from '@chakra-ui/react'

const PlaceOrder = (props) => {
    let mobile = props.isMobile;
    let cart = props.cart;
    let user = props.user;
    let subtotal = 0
    cart.forEach(item => {
        subtotal += (Number(item.pricePerUnit) * Number(item.quantityInCart))
    })



    const MobilePlaceOrder = () => {
        return (
            <Box > 
                <Flex flexDir={'column'} >
                    <Box  color={'white'} fontSize={'2xl'} fontWeight={'black'} p={4} marginBottom={-6}>
                        Pickup Details
                    </Box>
                    <Box bg={"rgba(0,0,0,.5)"} borderRadius={10} color={'white'} p={4} m={3} fontSize={'sm'}>
                        <Flex>
                            <Box flex={1}>
                                <Flex flexDir={'column'} >
                                    <Box flex={1} p={4} >
                                        <Center borderRadius={10} backgroundColor={'gray.700'} h={'100%'} >
                                            <Map /> 
                                        </Center>
                                    </Box>
                                    <Box flex={1}>
                                        Pickup From: Dispensary Name
                                    </Box>
                                    <Box flex={1}>
                                    Los Angeles - Venice (Abbot Kinney)
                                    </Box>
                                    <Box flex={1}>
                                    1310 Abbot Kinney Blvd Venice
                                    </Box>
                                    <Box flex={1}>
                                    California 90291
                                    </Box>
                                    <Box flex={1}>
                                    (424) 330-7232
                                    </Box>
                                    <Box flex={1}>
                                    Estimated Pickup Time: 8:57 pm | Curbside Pickup
                                    </Box>
                                </Flex>
                            </Box>
                            
                        </Flex>
                    
                    </Box>
                    <Box  color={'white'} fontSize={'2xl'} fontWeight={'black'} p={4} marginBottom={-6}>
                        Payment
                    </Box>
                    <Box bg={"rgba(0,0,0,.5)"} borderRadius={10} color={'white'} p={4} m={3} fontSize={'sm'}>
                        <Flex flexDir={'column'}>
                            <Box>Payment is due when you pick up your order. We accept the following methods: </Box> 
                            <Box>
                                <Flex>
                                    <Box flex={1}> 
                                        <Flex flexDir={'column'}>
                                            <Center>
                                                <Image p={2} w={16} src='https://flowerly-app-storage.s3.amazonaws.com/assets/summaryIcons/night.png'/>
                                            </Center>
                                            <Center fontWeight={'bold'}>
                                                <Text>Cash</Text>
                                            </Center>
                                        </Flex>
                                    </Box>
                                    <Box flex={1}> 
                                        <Flex flexDir={'column'}>
                                            <Center>
                                                <Image p={2} w={16} src='https://flowerly-app-storage.s3.amazonaws.com/assets/summaryIcons/night.png'/>
                                            </Center>
                                            <Center fontWeight={'bold'}>
                                                <Text>Debit Card</Text>
                                            </Center>
                                        </Flex>
                                    </Box>
                                    <Box flex={1}> 
                                        <Flex flexDir={'column'}>
                                            <Center>
                                                <Image p={2} w={16} src='https://flowerly-app-storage.s3.amazonaws.com/assets/summaryIcons/night.png'/>
                                            </Center>
                                            <Center fontWeight={'bold'}>
                                                <Text>Crypto</Text>
                                            </Center>
                                            <Center fontSize={'x-small'}>
                                                <Text>(Coming Soon)</Text>
                                            </Center>
                                        </Flex>
                                    </Box>
                                </Flex>
                            </Box>
                        </Flex>
                    </Box>
                    <Box color={'white'} fontSize={'2xl'} fontWeight={'black'} p={4} marginBottom={-6}>
                        Shopping bag
                    </Box>
                    <Box  bg={"rgba(0,0,0,.5)"} borderRadius={10} color={'white'} p={4} m={3} fontSize={'sm'}>
                        <Flex flexDir={'column'}>
                                {cart.length > 0 && (
                                    cart.map(( item, i ) => {
                                        let quantity = item.quantityInCart
                                        let formatted_data = item
                                        console.log('is this working', formatted_data)
                                        return (
                                            <Box backgroundColor={'gray.500'} borderRadius={10} marginTop={i !== 0 && 4}>
                                            <Flex>
                                                <Center flex={1} marginLeft={4} >
                                                    <Flex>
                                                        {/* <Box flex={1}  p={2}>
                                                            <Flex flexDir={'column'} >
                                                                <Center>
                                                                    <Button isDisabled borderRadius={100} variant={'outline'} size={'xs'}> - </Button>
                                                                </Center>    
                                                            </Flex>
                                                            
                                                        </Box> */}
                                                        <Box flex={1} >
                                                            <Flex flexDir={'column'}>
                                                                <Text>quantity</Text>
                                                                <Text textAlign={'center'}>{quantity ? quantity : 1}</Text>
                                                            </Flex>
                                                        </Box>
                                                        {/* <Box flex={1}  p={2}>
                                                            <Flex flexDir={'column'} >
                                                                <Center>
                                                                    <Button isDisabled borderRadius={100} variant={'outline'} size={'xs'}> + </Button>
                                                                </Center>    
                                                            </Flex>
                                                            
                                                        </Box> */}
                                                        
                                                    </Flex>
                                                    
                                                </Center>
                                                <Center flex={1}>
                                                    <Image p={2} marginLeft={4} src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png"/>
                                                </Center>
                                                <Center flex={3}>
                                                    <Flex flexDir={'column'}>
                                                        <Text fontSize={'lg'} fontWeight={'black'}>{`${formatted_data.productName}`} </Text>
                                                        <Text fontSize={'xs'} fontWeight={'bold'}>{`${formatted_data.strainType}   ${formatted_data.category}`}</Text>
                                                    </Flex>
                                                </Center>
                                                <Center flex={1} fontWeight={'bold'} p={2} marginRight={2}>
                                                    
                                                    <Flex flexDir={'column'}>
                                                        <Center>
                                                            <Text fontSize={'xl'} fontWeight={'bold'}>${formatted_data.pricePerUnit}</Text>
                                                        </Center>
                                                        <Center>
                                                            <Text fontSize={'xs'} fontWeight={'medium'}>{formatted_data.units} {formatted_data.productType}</Text>
                                                        </Center>
                                                    </Flex>
                                                </Center>
                                            </Flex>
                                        </Box>
                                        )
                                    })
                                )}
                        </Flex>
                    </Box>
                    <Box  color={'white'} fontSize={'2xl'} fontWeight={'black'} p={4} marginBottom={-6}>
                        Order Summary
                    </Box>
                    <Box bg={"rgba(0,0,0,.5)"} borderRadius={10} color={'white'} p={4} m={3} fontSize={'sm'} >
                        <Box  >
                            <Flex flexDir={'column'}>
                                <Box>
                                    <Flex fontWeight={'bold'}>
                                    <Box>
                                        <Text>Subtotal</Text>
                                    </Box>
                                    <Spacer />
                                    <Box>
                                        <Text>${subtotal.toFixed(2)}</Text>
                                    </Box>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Flex fontWeight={'bold'}>
                                    <Box>
                                        <Text>Exercise Tax</Text>
                                    </Box>
                                    <Spacer />
                                    <Box>
                                        <Text>$5.40</Text>
                                    </Box>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Flex fontWeight={'bold'}>
                                    <Box>
                                        <Text>Local Tax</Text>
                                    </Box>
                                    <Spacer />
                                    <Box>
                                        <Text>$4.14</Text>
                                    </Box>
                                    </Flex>
                                </Box>
                                <Box>
                                    <Flex fontWeight={'bold'}>
                                    <Box>
                                        <Text>Sales Tax</Text>
                                    </Box>
                                    <Spacer />
                                    <Box>
                                        <Text>$4.33</Text>
                                    </Box>
                                    </Flex>
                                </Box>
                                <Center >
                                    <Divider p={2}/>
                                </Center >
                                <Box marginTop={4}>
                                    <Flex fontWeight={'bold'} fontSize={'md'}>
                                        <Box>
                                            <Text>Grand Total</Text>
                                        </Box>
                                        <Spacer />
                                        <Box>
                                            <Text>${Number(subtotal + 13.87).toFixed(2)}</Text>
                                        </Box>
                                    </Flex>
                                </Box>
                                
                            </Flex>
                        </Box>
                    </Box>
                    <Box  color={'white'} fontSize={'2xl'} fontWeight={'black'} p={4} marginBottom={-6}>
                        Checkout
                    </Box>
                    <Box bg={"rgba(0,0,0,.5)"} borderRadius={10} color={'white'} p={4} m={3} fontSize={'sm'}>
                        <Box  >
                            <Flex flexDir={'column'}>
                                <Box marginTop={2}>
                                    <Flex fontWeight={'bold'} fontSize={'md'}>
                                    <Box>
                                        <Text>Checkout Total</Text>
                                    </Box>
                                    <Spacer />
                                    <Box>
                                        <Text >${Number(subtotal + 13.87).toFixed(2)}</Text>
                                    </Box>
                                    </Flex>
                                </Box>
                                <Center >
                                    <Divider p={2}/>
                                </Center >
                                <Box marginTop={6}>
                                    <Link to={'/checkout/completeOrder'}>
                                        <Button isFullWidth bgColor={'green.500'}>Place Order</Button>
                                    </Link>
                                </Box>
                            </Flex>
                        </Box>
                    </Box>
                   
                   
                </Flex>
            </Box>
        )
    }

    const DesktopPlaceOrder = () => {
        return (
            <Box p={6}> 
                <Flex>
                        <Box flex={1}/>
                        <Box flex={6} >
                            
                            <Flex color={'white'} fontSize={'2xl'} fontWeight={'black'} p={4} marginBottom={-6}>
                                <Box flex={4} >Pickup Details</Box>
                                <Box flex={2}>Checkout</Box>
                            </Flex>
                            <Flex>
                                <Box flex={4} bg={"rgba(0,0,0,.5)"} borderRadius={10} color={'white'} p={4} m={3} fontSize={'sm'}>
                                    <Flex>
                                        <Box flex={1}>
                                            <Flex flexDir={'column'} p={4} >
                                                <Box flex={1}>
                                                    Pickup From: Dispensary Name
                                                </Box>
                                                <Box flex={1}>
                                                Los Angeles - Venice (Abbot Kinney)
                                                </Box>
                                                <Box flex={1}>
                                                1310 Abbot Kinney Blvd Venice, California 90291
                                                </Box>
                                                <Box flex={1}>
                                                (424) 330-7232
                                                </Box>
                                                <Box flex={1}>
                                                Estimated Pickup Time: 8:57 pm | Curbside Pickup
                                                </Box>
                                            </Flex>
                                        </Box>
                                        <Box flex={1} p={4} >
                                            <Center borderRadius={10} backgroundColor={'gray.700'} h={'100%'} >
                                                <Map user={user}/>
                                            </Center>
                                        </Box>
                                    </Flex>
                                
                                </Box>
                                <Box flex={2} bg={"rgba(0,0,0,.5)"} borderRadius={10} color={'white'} p={4} m={3} fontSize={'sm'}>
                                    <Box  >
                                        <Flex flexDir={'column'}>
                                            <Box marginTop={2}>
                                                <Flex fontWeight={'bold'}>
                                                <Box>
                                                    <Text>Checkout</Text>
                                                </Box>
                                                <Spacer />
                                                <Box>
                                                    <Text>${Number(subtotal + 13.87).toFixed(2)}</Text>
                                                </Box>
                                                </Flex>
                                            </Box>
                                            <Center >
                                                <Divider p={2}/>
                                            </Center >
                                            <Box marginTop={6}>
                                            
                                                <Link to={'/checkout/completeOrder'}>
                                                    <Button isFullWidth bgColor={'Green'}>Place Order</Button>
                                                </Link>
                                        
                                            </Box>
                                        </Flex>
                                    </Box>
                                
                                </Box>
                            </Flex>
                            <Flex color={'white'} fontSize={'2xl'} fontWeight={'black'} p={4} marginBottom={-6}>
                                <Box flex={4} >Payment</Box>
                                <Box flex={2}>Summary</Box>
                            </Flex>
                            <Flex>
                                <Box flex={4} bg={"rgba(0,0,0,.5)"} borderRadius={10} color={'white'} p={4} m={3} fontSize={'sm'}>
                                    <Flex flexDir={'column'}>
                                        <Box>Payment is due when you pick up your order. We accept the following methods: </Box> 
                                        <Box>
                                            <Flex>
                                                <Box flex={1}> 
                                                    <Flex flexDir={'column'}>
                                                        <Center>
                                                            <Image p={2} w={16} src='https://flowerly-app-storage.s3.amazonaws.com/assets/summaryIcons/night.png'/>
                                                        </Center>
                                                        <Center>
                                                            <Text>Cash</Text>
                                                        </Center>
                                                    </Flex>
                                                </Box>
                                                <Box flex={1}> 
                                                    <Flex flexDir={'column'}>
                                                        <Center>
                                                            <Image p={2} w={16} src='https://flowerly-app-storage.s3.amazonaws.com/assets/summaryIcons/night.png'/>
                                                        </Center>
                                                        <Center>
                                                            <Text>Debit Card</Text>
                                                        </Center>
                                                    </Flex>
                                                </Box>
                                                <Box flex={1}> 
                                                    <Flex flexDir={'column'}>
                                                        <Center>
                                                            <Image p={2} w={16} src='https://flowerly-app-storage.s3.amazonaws.com/assets/summaryIcons/night.png'/>
                                                        </Center>
                                                        <Center>
                                                            <Text>Crypto (Coming Soon)</Text>
                                                        </Center>
                                                    </Flex>
                                                </Box>
                                            </Flex>
                                        </Box>
                                    </Flex>
                                </Box>
                                <Box flex={2} bg={"rgba(0,0,0,.5)"} borderRadius={10} color={'white'} p={4} m={3} fontSize={'sm'}>
                                    <Box  >
                                        <Flex flexDir={'column'}>
                                            <Box>
                                                <Flex fontWeight={'bold'}>
                                                <Box>
                                                    <Text>Subtotal</Text>
                                                </Box>
                                                <Spacer />
                                                <Box>
                                                    <Text>${subtotal.toFixed(2)}</Text>
                                                </Box>
                                                </Flex>
                                            </Box>
                                            <Box>
                                                <Flex fontWeight={'bold'}>
                                                <Box>
                                                    <Text>Exercise Tax</Text>
                                                </Box>
                                                <Spacer />
                                                <Box>
                                                    <Text>$5.40</Text>
                                                </Box>
                                                </Flex>
                                            </Box>
                                            <Box>
                                                <Flex fontWeight={'bold'}>
                                                <Box>
                                                    <Text>Local Tax</Text>
                                                </Box>
                                                <Spacer />
                                                <Box>
                                                    <Text>$4.14</Text>
                                                </Box>
                                                </Flex>
                                            </Box>
                                            <Box>
                                                <Flex fontWeight={'bold'}>
                                                <Box>
                                                    <Text>Sales Tax</Text>
                                                </Box>
                                                <Spacer />
                                                <Box>
                                                    <Text>$4.33</Text>
                                                </Box>
                                                </Flex>
                                            </Box>
                                            <Center >
                                                <Divider p={2}/>
                                            </Center >
                                            <Box marginTop={4}>
                                                <Flex fontWeight={'bold'}>
                                                <Box>
                                                    <Text>Grand Total</Text>
                                                </Box>
                                                <Spacer />
                                                <Box>
                                                    <Text>${Number(subtotal + 13.87).toFixed(2)}</Text>
                                                </Box>
                                                </Flex>
                                            </Box>
                                            
                                        </Flex>
                                    </Box>
                                    
                                </Box>
                            </Flex>
                            <Flex color={'white'} fontSize={'2xl'} fontWeight={'black'} p={4} marginBottom={-6}>
                                <Box flex={4} >Shopping bag</Box>
                                
                            </Flex>
                            <Flex>
                                <Box flex={4} bg={"rgba(0,0,0,.5)"} borderRadius={10} color={'white'} p={4} m={3} fontSize={'sm'}>
                                <Flex>
                                    <Box flex={1}></Box>
                                    <Box flex={10}>
                                        <Flex flexDir={'column'}>
                                                {cart.length > 0 && (
                                                    cart.map(( item, i ) => {
                                                        let quantity = item.quantityInCart
                                                        // let formatted_data = item.data
                                                        let formatted_data = item
                                                        subtotal += (Number(formatted_data.pricePerUnit) * Number(formatted_data.quantityInCart))
                                                        console.log('is this working', formatted_data)
                                                        return (
                                                            <Box p={2} flex={1} backgroundColor={'gray.700'} borderRadius={10} m={3}>
                                                                
                                                                <Flex>
                                                                    <Center flex={1} >
                                                                        <Flex>
                                                                            <Box flex={1}  p={2}>
                                                                                <Flex flexDir={'column'} >
                                                                                    <Center>
                                                                                        <Button isDisabled borderRadius={100} variant={'outline'} size={'xs'}> - </Button>
                                                                                    </Center>    
                                                                                </Flex>
                                                                                
                                                                            </Box>
                                                                            <Box flex={1} >
                                                                                <Flex flexDir={'column'}>
                                                                                    <Text>quantity</Text>
                                                                                    <Text textAlign={'center'}>{quantity ? quantity : 1}</Text>
                                                                                </Flex>
                                                                            </Box>
                                                                            <Box flex={1}  p={2}>
                                                                                <Flex flexDir={'column'} >
                                                                                    <Center>
                                                                                        <Button isDisabled borderRadius={100} variant={'outline'} size={'xs'}> + </Button>
                                                                                    </Center>    
                                                                                </Flex>
                                                                                
                                                                            </Box>
                                                                            
                                                                        </Flex>
                                                                        
                                                                    </Center>
                                                                    <Center flex={1}>
                                                                        <Image p={2} maxHeight={'60px'} src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png"/>
                                                                    </Center>
                                                                    <Box flex={1} >
                                                                        <Flex flexDir={'column'} position="absolute">
                                                                            <Text fontSize={'lg'} fontWeight={'black'}>{`${formatted_data.productName}`} <Text fontSize={'sm'} fontWeight={'medium'}>{formatted_data.units} {formatted_data.productType}</Text></Text>
                                                                            <Text fontSize={'xs'} fontWeight={'bold'}>{`${formatted_data.strainType}   ${formatted_data.category}`}</Text>
                                                                        </Flex>
                                                                    </Box>
                                                                    <Center flex={1} fontSize={'xl'} fontWeight={'medium'} p={2} marginRight={2}>${Math.round(formatted_data.pricePerUnit)}</Center>
                                                                </Flex>
                                                            
                                                            </Box>
                                                        )
                                                    })
                                                )}
                                        </Flex>
                                    
                                    </Box>
                                    <Box flex={1}></Box>
                                </Flex>
                                </Box>
                            
                            </Flex>
                        </Box>
                        <Box flex={1}/>
                </Flex>
            </Box>
        )
    }
    
    return (
        <>
            {mobile ? <MobilePlaceOrder /> : <DesktopPlaceOrder /> }
        </>
    );
  }

export default PlaceOrder;
  