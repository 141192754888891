import { useState, useContext, useEffect } from 'react';
import { Link } from "react-router-dom";
import {getInventory} from '../utilities/flowerlyApi'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { MdOutlineLocationSearching, MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md';
import {BsCaretDownFill} from 'react-icons/bs'
import UniversalQuestion from './questions/UniversalQuestion';
import { Box, Flex, Heading, Spacer, Button, Stat, Progress, useToast, Center, Image, Text, SimpleGrid, Divider, Skeleton, Select, Input, HStack, InputGroup, InputLeftAddon, InputLeftElement, Stack, Menu, MenuItem, MenuList, MenuButton, Modal, ModalOverlay, useDisclosure, ModalContent, List , ListIcon, ListItem} from '@chakra-ui/react';
import { formatOptions, consumptionOptions, ageOptions, intentionsOptions, medicalOptions, mostImportantOptions, technicalOptions, customerType, experienceType, strengthOptions, durationOptions, timeOfDayOptions } from '../utilities/menuOptions'
import { BsCheckCircle } from 'react-icons/bs'
import AgeVerify from './questions/AgeVerify';
import { isUndefined } from 'lodash';

const testArray = [1, 2, 3]
const longArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

const getItems = () =>
  Array(20)
    .fill(0)
    .map((_, ind) => ({ id: `element-${ind}` }));

const Summary = (props) => {
    const {user, handleSelectedProduct} = props;
    const [items, setItems] = useState(getItems);
    const [selected, setSelected] = useState({});
    const [position, setPosition] = useState(0);
    let [popoverStatus, setPopoverStatus] = useState(false)
    const [loaded, setLoaded] = useState(false);
    const [filteredResults, setFilteredResult] = useState([])
    const {experienceLevel, visitReason = '', consumptionMethod, intentionFeelings, preferredDuration, preferredStrength, preferredTimeOfDay, googleCoords} = user
    const inventory = props.inventory

    const placeholderProductImages = {
        Drink: 'https://flowerly-app-storage.s3.amazonaws.com/tempImages/drink-placeholder.webp',
        Concentrate: 'https://flowerly-app-storage.s3.amazonaws.com/tempImages/concentrates-placeholder.webp',
        drops: 'https://flowerly-app-storage.s3.amazonaws.com/tempImages/drops-placeholder.webp',
        rub: 'https://flowerly-app-storage.s3.amazonaws.com/tempImages/rub-placeholder.webp',
        PackedBud: 'https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.webp',
        patch: 'https://flowerly-app-storage.s3.amazonaws.com/tempImages/patch-placeholder.webp',
        Edible: 'https://flowerly-app-storage.s3.amazonaws.com/tempImages/edibles-placeholder.webp',
    }

    const translatedSummaryFeeling = {
        euphoric: 'Uplifting',
        focused: 'Uplifting',
        creative: 'Uplifting',
        happy: 'Uplifting',                 
        calm: 'Relaxing',               
        relaxed: 'Relaxing',               
        giggly: 'Laid-Back',                  
        hungry: 'Laid-Back',     
        sleep: 'Drowsy',
        tired: 'Drowsy',
    };

    const translatedSummaryFormat = {
        smoke: 'Weed',
        vape: 'Vapes',
        eat: 'Edibles',
        drink: 'Drinks',
        rub: 'Rubs and Creams',
        drops: 'Drops, Tinctures, and Pills',
        patches: 'Patches',
    };

    const consumptionIcons = {
        smoke: 'https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/joint-w.webp',
        vape: 'https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/vape-w.webp',
        eat: 'https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/eat-w.webp',
        drink: 'https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/drink-w.webp',
        rub: 'https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/rub-w.webp',
        drops: 'https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/pill-w.webp',
        patches: 'https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/patch-w.webp',
    }

    const feelingIcons = {
        euphoric: 'https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/euphoric-w.webp',
        focused: 'https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/focused-w.webp',
        creative: 'https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/creative-w.webp',
        happy: 'https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/happy-w.webp',                 
        calm: 'https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/calm-w.webp',                
        relaxed: 'https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/relaxed-w.webp',                   
        giggly: 'https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/giggly-w.webp',                  
        hungry: 'https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/hungry-w.webp',     
        sleep: 'https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/tired-w.webp',
        tired: 'https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/tired-w.webp',
    }     

    const strengthIcons = {
        lightest: 'https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/lighter-w.webp',
        lighter: 'https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/lighter-w.webp',
        stronger: 'https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/stronger-w.webp',
        strongest: 'https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/stronger-w.webp',
    }

    const timeOfDayIcons = {
        morning: 'https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/morning-w.webp',
        day: 'https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/day-w.webp',
        afternoon: 'https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/afternoon-w.webp',
        evening: 'https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/evening-w.webp',
        night: 'https://flowerly-app-storage.s3.amazonaws.com/assets/universalIcons/night-w.webp',
    }

    const filterCategorys = (incomingData, optionalConsumptionMethod) => {
        const cleanedData = []
        
        if (optionalConsumptionMethod || consumptionMethod !== '') {
            incomingData.forEach(element => {
               
                switch (optionalConsumptionMethod ? optionalConsumptionMethod : consumptionMethod) {
                    case 'smoke':
                        if (element.category === "Concentrate") {
                            if (element.productAttributes.productImages[0] === "") {
                                element.productAttributes.productImages[0] = 'https://flowerly-app-storage.s3.amazonaws.com/tempImages/concentrates-placeholder.webp'
                            }
                            cleanedData.push(element)
                            break;
                        } else if (element.category === "PackedBud" ) {
                            cleanedData.push(element)
                            break;
                        } else if (element.category === "Joint" ) {
                            cleanedData.push(element)
                            break;
                        } else if (element.category === "BulkBud") {
                            cleanedData.push(element)
                            break;
                        } else if (element.category === "PackedBudNonStandard") {
                            cleanedData.push(element)
                            break;
                        } else {
                            break;
                        }
                    case 'vape':
                        if (element.category === "Concentrate") {
                            if (element.productAttributes.productImages[0] === "") {
                                element.productAttributes.productImages[0] = 'https://flowerly-app-storage.s3.amazonaws.com/tempImages/concentrates-placeholder.webp'
                            }
                            cleanedData.push(element)
                            break;
                        } else {
                            break;
                        }
                    case 'eat':
                        if (element.category === "Edible") {
                            if (element.productAttributes.productImages[0] === "") {
                                element.productAttributes.productImages[0] = 'https://flowerly-app-storage.s3.amazonaws.com/tempImages/edibles-placeholder.webp'
                            }
                            cleanedData.push(element)
                            break;
                        } else {
                            break;
                        }
                    case 'drink':
                        if (element.category === "Edible" && element.productType === "Drink") {
                            if (element.productAttributes.productImages[0] === "") {
                                element.productAttributes.productImages[0] = 'https://flowerly-app-storage.s3.amazonaws.com/tempImages/drink-placeholder.webp'
                            }
                            cleanedData.push(element)
                            break;
                        } else {
                            break;
                        }
                    case 'rub':
                        if (element.category === "NonEdible" ) {
                            if (element.productType === "Cream" || element.productType === "Body Oil" || element.productType === "Salve") {
                                if (element.productAttributes.productImages[0] === "") {
                                    element.productAttributes.productImages[0] = 'https://flowerly-app-storage.s3.amazonaws.com/tempImages/rub-placeholder.webp'
                                }
                                cleanedData.push(element)
                                break;
                            }
                        } else {
                            break;
                        }
                    case 'drops':
                        if (element.category === "Edible" ) {
                            if (element.productType === "Tincture" || element.productType === 'Sublingual') {
                                if (element.productAttributes.productImages[0] === "") {
                                    element.productAttributes.productImages[0] = 'https://flowerly-app-storage.s3.amazonaws.com/tempImages/drops-placeholder.webp'
                                }
                                cleanedData.push(element)
                                break;
                            }
                        } else {
                            break;
                        }
                    case 'patches':
                        if (element.category === "NonEdible" ) {
                            if (element.productType === "Transdermal") {
                                if (element.productAttributes.productImages[0] === "") {
                                    element.productAttributes.productImages[0] = 'https://flowerly-app-storage.s3.amazonaws.com/tempImages/patch-placeholder.webp'
                                }
                                cleanedData.push(element)
                                break;
                            }
                        } else {
                            break;
                        }
                    case 'flower':
                        if (element.category === "PackedBud" ) {
                            cleanedData.push(element)
                            break;
                        } else if (element.category === "PackedBudNonStandard" ) {
                            cleanedData.push(element)
                            break;
                        } else if (element.category === "BulkBud") {
                            cleanedData.push(element)
                            break;
                        } else {
                            break;
                        }
                    case 'preroll':
                        if (element.category === "Joint" ) {
                            cleanedData.push(element)
                            break;
                        } else {
                            break;
                        }
                    case 'vaporizor':
                        if (element.category === "Concentrate") {
                            if (element.productType === "Cartridge" || element.productType === 'Live Resin Cartridge' || element.productType === 'Disposable' || element.productType === 'Rosin Pod' || element.productType === '710 Pod T1' || element.productType === '710 Pod T2' || element.productType === 'Pax Pod') {
                                cleanedData.push(element)
                                break;
                            }
                        } else {
                            break;
                        }
                    case 'concentrates':
                        if (element.category === "Concentrate") {
                            if (element.productAttributes.productImages[0] === "") {
                                element.productAttributes.productImages[0] = 'https://flowerly-app-storage.s3.amazonaws.com/tempImages/concentrates-placeholder.webp'
                            }
                            cleanedData.push(element)
                            break;
                        } else {
                            break;
                        }
                    case 'edibles':
                        if (element.category === "Edible") {
                            if (element.productAttributes.productImages[0] === "") {
                                element.productAttributes.productImages[0] = 'https://flowerly-app-storage.s3.amazonaws.com/tempImages/edibles-placeholder.webp'
                            }
                            cleanedData.push(element)
                            break;
                        } else {
                            break;
                        }
                    case 'tinctures':
                        if (element.category === "Edible" ) {
                            if (element.productType === "Tincture" || element.productType === 'Sublingual') {
                                cleanedData.push(element)
                                break;
                            }
                        } else {
                            break;
                        }
                    case 'topicals':
                        if (element.category === "NonEdible" ) {
                            if (element.productAttributes.productImages[0] === "") {
                                element.productAttributes.productImages[0] = 'https://flowerly-app-storage.s3.amazonaws.com/tempImages/rub-placeholder.webp'
                            }
                            cleanedData.push(element)
                            break;
                            
                        } else {
                            break;
                        }
                    default:
                        cleanedData.push(element)
                        break;
                      }
            });
            // console.log('hello 1', cleanedData.length)
            setFilteredResult(cleanedData);
            // console.log('reset state')
            return 
        } else {
            return setFilteredResult(incomingData);
        }
    }

    const updateResults = (inventoryData) => {
        let sourceData = inventoryData;
        console.log('is this getting called, ', sourceData.length)
        // console.log('is this getting called, ', sourceData)
        const newData = filterCategorys(sourceData);
        
    }

    const refreshInventory = async () => {
        const inventoryData = await getInventory().then((data) => {updateResults(data['data']); props.handleAddInventory(data); })
        // console.log('refrech it - call it bitch', inventoryData)
        // props.handleAddInventory(inventoryData)
        

    }

    const handleOptionChange = (e) => {
        const raw = JSON.parse(e.target.value);
        console.log('what is this', raw)
        props.handleAddUserData(raw.saveDataEntryValue, raw.saveDataEntryName)
        if (raw.saveDataEntryName === 'consumptionMethod') {
            filterCategorys(inventory, raw.saveDataEntryValue)

        } else {
            filterCategorys(inventory)
        }
       
        
        
    }  

    function LeftArrow() {
        const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);
    
        return (
        <Center flex={1}>
            <Button variant='outline' height={20} borderRadius={16} disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
                <MdOutlineArrowBackIosNew />
            </Button>
        </Center> 
        );
    }
  
    function RightArrow() {
        const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);
    
        return (
         <Center flex={1}>
            <Button variant='outline' height={20} borderRadius={16} disabled={isLastItemVisible} onClick={() => scrollNext()}>
                <MdOutlineArrowForwardIos />
            </Button>
        </Center>
        );
    }

    const AddToCart = ({productName, productId, data}) => {
        const toast = useToast()
        return (
                <Box >
                    <Flex>
                        <Box>
                            
                                <Button size={'sm'} isDisabled={productId === ""} isFullWidth color={"black"} onClick={() => { 
                                    props.handleUpdateCart(data);
                                    toast({
                                        title: `${productName} in your cart.`,
                                        description: Math.round(Math.random()) > 0 ? "Nice choice 😉" : "Good pick 👌",
                                        status: 'success',
                                        duration: 2000,
                                        isClosable: true,
                                        position:'bottom'
                                      }); }}>
                                    add to cart
                                </Button>
                             
                        </Box>
                    </Flex>
                </Box>
        )
    }

    function MobileCard({ productName, brand, units, price, data, productId, storeId, productWeight }) {
        // const item = { productName, brand, units, price }
        return (
            <Box borderWidth={"medium"}  borderRadius={12} p={2} className="glassEffect">
                <Link to={`/${storeId}/${productId}`} >
                    <Flex flexDir={'column'}>
                        <Center flex={1} p={1}>
                            <Image src={data.productAttributes && (data.productAttributes.productImages[0] === "" || data.productAttributes.productImages[0] === undefined) ? "https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png" : data.productAttributes.productImages[0]}  borderRadius={10}/>
                        </Center>
                        <Box flex={1} p={2}>
                            <Stack>
                                <Box>
                                    <Center>
                                        <Text fontWeight={'bold'} fontSize={'large'}>{productName}</Text>
                                    </Center>
                                    <Center>
                                        <Text>by {brand}</Text>
                                    </Center>
                                </Box>
                                <Box>
                                    <Flex >
                                        <Center flex={1}>
                                            <Image p={1} onLoad={() => setLoaded(true)} src="https://flowerly-app-storage.s3.amazonaws.com/assets/summaryIcons/match.png" />
                                        </Center>
                                        <Center flex={1}>
                                            <Image p={1} onLoad={() => setLoaded(true)} src="https://flowerly-app-storage.s3.amazonaws.com/assets/summaryIcons/sativaSun.png" />
                                        </Center>
                                        <Center flex={1}>
                                            <Image p={1} onLoad={() => setLoaded(true)} src="https://flowerly-app-storage.s3.amazonaws.com/assets/summaryIcons/energy.png" />
                                        </Center>
                                      
                                    </Flex>
                                </Box>
                                <Center flex={1}>
                                    <Flex>
                                        <Center textAlign={"center"} fontWeight={"bold"} p={1}>
                                            <Text>${price}</Text>
                                        </Center>
                                        
                                        <Center borderRadius={20} borderWidth={"thin"} p={1}>
                                            <Flex>
                                                <Box flex={1}/>
                                                <Center flex={5} w={12}>
                                                    <Text color={'white'}>{' '}{data.productUnitOfMeasure}{' '}</Text>
                                                </Center>
                                                <Box flex={1}/>
                                            </Flex>
                                        </Center>
                                    </Flex>
                                </Center>
                            </Stack>
                        </Box>
                    </Flex>
                </Link>
                <Center flex={1}>
                    <AddToCart productName={productName} productId={productId} data={data}/>
                </Center>
            </Box>
        );
    }
    
    function Card({ productName, brand, units, price, productId, storeId, data }) {
        const item = { productName, brand, units, price }
        return (
            <Link to={`/${storeId}/${productId}`} >
                <Box borderWidth={"medium"}  borderRadius={20} className="glassEffect">
                    <Flex>
                        <Center flex={2} p={2} >
                            <Image onLoad={() => setLoaded(true)} src={data.productAttributes.productImages[0] === "" ? "https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png" : data.productAttributes.productImages[0]}  borderRadius={10}/>
                        </Center>
                        <Box flex={3} p={2}>
                            <Stack>
                                <Box>
                                    <Text>{productName}</Text>
                                    <Text>by {brand}</Text>
                                </Box>
                                <Box>
                                    <Flex >
                                        <Center flex={1}>
                                            <Image p={1} onLoad={() => setLoaded(true)} src="https://flowerly-app-storage.s3.amazonaws.com/assets/summaryIcons/match.png" />
                                        </Center>
                                        <Center flex={1}>
                                            <Image p={1} onLoad={() => setLoaded(true)} src="https://flowerly-app-storage.s3.amazonaws.com/assets/summaryIcons/sativaSun.png" />
                                        </Center>
                                        <Center flex={1}>
                                            <Image p={1} onLoad={() => setLoaded(true)} src="https://flowerly-app-storage.s3.amazonaws.com/assets/summaryIcons/energy.png" />
                                        </Center>
                                        <Box flex={1}>
                                            <Stack>
                                                <Box textAlign={"center"} fontWeight={"bold"}>
                                                    <Text>${price}</Text>
                                                </Box>
                                                <Box borderRadius={20} borderWidth={"thin"}>
                                                    <Flex>
                                                        <Box flex={1}/>
                                                        <Center flex={5} w={12}>
                                                            <Text>{' '}{units}{' '}</Text>
                                                        </Center>
                                                        <Box flex={1}/>
                                                    </Flex>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    </Flex>
                                </Box>
                            </Stack>
                        </Box>
                    </Flex>
                </Box>
            </Link>
        );
    }

    function Header() {
        return (
            <Flex p={3}>
                <Box>
                    <Heading fontWeight={"extrabold"} >Hand Picked </Heading>
                    <Text fontSize={'sm'} fontWeight={'bold'}>{filteredResults.length} results for you ({inventory.length} total)</Text>
                </Box>
                <Spacer />
                <HStack spacing='24px' color={"white"} >
                    <InputGroup color={"white"}  >
                        <InputLeftElement
                        pointerEvents='none'
                        color='gray.300'
                        fontSize='1.2em'
                        children={<MdOutlineLocationSearching />}
                        />
                        <Input w={'20vw'} placeholder={googleCoords} textAlign={"center"} fontWeight={"bolder"} color={"white"} />
                    
                    </InputGroup>
                    <Select  placeholder='filter' textAlign={"center"} fontWeight={"bolder"} >
                        <option value='option1'>Option 1</option>
                        <option value='option2'>Option 2</option>
                        <option value='option3'>Option 3</option>
                    </Select>
                    <Select  placeholder='sort' textAlign={"center"} fontWeight={"bolder"} >
                        <option value='option1'>high potency</option>
                        <option value='option2'>low potency</option>
                        <option value='option3'>highest price</option>
                        <option value='option4'>lowest price</option>
                    </Select>
                </HStack >
            </Flex>
        )
    }

    function DesktopSummaryPanel() {
        return (
            <Box flex={1} h={"100%"} bg={"rgba(0,0,0,.5)"}  borderRadius={10} color={"white"}  >
                <Flex flexDirection={"column"} paddingTop={1}>
                    {/* {verbalSummaryDesktop} */}
                    <Box p={3} w={"100%"}>
                        <Text fontWeight={"extrabold"} fontSize={"1.4em"}>
                            Search Summary
                        </Text>
                        <Text fontWeight={"bold"} fontSize={"1em"} paddingTop={.5}>
                        {translatedSummaryFeeling[intentionFeelings]} {translatedSummaryFormat[consumptionMethod]} 
                        </Text>
                        <Divider paddingTop={3}/>
                    </Box>
                    <Box p={3}>
                        <Flex>
                            <Box flex={3}>
                                <Text fontWeight={"medium"} fontSize={"1.2em"}>looking for something to</Text>
                                <Menu >
                                    <MenuButton as={Button} color={"white"} variant={'link'} w={'100%'} rightIcon={<BsCaretDownFill />}>
                                        <Text>{consumptionMethod !== undefined ? consumptionMethod : 'select format'}</Text>
                                    </MenuButton>
                                    <MenuList>
                                        { consumptionOptions["options"].map((item, i) => { 
                                            // if (item.saveDataEntryValue !== consumptionMethod)
                                                return (<MenuItem color={"black"} key={i} onClick={(e) => {handleOptionChange(e)}} value={JSON.stringify(item)}>{item.displayName} </MenuItem>)
                                            })
                                        }
                                    </MenuList>
                                </Menu>
                            </Box>
                            <Box flex={1} p={1}>
                                <Image p={3} src={consumptionMethod !== undefined ? consumptionIcons[consumptionMethod] : 'https://flowerly-app-storage.s3.amazonaws.com/assets/summaryIcons/eat.png'}/>
                            </Box>
                        </Flex>
                    </Box>
                    <Spacer />
                    <Box p={3}>
                        <Flex>
                            <Box flex={3}>
                                <Text fontWeight={"medium"} fontSize={"1.2em"}>that will help me feel</Text>
                                <Menu >
                                    <MenuButton as={Button} color={"white"} variant={'link'} w={'100%'} rightIcon={<BsCaretDownFill />}>
                                        <Text>{intentionFeelings !== undefined ? intentionFeelings : 'select feeling'}</Text>
                                    </MenuButton>
                                    <MenuList>
                                        { intentionsOptions["options"].map((item, i) => { 
                                            // if (item.saveDataEntryValue !== intentionFeelings)
                                                return (<MenuItem color={"black"} key={i} onClick={(e) => {handleOptionChange(e)}} value={JSON.stringify(item)}>{item.displayName} </MenuItem>)
                                            })
                                        }
                                    </MenuList>
                                </Menu>
                            </Box>
                            <Box flex={1} p={1}>
                                <Image p={3} src={intentionFeelings !== undefined ? feelingIcons[intentionFeelings] : 'https://flowerly-app-storage.s3.amazonaws.com/assets/summaryIcons/relax.png'}/>
                            </Box>
                        </Flex>
                    </Box>
                    <Spacer />
                    <Box p={3}>
                        <Flex>
                            <Box flex={3}>
                                <Text fontWeight={"medium"} fontSize={"1.2em"}>have the</Text>
                                <Menu >
                                    <MenuButton as={Button} color={"white"} variant={'link'} w={'100%'} rightIcon={<BsCaretDownFill />}>
                                        <Text>{preferredStrength !== undefined ? preferredStrength : 'select strength'}</Text>
                                    </MenuButton>
                                    <MenuList>
                                        { strengthOptions["options"].map((item, i) => { 
                                                  return (<MenuItem color={"black"} key={i} onClick={(e) => {handleOptionChange(e)}} value={JSON.stringify(item)}>{item.displayName} </MenuItem>)
                                            })
                                        }
                                    </MenuList>
                                </Menu>
                            </Box>
                            <Box flex={1} p={1}>
                                <Image p={3} src={preferredStrength !== undefined ? strengthIcons[preferredStrength] : 'https://flowerly-app-storage.s3.amazonaws.com/assets/summaryIcons/lightest.png'}/>
                            </Box>
                        </Flex>
                    </Box>
                    <Spacer />
                    <Box p={3}>
                        <Flex>
                            <Box flex={3}>
                                <Text fontWeight={"medium"} fontSize={"1.2em"}>typically for</Text>
                                {/* <Select onChange={(e) => {handleOptionChange(e)}} variant='flushed' placeholder={preferredDuration} textAlign={"center"} fontWeight={"bolder"} fontSize={"1.4em"}>
                                {    durationOptions["options"].map((item, i) => { 
                                       if (item.saveDataEntryValue !== preferredDuration)
                                        return (<option key={i} value={JSON.stringify(item)}>{item.displayName}</option>)
                                        })
                                   }
                                </Select> */}
                                <Menu >
                                    <MenuButton as={Button} color={"white"} variant={'link'} w={'100%'} rightIcon={<BsCaretDownFill />}>
                                        <Text>{preferredDuration !== undefined ? preferredDuration : 'select duration'}</Text>
                                    </MenuButton>
                                    <MenuList>
                                        { durationOptions["options"].map((item, i) => { 
                                            // if (item.saveDataEntryValue !== intentionFeelings)
                                                return (<MenuItem color={"black"} key={i} onClick={(e) => {handleOptionChange(e)}} value={JSON.stringify(item)}>{item.displayName} </MenuItem>)
                                            })
                                        }
                                    </MenuList>
                                </Menu>
                            </Box>
                            <Box flex={1} p={1}>
                                <Image p={3} src='https://flowerly-app-storage.s3.amazonaws.com/assets/summaryIcons/shortest.png'/>
                            </Box>
                        </Flex>
                    </Box>
                    <Spacer />
                    <Box p={3}>
                        <Flex>
                            <Box flex={3}>
                                <Text fontWeight={"medium"} fontSize={"1.2em"}>during the</Text>
                                {/* <Select onChange={(e) => {handleOptionChange(e)}} variant='flushed' placeholder={preferredTimeOfDay} textAlign={"center"} fontWeight={"bolder"} fontSize={"1.4em"}>
                                {    timeOfDayOptions["options"].map((item, i) => { 
                                       if (item.saveDataEntryValue !== preferredTimeOfDay)
                                        return (<option key={i} value={JSON.stringify(item)}>{item.displayName}</option>)
                                        })
                                   }
                                </Select> */}
                                <Menu >
                                    <MenuButton as={Button} color={"white"} variant={'link'} w={'100%'} rightIcon={<BsCaretDownFill />}>
                                        <Text>{preferredTimeOfDay !== undefined ? preferredTimeOfDay : 'select time'}</Text>
                                    </MenuButton>
                                    <MenuList>
                                        { timeOfDayOptions["options"].map((item, i) => { 
                                            // if (item.saveDataEntryValue !== intentionFeelings)
                                                return (<MenuItem color={"black"} key={i} onClick={(e) => {handleOptionChange(e)}} value={JSON.stringify(item)}>{item.displayName} </MenuItem>)
                                            })
                                        }
                                    </MenuList>
                                </Menu>
                            </Box>
                            <Box flex={1} p={1}>
                                <Image p={3} src={preferredTimeOfDay !== undefined ? timeOfDayIcons[preferredTimeOfDay] : 'https://flowerly-app-storage.s3.amazonaws.com/assets/summaryIcons/night.png'}/>
                            </Box>
                        </Flex>
                    </Box>
                </Flex>
            </Box>
        )
    }

    function DesktopSummaryView () {
        return(
            <Flex p={3} >
                <Box flex={3} h={"80vh"} color={"white"} >
                    <Header />
                    {
                        [1].map((item, i) => 
                            <Box key={i} p={3}>
                                <Text fontWeight={"extrabold"} >Best for Morning Energy</Text>
                                <Flex p={3} >
                                    {/* <Center flex={1}>
                                        <Button variant='outline' height={20} borderRadius={16}>
                                            <MdOutlineArrowBackIosNew />
                                        </Button>
                                    </Center> */}
                                    <Box flex={10} w={'10vw'} overflow="auto" h={"64vh"}>
                                        <SimpleGrid columns={3} spacing={10}>
                                            
                                                {filteredResults.map((item, i) => {
                                                    
                                                      return (i < 300) &&
                                                            <Card
                                                            data={item}
                                                            productName={item.productName}
                                                            brand={item.brand}
                                                            units={item.units}
                                                            price={item.pricePerUnit}
                                                            productId={item.productId}
                                                            storeId={item.storeId}
                                                            key={'key' + i}
                                                            
                                                            />
                                                        
                                            
                                                })}
                                        </SimpleGrid>
                                        
                                        {/* <HStack> */}
                                        {/* <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
                                            {items.map(({ id, itemId, productName, brand, units, strainType, pricePerUnit }, i) => {
                                                itemId = getRandomInt()
                                                return(
                                                    <Card itemId={id} // NOTE: itemId is required for track items
                                                    productName={productName}
                                                    brand={brand}
                                                    units={units}
                                                    price={pricePerUnit}
                                                    title={productName + '_' + itemId}
                                                    key={id}
                                                    onClick={handleItemClick(id)}
                                                    selected={id === selected}>
                                                    </Card>
                                                )
                                            }
                                            )}
                                            
                                            </ScrollMenu> */}
                                        {/* </HStack> */}
                                    </Box>
                                    {/* <Center flex={1}>
                                        <Button variant='outline' height={20} borderRadius={16}>
                                            <MdOutlineArrowForwardIos />
                                        </Button>
                                    </Center> */}
                                </Flex>
                            </Box>

                        )
                    }
                </Box>
                <DesktopSummaryPanel /> 
                
            </Flex>
    
        )
    } 

    function MobileSummaryView () {
        const screenWidth = window.innerWidth;

        return (
            <Flex p={3} flexDirection={"column"} >
                        {/* <Center p={2} w={"100%"} paddingBottom={-6}>
                            <Text fontWeight={"extrabold"} fontSize={'large'}>
                                {translatedSummaryFeeling[intentionFeelings]} {translatedSummaryFormat[consumptionMethod]} 
                            </Text>
                        </Center> */}
                <Box flex={1} h={"100%"} bg={"rgba(0,0,0,.5)"}  borderRadius={10} color={"white"} marginTop={3} >
                    <Flex flexDirection={"column"} paddingTop={1}>
                        
                        <Box p={1}>
                            <Flex >
                                <Center flex={1} >
                                    <Menu>
                                        <MenuButton as={Button} color={"white"} variant={'link'} w={'100%'} >
                                            <Stack>
                                                <Center borderWidth={1.5} borderRadius={12} m={1} backgroundBlendMode={'soft-light'} backgroundColor={'rgba(255, 255, 255, 0.1)'}>
                                                    <Image p={2} w={10} src={consumptionMethod !== undefined ? consumptionIcons[consumptionMethod] : 'https://flowerly-app-storage.s3.amazonaws.com/assets/summaryIcons/eat.png'}/>
                                                </Center>
                                                <Center>
                                                    <Text fontWeight={"bolder"}  fontSize={'.8em'}>
                                                        {consumptionMethod !== undefined ? consumptionMethod : 'format'}
                                                    </Text>
                                                </Center>
                                            </Stack>
                                        </MenuButton>
                                        <MenuList>
                                            { consumptionOptions["options"].map((item, i) => { 
                                                    return (<MenuItem color={"black"} key={i} onClick={(e) => {handleOptionChange(e)}} value={JSON.stringify(item)}>{item.displayName} </MenuItem>)
                                                })
                                            }
                                        </MenuList>
                                    </Menu>
                                </Center>
                                <Center flex={1} >
                                    <Menu>
                                        <MenuButton as={Button} color={"white"} variant={'link'} w={'100%'} >
                                            <Stack>
                                                <Center borderWidth={1.5} borderRadius={12} m={1} backgroundBlendMode={'soft-light'} backgroundColor={'rgba(255, 255, 255, 0.1)'}>
                                                    <Image p={2} w={10} src={intentionFeelings !== undefined ? feelingIcons[intentionFeelings] : 'https://flowerly-app-storage.s3.amazonaws.com/assets/summaryIcons/relax.png'}/>
                                                </Center>
                                                <Center>
                                                    <Text fontWeight={"bolder"}  fontSize={'.8em'}>
                                                        {intentionFeelings !== undefined ? intentionFeelings : 'feeling'}
                                                    </Text>
                                                </Center>
                                            </Stack>
                                        </MenuButton>
                                        <MenuList>
                                            { intentionsOptions["options"].map((item, i) => { 
                                                    return (<MenuItem color={"black"} key={i} onClick={(e) => {handleOptionChange(e)}} value={JSON.stringify(item)}>{item.displayName} </MenuItem>)
                                                })
                                            }
                                        </MenuList>
                                    </Menu>
                                    
                                </Center>
                                <Center flex={1} >
                                    <Menu>
                                        <MenuButton as={Button} color={"white"} variant={'link'} w={'100%'} >
                                            <Stack>
                                                <Center borderWidth={1.5} borderRadius={12} m={1} backgroundBlendMode={'soft-light'} backgroundColor={'rgba(255, 255, 255, 0.1)'}>
                                                    <Image p={2} w={10} src={preferredStrength !== undefined ? strengthIcons[preferredStrength] : 'https://flowerly-app-storage.s3.amazonaws.com/assets/summaryIcons/lightest.png'}/>
                                                </Center>
                                                <Center>
                                                    <Text fontWeight={"bolder"}  fontSize={'.8em'}>
                                                        {preferredStrength !== undefined ? preferredStrength : 'strength'}
                                                    </Text>
                                                </Center>
                                            </Stack>
                                        </MenuButton>
                                        <MenuList>
                                            { strengthOptions["options"].map((item, i) => { 
                                                    return (<MenuItem color={"black"} key={i} onClick={(e) => {handleOptionChange(e)}} value={JSON.stringify(item)}>{item.displayName} </MenuItem>)
                                                })
                                            }
                                        </MenuList>
                                    </Menu>
                                    
                                </Center>
                                <Center flex={1} >
                                    <Menu>
                                        <MenuButton as={Button} color={"white"} variant={'link'} w={'100%'} >
                                            <Stack>
                                                <Center borderWidth={1.5} borderRadius={12} m={1} backgroundBlendMode={'soft-light'} backgroundColor={'rgba(255, 255, 255, 0.1)'}>
                                                    <Image p={2} w={10} src='https://flowerly-app-storage.s3.amazonaws.com/assets/summaryIcons/shortest.png'/>
                                                </Center>
                                                <Center>
                                                    <Text fontWeight={"bolder"}  fontSize={'.8em'}>
                                                        {preferredDuration !== undefined ? preferredDuration : 'strength'}
                                                    </Text>
                                                </Center>
                                            </Stack>
                                        </MenuButton>
                                        <MenuList>
                                            { durationOptions["options"].map((item, i) => { 
                                                    return (<MenuItem color={"black"} key={i} onClick={(e) => {handleOptionChange(e)}} value={JSON.stringify(item)}>{item.displayName} </MenuItem>)
                                                })
                                            }
                                        </MenuList>
                                    </Menu>
                                </Center>
                                          
                                <Center flex={1} >
                                    <Menu  >
                                        <MenuButton as={Button} color={"white"} variant={'link'} w={'100%'} >
                                            <Stack>
                                                <Center borderWidth={1.5} borderRadius={12} m={1} backgroundBlendMode={'soft-light'} backgroundColor={'rgba(255, 255, 255, 0.1)'}>
                                                    <Image p={2} w={10} src={preferredTimeOfDay !== undefined ? timeOfDayIcons[preferredTimeOfDay] : 'https://flowerly-app-storage.s3.amazonaws.com/assets/summaryIcons/night.png'}/>
                                                </Center>
                                                <Center>
                                                    <Text fontWeight={"bolder"}  fontSize={'.8em'}>
                                                        {preferredTimeOfDay !== undefined ? preferredTimeOfDay : 'time'}
                                                    </Text>
                                                </Center>
                                            </Stack>
                                        </MenuButton>
                                        <MenuList>
                                            { timeOfDayOptions["options"].map((item, i) => { 
                                                    return (<MenuItem color={"black"} key={i} onClick={(e) => {handleOptionChange(e)}} value={JSON.stringify(item)}>{item.displayName} </MenuItem>)
                                                })
                                            }
                                        </MenuList>
                                    </Menu>
                                </Center>
                                         
                            </Flex>
                        </Box>
                    </Flex>
                </Box>
                <Box> 
                    { consumptionMethod !== undefined ? (
                        <Center bg={"rgba(0,0,0,.5)"} borderRadius={10} color={"white"} m={3}>
                            <Text fontWeight={"extrabold"} fontSize={'xl'} p={1}>{filteredResults.length} Matching Products</Text>
                        </Center>
                    ) : (
                        <Center bg={"rgba(0,0,0,.5)"} borderRadius={10} color={"white"} m={3}>
                            <Text fontWeight={"extrabold"} fontSize={'xl'} p={1}>Narrow your results ^</Text>
                        </Center>
                    )}
                    {intentionFeelings !== undefined && preferredTimeOfDay !== undefined 
                    ? (
                        <Center bg={"rgba(0,0,0,.5)"} borderRadius={10} color={"white"} m={3}>
                            <Text fontWeight={"extrabold"} fontSize={'xl'} p={1}>Best for {intentionFeelings !== undefined ? intentionFeelings : ''} {preferredTimeOfDay !== undefined ? `${preferredTimeOfDay}s` : ''}</Text>
                        </Center>) 
                    : null} 
                    <Flex  >
                        <Box flex={10} w={'10vw'} h={'72vh'} overflow="auto" >
                                <SimpleGrid columns={screenWidth < 430 ? 2 : 3} spacing={4}>
                                    {filteredResults.map((item, i) => {
                                            // if (item.productAttributes.productImages[0] !== "") {
                                               return i < 300 ? 
                                                <MobileCard
                                                data={item}
                                                productName={item.productName}
                                                brand={item.brand}
                                                units={item.units}
                                                price={item.pricePerUnit}
                                                productId={item.productId}
                                                storeId={item.storeId}
                                                key={'key' + i}
                                                
                                                /> : null
                                            // }
                                        }
                                    )}
                            </SimpleGrid>
                        </Box>
                    </Flex>
                </Box>
              
            </Flex>
        )
    }




    useEffect(() => {
        console.log('is this working - call it bitch')
   
        refreshInventory()
    }, [])

    return (
        <>
            {props.isMobile ? <MobileSummaryView /> : <DesktopSummaryView /> }
            {/* {DesktopSummaryView(inventory)} */}
            
        </>
    )
}
  
export default Summary;


// ageGroup: "18-40"
// deviceWidth: 150
// experienceLevel: "beginner"
// format: "edibles"
// googleCoords: ""
// intentionFeelings: "happy"
// lat: 40.7182872
// long: -73.9959355
// preferredDuration: "shorter"
// preferredStrength: "lighter"
// preferredTimeOfDay: "day"
// visitReason: "recreational"


// ageGroup: "41-65"
// deviceWidth: 668
// experienceLevel: "experienced"
// format: "concentrates"
// googleCoords: ""
// lat: 40.71827
// long: -73.9959328
// medicalIssues: "sleep"
// mostImportant: "strength"
// preferredCannaTerps: ['caryophyllene']
// visitReason: "medical"

// ageGroup: "18-40"
// deviceWidth: 668
// experienceLevel: "experienced"
// format: "tinctures"
// googleCoords: ""
// lat: 40.7182783
// long: -73.9959428
// mostImportant: "strength"
// preferredCannaTerps: ['CBG']
// visitReason: "recreational"



    // let length = "";
    // let strength = "";
    // if (user.preferredDuration === "shortest") {
    //     length = "one to two hours"
    // } else if (user.preferredDuration === "shorter") {
    //     length = "two to three hours"
    // } else if (user.preferredDuration === "longer") {
    //     length = "three to four hours"
    // } else if (user.preferredDuration === "longest") {
    //     length = "four to six hours"
    // }

    // if (user.preferredStrength === "lightest") {
    //     strength = "the lightest"
    // } else if (user.preferredStrength === "lighter") {
    //     strength = "a lighter"
    // } else if (user.preferredStrength === "stronger") {
    //     strength = "a stronger"
    // } else if (user.preferredStrength === "strongest") {
    //     strength = "the strongest"
    // }
    

    // const handleItemClick = (item) => {
    //     console.log("I JUST CLICKED IT", item);
    //     setSelected(item);
    // }
    // const isItemSelected = (id) => !!selected.find((el) => el === id);
    // const handleClick =
    //   (id) =>
    //   ({ getItemById, scrollToItem }) => {
    //     const itemSelected = isItemSelected(id);
  
    //     setSelected((currentSelected) =>
    //       itemSelected
    //         ? currentSelected.filter((el) => el !== id)
    //         : currentSelected.concat(id)
    //     );
    //   };

    
//   const verbalSummaryDesktop = <Center flex={10} fontSize={40} > 
//   <Box>
//   { experienceLevel === "experienced" && <Text color={"white"}>Finding results for <strong>{user.format}</strong> with <strong>{user.preferredCannaTerps}</strong>. {user.visitReason === "medical" && `Providing best results for assisting with ${user.medicalIssues}.`} Prioritizing suggestions by <strong>{user.mostImportant}</strong>.</Text>}
//   { (experienceLevel !== "experienced" && user.consumptionMethod === "drops") && <Text color={"white"} >I'm looking {user.consumptionMethod === "drops" ? "for " : "to "} <strong>{user.consumptionMethod}</strong> {user.consumptionMethod === "drops" ? null : "something" } that will make me feel <strong>{user.intentionFeelings}</strong>, have <strong>{strength}</strong> effect, typically for <strong>{length}</strong> during the <strong>{user.preferredTimeOfDay}</strong>.</Text>}
//   { (experienceLevel !== "experienced" && user.consumptionMethod === "patches") && <Text color={"white"}>I'm looking {user.consumptionMethod === "patches"  ? "for " : "to "} <strong>{user.consumptionMethod}</strong> {user.consumptionMethod === "patches" ? null : "something" } that will make me feel <strong>{user.intentionFeelings}</strong>, have <strong>{strength}</strong> effect, typically for <strong>{length}</strong> during the <strong>{user.preferredTimeOfDay}</strong>.</Text>}
//   { (experienceLevel !== "experienced" && user.consumptionMethod ===  "CBD")  && <Text color={"white"}>I'm looking {user.consumptionMethod ===  "CBD" ? "for " : "to "} <strong>{user.consumptionMethod}</strong> {user.consumptionMethod === "CBD" ? null : "something" } that will make me feel <strong>{user.intentionFeelings}</strong>, have <strong>{strength}</strong> effect, typically for <strong>{length}</strong> during the <strong>{user.preferredTimeOfDay}</strong>.</Text>}
//   { (experienceLevel !== "experienced" && user.consumptionMethod !==  "CBD" && user.consumptionMethod !==  "patches" && user.consumptionMethod !==  "drops")  && <Text color={"white"}>I'm looking to <strong>{user.consumptionMethod}</strong> something that will make me feel <strong>{user.intentionFeelings}</strong>, have <strong>{strength}</strong> effect, typically for <strong>{length}</strong> during the <strong>{user.preferredTimeOfDay}</strong>.</Text>}
//       <Center marginTop={10}>
//           <Button width={props.isMobile ? "10vw" : "20vw"} style={{color: "white"}}_hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} variant={'link'} colorScheme='grey' >edit</Button>
//       </Center>
//   </Box>
// </Center>


//   <Flex >
//                         <Box  p={4}>
//                             <Box>
//                                 <Text fontWeight={"extrabold"} fontSize={"1.4em"}>Best for {user.preferredTimeOfDay}</Text >
//                             </Box>
//                                 <Box borderRadius={8} borderWidth={"medium"} borderColor={"rgba(0,0,0,.5)"} borderStyle={"solid"} h={40}>
//                                     <Flex>
//                                         <Box flex={1}>
//                                             {loaded ? (
//                                                 <Box p={3}>
//                                                     <Image onLoad={() => setLoaded(true)} src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png" />
//                                                 </Box>
//                                             ) : (
//                                                 <Skeleton>
//                                                     <Box p={3}>
//                                                         <Image onLoad={() => setLoaded(true)} src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png" />
//                                                     </Box>
//                                                 </Skeleton>
//                                             )}
                                            
//                                         </Box>
//                                         <Box flex={2}>
//                                             <Text fontWeight={"extrabold"} fontSize={"1.4em"}>Product Name </Text >
//                                             <Text fontWeight={"light"}>Brand Name</Text>
//                                         </Box>
//                                     </Flex>
//                                 </Box>
//                         </Box>
//                         <Box  p={4}>
//                             <Box>
//                                 <Text fontWeight={"extrabold"} fontSize={"1.4em"}>Best for {user.preferredTimeOfDay}</Text >
//                             </Box>
//                                 <Box borderRadius={8} borderWidth={"medium"} borderColor={"rgba(0,0,0,.5)"} borderStyle={"solid"} h={40}>
//                                     <Flex>
//                                         <Box flex={1}>
//                                             {loaded ? (
//                                                 <Box p={3}>
//                                                     <Image onLoad={() => setLoaded(true)} src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png" />
//                                                 </Box>
//                                             ) : (
//                                                 <Skeleton>
//                                                     <Box p={3}>
//                                                         <Image onLoad={() => setLoaded(true)} src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png" />
//                                                     </Box>
//                                                 </Skeleton>
//                                             )}
//                                             <></>
                                            
//                                         </Box>
//                                         <Box flex={2}>
//                                             <Text fontWeight={"extrabold"} fontSize={"1.4em"}>Product Name </Text >
//                                             <Text fontWeight={"light"}>Brand Name</Text>
//                                         </Box>
//                                     </Flex>
//                                 </Box>
//                         </Box>
//                         <Box  p={4}>
//                             <Box>
//                                 <Text fontWeight={"extrabold"} fontSize={"1.4em"}>Best for {user.preferredTimeOfDay}</Text >
//                             </Box>
//                                 <Box borderRadius={8} borderWidth={"medium"} borderColor={"rgba(0,0,0,.5)"} borderStyle={"solid"} h={40}>
//                                     <Flex>
//                                         <Box flex={1}>
//                                             {loaded ? (
//                                                 <Box p={3}>
//                                                     <Image onLoad={() => setLoaded(true)} src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png" />
//                                                 </Box>
//                                             ) : (
//                                                 <Skeleton>
//                                                     <Box p={3}>
//                                                         <Image onLoad={() => setLoaded(true)} src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png" />
//                                                     </Box>
//                                                 </Skeleton>
//                                             )}
                                            
//                                         </Box>
//                                         <Box flex={2}>
//                                             <Text fontWeight={"extrabold"} fontSize={"1.4em"}>Product Name {user.preferredTimeOfDay}</Text >
//                                         </Box>
//                                     </Flex>
//                                 </Box>
//                         </Box>
//                     </Flex>

{/* <Box flex={1} >
                    <Flex >
                    <Box  p={4}>
                            <Box>
                                <Text fontWeight={"extrabold"} fontSize={"1.4em"}>Best for {user.preferredTimeOfDay}</Text >
                            </Box>
                                <Box borderRadius={8} borderWidth={"medium"} borderColor={"rgba(0,0,0,.5)"} borderStyle={"solid"} h={40}>
                                    <Flex>
                                        <Box flex={1}>
                                            {loaded ? (
                                                <Box p={3}>
                                                    <Image onLoad={() => setLoaded(true)} src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png" />
                                                </Box>
                                            ) : (
                                                <Skeleton>
                                                    <Box p={3}>
                                                        <Image onLoad={() => setLoaded(true)} src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png" />
                                                    </Box>
                                                </Skeleton>
                                            )}
                                            
                                        </Box>
                                        <Box flex={2}>
                                            <Text fontWeight={"extrabold"} fontSize={"1.4em"}>Product Name {user.preferredTimeOfDay}</Text >
                                        </Box>
                                    </Flex>
                                </Box>
                        </Box>
                        <Box  p={4}>
                            <Box>
                                <Text fontWeight={"extrabold"} fontSize={"1.4em"}>Best for {user.preferredTimeOfDay}</Text >
                            </Box>
                                <Box borderRadius={8} borderWidth={"medium"} borderColor={"rgba(0,0,0,.5)"} borderStyle={"solid"} h={40}>
                                    <Flex>
                                        <Box flex={1}>
                                            {loaded ? (
                                                <Box p={3}>
                                                    <Image onLoad={() => setLoaded(true)} src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png" />
                                                </Box>
                                            ) : (
                                                <Skeleton>
                                                    <Box p={3}>
                                                        <Image onLoad={() => setLoaded(true)} src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png" />
                                                    </Box>
                                                </Skeleton>
                                            )}
                                            
                                        </Box>
                                        <Box flex={2}>
                                            <Text fontWeight={"extrabold"} fontSize={"1.4em"}>Product Name {user.preferredTimeOfDay}</Text >
                                        </Box>
                                    </Flex>
                                </Box>
                        </Box>
                    </Flex>
                    <Flex >
                    <Box  p={4}>
                            <Box>
                                <Text fontWeight={"extrabold"} fontSize={"1.4em"}>Best for {user.preferredTimeOfDay}</Text >
                            </Box>
                                <Box borderRadius={8} borderWidth={"medium"} borderColor={"rgba(0,0,0,.5)"} borderStyle={"solid"} h={40}>
                                    <Flex>
                                        <Box flex={1}>
                                            {loaded ? (
                                                <Box p={3}>
                                                    <Image onLoad={() => setLoaded(true)} src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png" />
                                                </Box>
                                            ) : (
                                                <Skeleton>
                                                    <Box p={3}>
                                                        <Image onLoad={() => setLoaded(true)} src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png" />
                                                    </Box>
                                                </Skeleton>
                                            )}
                                            
                                        </Box>
                                        <Box flex={2}>
                                            <Text fontWeight={"extrabold"} fontSize={"1.4em"}>Product Name {user.preferredTimeOfDay}</Text >
                                        </Box>
                                    </Flex>
                                </Box>
                        </Box>
                        <Box  p={4}>
                            <Box>
                                <Text fontWeight={"extrabold"} fontSize={"1.4em"}>Best for {user.preferredTimeOfDay}</Text >
                            </Box>
                                <Box borderRadius={8} borderWidth={"medium"} borderColor={"rgba(0,0,0,.5)"} borderStyle={"solid"} h={40}>
                                    <Flex>
                                        <Box flex={1}>
                                            {loaded ? (
                                                <Box p={3}>
                                                    <Image onLoad={() => setLoaded(true)} src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png" />
                                                </Box>
                                            ) : (
                                                <Skeleton>
                                                    <Box p={3}>
                                                        <Image onLoad={() => setLoaded(true)} src="https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png" />
                                                    </Box>
                                                </Skeleton>
                                            )}
                                            
                                        </Box>
                                        <Box flex={2}>
                                            <Text fontWeight={"extrabold"} fontSize={"1.4em"}>Product Name {user.preferredTimeOfDay}</Text >
                                        </Box>
                                    </Flex>
                                </Box>
                        </Box>
                    </Flex>
                    </Box> */}