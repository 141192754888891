import { Link } from "react-router-dom";
import { Box, Flex, Progress, Heading, Spacer, Button, Container, Center, SimpleGrid, Text, useCheckbox, useCheckboxGroup, chakra } from '@chakra-ui/react';

const CustomCheckbox = (props) => {
  const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } =
    useCheckbox(props)

  return (
    <chakra.label
      display='flex'
      flexDirection='row'
      alignItems='center'
      gridColumnGap={2}
      maxW='36'
      bg='green.50'
      border='1px solid'
      borderColor='green.500'
      rounded='lg'
      px={3}
      py={1}
      cursor='pointer'
      {...htmlProps}
    >
      <input {...getInputProps()} hidden />
      <Flex
        alignItems='center'
        justifyContent='center'
        border='2px solid'
        borderColor='green.500'
        w={4}
        h={4}
        {...getCheckboxProps()}
      >
        {state.isChecked && <Box w={2} h={2} bg='green.500' />}
      </Flex>
      <Text style={{color: "black"}} {...getLabelProps()}>{props.displayname}</Text>
    </chakra.label>
  )
}

const UniversalSelectMultiple = (props) => {
    const { firstLineText, secondLineText, answers, extraColumns, extraSpacing, detailText, progressValue, route } = props; 
    const { value, getCheckboxProps } = useCheckboxGroup({defaultValue: []})

    console.log('UniversalSelectMultiple state', value)
    return (
      <>
        <div className={'animate__animated animate__bounceInLeft'}>
        <Center paddingTop={props.isMobile ? 8 : 6}>
              <Progress w={props.isMobile ? '200px' : '40%' } colorScheme={'green'} size={props.isMobile && 'lg'} height={props.isMobile ? "1rem" : "32px"} value={progressValue} borderRadius={20} bg={"rgba(0, 0, 0, 0.4);"}/>
            </Center>
          <Flex paddingTop={props.isMobile ? 4 : 10} >
            <Box minW={props.isMobile ? 3 : 100} >
            </Box>
            <Box h='25vh' flex={1} bg={"rgba(0, 0, 0, 0.4);"} maxW='sm' color="white" style={{borderRadius: '2rem'}}>
              <Container style={{paddingTop: props.isMobile ? '60px' : '7vh'}}>
                <Heading fontSize='4xl'>{firstLineText}</Heading>
                <Text fontSize='2xl'>{secondLineText}</Text>
              </Container>
            </Box>
            <Box minW={3}>
            </Box>
          </Flex>
  
          <Flex paddingTop={20} >
            <Box >
            </Box>
            {props.isMobile ? null : <Spacer />}
            <Box h='25vh' flex={1} minW='3' color="white" style={{borderRadius: '2rem', paddingLeft: '10px'}}>
              <Center style={{paddingBottom: "10px"}}>
                {detailText}
              </Center>
              <SimpleGrid columns={extraColumns || 2} spacing={extraSpacing || 10}>
              {answers.map((item, i) => {
                const saveDataEntryValue = item.saveDataEntryValue
                return (               
                  <CustomCheckbox key={item.displayName} displayname={item.displayName} {...getCheckboxProps({value:saveDataEntryValue})} />

                )
              })}
              </SimpleGrid>
            </Box>
            <Box minW={props.isMobile ? 3 : 100}>
            </Box>
          </Flex>
          <Center style={{paddingTop: "20px"}}>
              <Button variant={'ghost'} colorScheme='whiteAlpha' >More Options +</Button>
          </Center>
          <Center style={{paddingTop: "20px"}}>
            <Link to={route}>
                <Button width={props.isMobile ? "70vw" : "30vw"}style={{color: "white"}}_hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} variant={'outline'} colorScheme='grey' isDisabled={value === []} onClick={() => {props.handleAddUserData(value, answers[0]["saveDataEntryName"])}}>Continue</Button>
            </Link>
          </Center>
        </div>
      </> 
    )
  }

  export default UniversalSelectMultiple;