import { useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import { Pie, ResponsivePie } from '@nivo/pie'
import { useLocation } from 'react-router-dom';
import { findProduct } from './../utilities/flowerlyApi'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { MdOutlineLocationSearching, MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { RiErrorWarningLine } from 'react-icons/ri';
import UniversalQuestion from './questions/UniversalQuestion';
import { Box, Flex, Heading, Spacer, Button, Container, Center, Image, Text, useToast, Divider, CircularProgress, CircularProgressLabel, Select, Input, HStack, InputGroup, InputLeftAddon, InputLeftElement, Stack, Progress, Stat, StatLabel, StatNumber, StatHelpText, IconButton} from '@chakra-ui/react';
import { formatOptions, consumptionOptions, ageOptions, intentionsOptions, medicalOptions, mostImportantOptions, technicalOptions, customerType, experienceType, strengthOptions, durationOptions, timeOfDayOptions } from '../utilities/menuOptions'

// ageGroup: "18-40"
// deviceWidth: 150
// experienceLevel: "beginner"
// format: "edibles"
// googleCoords: ""
// intentionFeelings: "happy"
// lat: 40.7182872
// long: -73.9959355
// preferredDuration: "shorter"
// preferredStrength: "lighter"
// preferredTimeOfDay: "day"
// visitReason: "recreational"

// ageGroup: "41-65"
// deviceWidth: 668
// experienceLevel: "experienced"
// format: "concentrates"
// googleCoords: ""
// lat: 40.71827
// long: -73.9959328
// medicalIssues: "sleep"
// mostImportant: "strength"
// preferredCannaTerps: ['caryophyllene']
// visitReason: "medical"

// ageGroup: "18-40"
// deviceWidth: 668
// experienceLevel: "experienced"
// format: "tinctures"
// googleCoords: ""
// lat: 40.7182783
// long: -73.9959428
// mostImportant: "strength"
// preferredCannaTerps: ['CBG']
// visitReason: "recreational"

// brand: "nuEra"
// cannabinoids: {THCV: '<0.10%', CBDA: '<0.10%', CBD: '<0.10%', THCA: '27.96%', CBGA: '1.03%', …}
// category: "flower"
// createdAt: 1643930734015
// pricePerUnit: "45.00"
// productAttributes: {description: 'LSD | Original Skunk #1 x Afghan Indica | LSD is k…e for the first time at dispensaries in Illinois.', aroma: Array(4)}
// productId: "95de5c00-8548"
// productName: "LSDDDII"
// productType: "popcorn"
// rating: {level: 4, totalRatings: 12}
// sale: false
// storeId: "testStoreId"
// strainType: "indica"
// terpenes: {Total Terpenes: '3.7%', Limonene: '0.80%', B-Myrcene: '0.85%', B-Caryophyllene: '0.64%', A-Humulene: '0.33%'}
// units: "7G"
// updatedAt: 1643930734015

const ProductDetail = (props) => {
        const [productData, setProductData] = useState({})
        
        const { brand='', cannabinoids = '', category = '', pricePerUnit = '', productAttributes = {aroma: []}, productId = '', productName = '', productType = '', rating = '', sale = '', storeId = '', strainType = '', terpenes = '', units = ''} = productData;
       
        // console.log("ProductDetail:", props.selectedProduct)
        const roundedPrice = Math.round(pricePerUnit)
        const description = productData === {} ? "" : productAttributes["description"];

        const handleProductData = newProductData => {
            setProductData(newProductData)
        }

        const RefreshProductData = async () => {
            const pathname = window.location.pathname
            const pathParams = pathname.split('/')
            const pathStoreId = pathParams[1];
            const pathProductId = pathParams[2];

            const newProductData = await findProduct(pathProductId, pathStoreId);
            return newProductData;
     
        }

        useEffect(() => {
            let isMounted = true;               // note mutable flag
            RefreshProductData().then(data => {
              if (isMounted) handleProductData(data);    // add conditional check
            })
            return () => { isMounted = false }; 
        }, [])


        const MatchDefault = () => {
            return (
                <Box bg={"rgba(0,0,0,.5)"} borderRadius={14} padding={3} marginTop={10}>
                    <Flex>
                        <Box flex={1}>
                            <Image src="https://flowerly-app-storage.s3.amazonaws.com/assets/summaryIcons/match.png" />
                        </Box>
                        <Box flex={3}>
                            <Text>
                            {productName} is perfectly suited for crushing work and working out. Great choice!
                            </Text>
                        </Box>
                    </Flex>
                    <Flex flexDirection={"column"} >
                        <Box p={3}>
                            <Flex flexDirection={"column"}>
                                <Flex paddingBottom={3}>
                                    <Box>
                                        <Text>{strainType} • {productType} {category} • {units}</Text>
                                    </Box>
                                    <Spacer />
                                    <Box>
                                        <Text>100%</Text>
                                    </Box>
                                </Flex>
                                <Progress value={100} size='xs' bgGradient='linear(to-l, #7928CA, #FF0080)' colorScheme='green'/>
                            </Flex>
                        </Box>
                        <Box p={3}>
                            <Flex flexDirection={"column"}>
                                <Flex paddingBottom={3}>
                                    <Box>
                                        <Text>creative • focus • uplifting</Text>
                                    </Box>
                                    <Spacer />
                                    <Box>
                                        <Text>95%</Text>
                                    </Box>
                                </Flex>
                                <Progress value={95} size='xs' bgGradient='linear(to-l, #7928CA, #FF0080)' colorScheme='green'/>
                                
                            </Flex>
                        </Box>
                        <Box p={3}>
                            <Flex flexDirection={"column"}>
                                <Flex paddingBottom={3}>
                                    <Box>
                                    <Text>
                                        { productData !== {} && productAttributes["aroma"].map((smell, i) => {
                                                if (i === 0) {
                                                    return `${smell} `
                                                } else {
                                                    return `• ${smell} `
                                                }
                                            })
                                        }
                                    </Text>
                                    </Box>
                                    <Spacer />
                                    <Box>
                                        <Text>90%</Text>
                                    </Box>
                                </Flex>
                                
                                <Progress value={90} size='xs' bgGradient='linear(to-l, #7928CA, #FF0080)' colorScheme='green'/>
                            </Flex>
                        </Box>
                    </Flex>
                </Box>
            )
        }

        const TerpenesDefault = () => {
            const getValues = (key, value) => {
                let tempKey = Math.round(Math.random() * 100000)

                return <Text key={tempKey} >{value}: {key}</Text>
            }
            const values = _.map(terpenes, getValues)
            let AHumuleneTemp = ''
            let LimoneneTemp = ''
            let BMyrceneTemp = ''
            let BCaryophylleneTemp = ''
            let TOPTemp = ''
          
            const findAHumulene = (value, key) => {
                // console.log("THIS", key, value)
                if (key === "A-Humulene") {
                    AHumuleneTemp = value
                   return <CircularProgress value={Number(value.slice(0, -1))* 10} color='green.400'>
                                <CircularProgressLabel fontSize={'x-small'}>{Number(value.slice(0, -1))}%</CircularProgressLabel>
                            </CircularProgress>
                } 
                return
            }
            const findLimonene = (value, key) => {
               if (key === "Limonene") {
                    LimoneneTemp = value
                    // console.log("right here dude", Number(value.slice(0, -1))* 10) 
                    return <CircularProgress value={Number(value.slice(0, -1))* 10} color='green.400'>
                                <CircularProgressLabel fontSize={'x-small'}>{Number(value.slice(0, -1))}%</CircularProgressLabel>
                            </CircularProgress>
                }
                return 
            }

            const findBMyrcene = (value, key) => {
                if (key === "B-Myrcene") {
                    BMyrceneTemp = value
                    return <CircularProgress value={Number(value.slice(0, -1))* 10} color='green.400'>
                                <CircularProgressLabel fontSize={'x-small'}>{Number(value.slice(0, -1))}%</CircularProgressLabel>
                            </CircularProgress>
                 }  
                 return 
             }

             const findBCaryophyllene = (value, key) => {
                if (key === "B-Caryophyllene") {
                    BCaryophylleneTemp = value
                    return <CircularProgress value={Number(value.slice(0, -1))* 10} color='green.400'>
                                <CircularProgressLabel fontSize={'x-small'}>{Number(value.slice(0, -1))}%</CircularProgressLabel>
                            </CircularProgress>
                 }  
                 return 
             }

             const findTOP = (value, key) => {
                if (key === "Total Terpene") {
                    TOPTemp = value
                     return <CircularProgress value={Number(value.slice(0, -1))* 10} color='green.400' >
                                <CircularProgressLabel fontSize={'x-small'}>{Number(value.slice(0, -1))}%</CircularProgressLabel>
                            </CircularProgress>
                 }  
                 return 
             }

            const AHumulene =  _.map(terpenes, findAHumulene)
            const Limonene =  _.map(terpenes, findLimonene)
            const BMyrcene =  _.map(terpenes, findBMyrcene)
            const BCaryophyllene =  _.map(terpenes, findBCaryophyllene)
            const TOP =  _.map(terpenes, findTOP)


            return (
                <Box>
                    <Text fontSize={'2xl'} fontWeight={'bold'}>Terpenes</Text>
                    <Box bg={"rgba(0,0,0,.5)"} borderRadius={14} padding={3} >
                        {/* {values} */}
                    
                            <Flex fontSize={'small'} fontWeight={'bold'} marginBottom={2}>
                                <Box flex={1} >
                                <Center>
                                    <Text>B-Caryophyllene</Text>
                                </Center>
                                </Box>
                                <Box flex={1} >
                                    <Center>
                                        <Text>Limonene</Text>
                                    </Center>
                                </Box>
                                <Box flex={1} >
                                    <Center>
                                        <Text>B-Myrcene</Text>
                                    </Center>
                                </Box>
                            </Flex>
                            <Flex fontWeight={'bold'} fontSize={'xl'}>
                                <Box flex={1} p={0}>
                                <Center>
                                    {BCaryophyllene}
                                </Center>
                                </Box>
                                <Box flex={1} p={0}>
                                    <Center>
                                    {Limonene}
                                    </Center>
                                </Box>
                                <Box flex={1} p={0}>
                                    <Center>
                                    <Text>{BMyrcene}</Text>
                                    </Center>
                                </Box>
                            </Flex>
                            <Flex fontSize={'small'}>
                                <Box flex={1} p={3} marginBottom={-3} >
                                    <Flex flexDirection={"column"}>
                                        <Center>
                                            <Text>pain relief</Text>
                                        </Center>
                                        <Center>
                                            <Text>anti-anxiety</Text>
                                        </Center>
                                    </Flex>
                                </Box>
                                <Box flex={1} p={3} marginBottom={-3}>
                                    <Flex flexDirection={"column"}>
                                        <Center>
                                            <Text>immunostimulant</Text>
                                        </Center>
                                        <Center>
                                            <Text>anti-cancer</Text>
                                        </Center>
                                    </Flex>
                                </Box>
                                <Box flex={1} p={3} marginBottom={-3}>
                                    <Flex flexDirection={"column"}>
                                        <Center>
                                            <Text>anti-infammatory</Text>
                                        </Center>
                                        <Center>
                                            <Text>mild sedative</Text>
                                        </Center>
                                    </Flex>
                                    
                                </Box>
                            </Flex>
                    </Box>
                    
                </Box>
            )
        }

        const CannabinoidsDefault = () => {
            let percentageThc = 0
            let percentageCbd = 0
            let percentageCbg = 0
          
            const findTHC = (value, key) => {
                // console.log("THIS", key, value)
                if (key === "Total Cannabinoids") {
                    percentageThc = value
                    return <Text>{value}</Text>
                } 
                return
            }
            const findCBD = (value, key) => {
               if (key === "CBD") {
                    percentageCbd = value
                    return <Text>{value}</Text>
                }
                return 
            }

            const findCBG = (value, key) => {
                if (key === "CBGA") {
                     percentageCbg = value
                     return <CircularProgress value={Math.round(parseInt(value)*10)} color='green.400'>
                                <CircularProgressLabel>{Math.round(parseInt(value))}%</CircularProgressLabel>
                            </CircularProgress>
                 }  
                 return 
             }

            // const findRatio = () => {
            //     if (ratioCbd !== 0) {
            //         if (ratioThc > ratioCbd) {
            //             return <StatNumber>High THC</StatNumber>
            //         } else if (ratioThc > ratioCbd) {
            //             return <StatNumber>High CBD</StatNumber>
            //         }
            //     }
            // }
            const THC =  _.map(cannabinoids, findTHC)
            const CBD =  _.map(cannabinoids, findCBD)
            const CBG =  _.map(cannabinoids, findCBG)

            const getValues = (key, value) => {
                let tempKey = Math.round(Math.random() * 100000)
                return <Text key={tempKey}>{value}: {key}</Text>
            }
            const values = _.map(cannabinoids, getValues)

            return (
                <Box>
                    <Text fontSize={'2xl'} fontWeight={'bold'}>Cannabinoids</Text>
                    <Box bg={"rgba(0,0,0,.5)"} borderRadius={14} padding={3} >
                        {/* {values} */}
                    
                        <Flex>
                                <Box flex={1} p={3}>
                                <Center>
                                    <Text>CBG</Text>
                                </Center>
                                </Box>
                                <Box flex={1} p={3}>
                                    <Center>
                                        <Text>CBD</Text>
                                    </Center>
                                </Box>
                                <Box flex={1} p={3}>
                                    <Center>
                                        <Text>THCV</Text>
                                    </Center>
                                </Box>
                            </Flex>
                            <Flex fontWeight={'bold'} fontSize={'xl'}>
                                <Box flex={1} p={0}>
                                <Center>
                                    {CBG}
                                </Center>
                                </Box>
                                <Box flex={1} p={0}>
                                    <Center>
                                    {CBG}
                                    </Center>
                                </Box>
                                <Box flex={1} p={0}>
                                    <Center>
                                    <Text>{CBG}</Text>
                                    </Center>
                                </Box>
                            </Flex>
                            <Flex>
                                <Box flex={1} p={3} marginBottom={-3}>
                                <Center>
                                    <Text>anti-anxiety</Text>
                                </Center>
                                </Box>
                                <Box flex={1} p={3} marginBottom={-3}>
                                    <Center>
                                        <Text>pain</Text>
                                    </Center>
                                </Box>
                                <Box flex={1} p={3} marginBottom={-3}>
                                    <Center>
                                        <Text>energy</Text>
                                    </Center>
                                </Box>
                            </Flex>
                            <Center p={6} marginBottom={-6}>
                                <Divider />
                            </Center>
                            <Box  p={6} marginBottom={-6}>
                            <Progress value={95} size='sm' bg={'DarkGrey'} colorScheme='green' borderRadius={14} />
                            </Box>
                            
                            <Flex>
                                <Box flex={1} p={3} marginBottom={-6}>
                                <Center>
                                    <Text>THC</Text>
                                </Center>
                                </Box>
                                <Box flex={1} p={3} marginBottom={-6}>
                                    <Center>
                                        <Text>CBD</Text>
                                    </Center>
                                </Box>
                                <Box flex={1} p={3} marginBottom={-6}>
                                    <Center>
                                        <Text>Ratio</Text>
                                    </Center>
                                </Box>
                            </Flex>
                            <Flex fontWeight={'bold'} fontSize={'xl'}>
                                <Box flex={1} p={3}>
                                <Center>
                                    {THC}
                                </Center>
                                </Box>
                                <Box flex={1} p={3}>
                                    <Center>
                                    {CBD}
                                    </Center>
                                </Box>
                                <Box flex={1} p={3}>
                                    <Center>
                                    <Text>High THC</Text>
                                    </Center>
                                </Box>
                            </Flex>
                    </Box>
                    
                </Box>
            )
        }

        const DescriptionDefault = () => {
            return (
                <Box>
                    <Text fontSize={'2xl'} fontWeight={'bold'}>Description</Text>
                    <Box bg={"rgba(0,0,0,.5)"} borderRadius={14} padding={3} >
                        <Text>{description}</Text>
                    </Box>
                </Box>
            )
        }

        const BeginnerPotencyWarning = () => {
            let ratioThc = 0
            let ratioCbd = 0
          
            const findTHC = (value, key) => {
                // console.log("THIS", key, value)
                if (key === "Total Cannabinoids") {
                    ratioThc = value
                    return <Text>{value}</Text>
                } 
                return
            }
            const findCBD = (value, key) => {
               if (key === "CBD") {
                    ratioCbd = value
                    return <Text>{value}</Text>
                }
                return 
            }

            // const findRatio = () => {
            //     if (ratioCbd !== 0) {
            //         if (ratioThc > ratioCbd) {
            //             return <StatNumber>High THC</StatNumber>
            //         } else if (ratioThc > ratioCbd) {
            //             return <StatNumber>High CBD</StatNumber>
            //         }
            //     }
            // }
            const THC =  _.map(cannabinoids, findTHC)
            const CBD =  _.map(cannabinoids, findCBD)
            // const ratio = findRatio()

            return (
                <Box >
                    {props.isMobile && <Text fontSize={'2xl'} fontWeight={'bold'}>First Time Warning</Text>}
                    <Box bg={"rgba(0,0,0,.5)"} borderRadius={14}  >
                        <Flex>
                            <Center flex={1}>
                                <RiErrorWarningLine style={{width: "100%", height: "100%", padding: "16px"}} />
                            </Center>
                            <Center flex={4} p={3}>
                                <Text fontWeight={'bold'}>
                                    High THC Content: Not Recommended for First Timers
                                </Text>
                            </Center>
                            
                        </Flex>
                    </Box>
                    <Box bg={"rgba(0,0,0,.5)"} borderRadius={14} marginTop={4} >
                      
                            <Box  p={6} marginBottom={-9}>
                            <Progress value={95} size='sm' bg={'DarkGrey'} colorScheme='green' borderRadius={14} />
                            </Box>
                            
                            <Center p={6} marginBottom={-9}>
                                <Text fontWeight={'bold'}>
                                    High THC Strains are best for celebral effects but can sometimes make begginers feel anxious and paranoid.
                                </Text>
                            </Center>
                            <Center p={6} marginBottom={-6}>
                                <Divider />
                            </Center>
                            <Flex>
                                <Box flex={1} p={3} marginBottom={-6}>
                                <Center>
                                    <Text>THC</Text>
                                </Center>
                                </Box>
                                <Box flex={1} p={3} marginBottom={-6}>
                                    <Center>
                                        <Text>CBD</Text>
                                    </Center>
                                </Box>
                                <Box flex={1} p={3} marginBottom={-6}>
                                    <Center>
                                        <Text>Ratio</Text>
                                    </Center>
                                </Box>
                            </Flex>
                            <Flex fontWeight={'bold'} fontSize={'xl'}>
                                <Box flex={1} p={3}>
                                <Center>
                                    {THC}
                                </Center>
                                </Box>
                                <Box flex={1} p={3}>
                                    <Center>
                                    {CBD}
                                    </Center>
                                </Box>
                                <Box flex={1} p={3}>
                                    <Center>
                                    <Text>High THC</Text>
                                    </Center>
                                </Box>
                            </Flex>
                            
                           
                       
                    </Box>
                </Box>
            )
        }

        const TerpeneIdealTaste = () => {
            return (
                <Box>
                    <Text fontSize={'2xl'} fontWeight={'bold'}>Terpene Ideal Taste</Text>
                    <Box bg={"rgba(0,0,0,.5)"} borderRadius={14} padding={3} >
                        <Flex marginBottom={-4}>
                            <Center flex={1}>
                                <Flex flexDir={'column'}>
                                    <Text fontWeight={'bold'} fontSize={'sm'} p={1}>High-Temp</Text>
                                    <Progress value={90} size='sm' bg={'DarkGrey'} colorScheme='red' borderRadius={14} />
                                    <Center >
                                        <Text fontWeight={'bold'} fontSize={'sm'} p={1} marginBottom={-2} >390ºF</Text>
                                    </Center>
                                    <Center>
                                        <Text fontSize={'xs'}>199ºC</Text>
                                    </Center>
                                </Flex>
                            </Center>
                            <Center flex={1}>
                                <Flex flexDir={'column'}>
                                    <Text fontWeight={'bold'} fontSize={'sm'} p={1}>Mid-Temp</Text>
                                    <Progress value={70} size='sm' bg={'DarkGrey'} colorScheme='orange' borderRadius={14} />
                                    <Center>
                                        <Text fontWeight={'bold'} fontSize={'sm'} p={1} marginBottom={-2} >348.8ºF</Text>
                                    </Center>
                                    <Center>
                                        <Text fontSize={'xs'}>176ºC</Text>
                                    </Center>
                                </Flex>
                            </Center>
                            <Center flex={1}>
                                <Flex flexDir={'column'}>
                                    <Text fontWeight={'bold'} fontSize={'sm'} p={1}>Low-Temp</Text>
                                    <Progress value={55} size='sm' bg={'DarkGrey'} colorScheme='yellow' borderRadius={14} />
                                    <Center>
                                        <Text fontWeight={'bold'} fontSize={'sm'} p={1} marginBottom={-2} >331ºF</Text>
                                    </Center>
                                    <Center>
                                        <Text fontSize={'xs'}>166ºC</Text>
                                    </Center>
                                </Flex>
                            </Center>
                        </Flex>
                        <Flex>
                            <Center flex={1}>
                                <Image p={8} marginBottom={-6} src="https://flowerly-app-storage.s3.amazonaws.com/assets/componentIcons/terpenePlants/black_pepper.webp" />
                            </Center>
                            <Center flex={1}>
                                <Image p={8} marginBottom={-6} src="https://flowerly-app-storage.s3.amazonaws.com/assets/componentIcons/terpenePlants/lemon.webp" />
                            </Center>
                            <Center flex={1}>
                                <Image p={8} marginBottom={-6} src="https://flowerly-app-storage.s3.amazonaws.com/assets/componentIcons/terpenePlants/mango.webp" />
                            </Center>
                        </Flex>
                        <Flex>
                            <Center flex={1}>
                                <Text fontWeight={'bold'} fontSize={'sm'} p={1}>Black Pepper</Text>
                            </Center>
                            <Center flex={1}>
                                <Text fontWeight={'bold'} fontSize={'sm'} p={1}>Lemon</Text>
                            </Center>
                            <Center flex={1}>
                                <Text fontWeight={'bold'} fontSize={'sm'} p={1}>Mango</Text>
                            </Center>
                        </Flex>
                    </Box>
                </Box>
            )
        }

        const FullSpectrum = () => {
            const pieData = [
                {
                  "id": "THC",
                  "label": "THC",
                  "value": 27,
                  "color": "hsl(193, 70%, 50%)"
                },
                {
                  "id": "CBD",
                  "label": "CBD",
                  "value": .4,
                  "color": "hsl(213, 70%, 50%)"
                },
                {
                  "id": "CBN",
                  "label": "CBN",
                  "value": 4,
                  "color": "hsl(2, 70%, 50%)"
                },
                {
                  "id": "CBC",
                  "label": "CBC",
                  "value": 10,
                  "color": "hsl(108, 70%, 50%)"
                },
                {
                  "id": "THCA",
                  "label": "THCA",
                  "value": 20,
                  "color": "hsl(168, 70%, 50%)"
                }
              ]

            const MyResponsivePie = (pieData) => {
                const commonProperties = {
                        width: 500,
                        height: 300,
                        margin: { top: 60, right: 120, bottom: 80, left: 120 },
                        // data: generateProgrammingLanguageStats(true, 9).map(({ label, ...d }) => ({
                        //     id: label,
                        //     ...d,
                        // })),
                        data: pieData,
                        animate: true,
                        activeOuterRadiusOffset: 8,
                    }

                return (
                        <Pie
                            {...commonProperties}
                            innerRadius={0.6}
                            padAngle={0.5}
                            cornerRadius={5}
                            arcLinkLabelsColor={{
                                from: 'color',
                            }}
                            arcLinkLabelsThickness={3}
                            arcLinkLabelsTextColor={{
                                from: 'color',
                                modifiers: [['darker', 1.2]],
                            }}
                        />);
            }
             

            return (
                <Box>
                    <Text fontSize={'2xl'} fontWeight={'bold'}>Full Spectrum Breakdown</Text>
                    <Box bg={"rgba(0,0,0,.5)"} borderRadius={14} padding={3} >
                        <Flex fontSize={'xs'} flexDir={props.isMobile && 'column'}>
                            
                            <Box h={280} w={280} marginLeft={-20} marginTop={-10} marginBottom={-4}>
                                {MyResponsivePie(pieData)}
                            </Box>
                            <Flex>
                                <Center paddingLeft={props.isMobile === false && "8em"} flex={props.isMobile && 1}>
                                    <Flex flexDir={'column'}>
                                        <Flex flex={1}>
                                            <Center bgColor={"pink.500"} borderRadius={20} p={1} pos={'absolute'} paddingLeft={2} paddingRight={2}>0.8%</Center>
                                            <Center bgColor={"gray.500"} p={1} borderRadius={20} paddingLeft={16} paddingRight={6} fontWeight={'bold'}>THC</Center>
                                        </Flex>
                                        <Flex flex={1} paddingTop={2}>
                                            <Center bgColor={"purple.500"} borderRadius={20} p={1} pos={'absolute'} paddingLeft={2} paddingRight={2}>23%</Center>
                                            <Center bgColor={"gray.500"} p={1} borderRadius={20} paddingLeft={16} paddingRight={4} fontWeight={'bold'}>THCA</Center>
                                        </Flex>
                                        <Flex flex={1} paddingTop={2}>
                                            <Center bgColor={"blue.500"} borderRadius={20} p={1} pos={'absolute'} paddingLeft={2} paddingRight={2}>3.1%</Center>
                                            <Center bgColor={"gray.500"} p={1} borderRadius={20} paddingLeft={16} paddingRight={6} fontWeight={'bold'}>CBD{`   `}</Center>
                                        </Flex>
                                        <Flex flex={1} paddingTop={2}>
                                            <Center bgColor={"DarkBlue"} borderRadius={20} p={1} pos={'absolute'} paddingLeft={2} paddingRight={2}>6.8%</Center>
                                            <Center bgColor={"gray.500"} p={1} borderRadius={20} paddingLeft={16} paddingRight={4} fontWeight={'bold'}>CBDA</Center>
                                        </Flex>
                                    </Flex>
                                </Center>
                                <Center paddingLeft={8} flex={props.isMobile && 1}>
                                    <Flex flexDir={'column'}>
                                        <Flex flex={1}>
                                            <Center bgColor={"pink.500"} borderRadius={20} p={1} pos={'absolute'} paddingLeft={2} paddingRight={2}>5.0%</Center>
                                            <Center bgColor={"gray.500"} p={1} borderRadius={20} paddingLeft={16} paddingRight={8} fontWeight={'bold'}>CBG</Center>
                                        </Flex>
                                        <Flex flex={1} paddingTop={2}>
                                            <Center bgColor={"purple.500"} borderRadius={20} p={1} pos={'absolute'} paddingLeft={2} paddingRight={2}>3.0%</Center>
                                            <Center bgColor={"gray.500"} p={1} borderRadius={20} paddingLeft={16} paddingRight={6} fontWeight={'bold'}>THCV</Center>
                                        </Flex>
                                        <Flex flex={1} paddingTop={2}>
                                            <Center bgColor={"blue.500"} borderRadius={20} p={1} pos={'absolute'} paddingLeft={2} paddingRight={2}>1.0%</Center>
                                            <Center bgColor={"gray.500"} p={1} borderRadius={20} paddingLeft={16} paddingRight={8} fontWeight={'bold'}>CBC</Center>
                                        </Flex>
                                        <Flex flex={1} paddingTop={2}>
                                            <Center bgColor={"DarkBlue"} borderRadius={20} p={1} pos={'absolute'} paddingLeft={2} paddingRight={2} >25.84%</Center>
                                            <Center bgColor={"gray.500"} p={1} borderRadius={20} paddingLeft={16} paddingRight={4} fontWeight={'bold'}>TOTAL</Center>
                                        </Flex>
                                    </Flex>
                                </Center>
                            </Flex>
                        </Flex>
                    </Box>
                </Box>
            )
        }

        const DosageUsage = () => {
            return (
                <Box>
                    <Text fontSize={'2xl'} fontWeight={'bold'} >Dosage and Usage</Text>
                    <Box bg={"rgba(0,0,0,.5)"} borderRadius={14} padding={3} >
                        <Flex>
                            <Box flex={1}>
                                <Center>
                                    <Flex flexDir={'column'}>
                                        <Center>
                                            <Text fontSize={'sm'} fontWeight={'bold'}>single dose</Text>
                                        </Center>
                                        <Box>
                                            <Flex>
                                                <Box flex={1}>
                                                    <Image p={2} src="https://flowerly-app-storage.s3.amazonaws.com/assets/componentIcons/dosageUsage/timer_w.png" />
                                                </Box>
                                                <Center flex={1}>
                                                    <Flex flexDir={'column'}>
                                                        <Center>
                                                            <Text fontSize={'xl'} fontWeight={"bold"}>3 sec</Text>
                                                        </Center>
                                                        <Text>inhalation</Text>
                                                    </Flex>
                                                </Center>
                                            </Flex>
                                        </Box>
                                    </Flex>
                                </Center>
                            </Box>
                            <Box paddingLeft={6}>
                                <Divider orientation='vertical' />
                            </Box>
                            <Box flex={3} >
                                
                                <Flex flexDir={'column'}>
                                    <Center>
                                        <Text fontSize={'sm'} fontWeight={'bold'}> consumption methods</Text>
                                    </Center>
                                    <Box p={1}/>
                                    <Flex fontSize={'sm'}>
                                        <Box flex={1}>
                                            <Center>
                                                <Image src="https://flowerly-app-storage.s3.amazonaws.com/assets/componentIcons/dosageUsage/joint_w.png" w={10}/>
                                            </Center>
                                            <Center>
                                                <Text>joint</Text>
                                            </Center>
                                        </Box>
                                        <Box flex={1}>
                                            <Center>
                                                <Image src="https://flowerly-app-storage.s3.amazonaws.com/assets/componentIcons/dosageUsage/blunt_w.png" w={10}/>
                                            </Center>
                                            <Center>
                                                <Text>blunt</Text>
                                            </Center>
                                        </Box>
                                        <Box flex={1}>
                                            <Center>
                                                <Image src="https://flowerly-app-storage.s3.amazonaws.com/assets/componentIcons/dosageUsage/bong_w.png" w={10}/>
                                            </Center>
                                            <Center>
                                                <Text>bong</Text>
                                            </Center>
                                        </Box>
                                        <Box flex={1}>
                                            <Center>
                                                <Image src="https://flowerly-app-storage.s3.amazonaws.com/assets/componentIcons/dosageUsage/pipe_w.png" w={10}/>
                                            </Center>
                                            <Center>
                                                <Text>pipe</Text>
                                            </Center>
                                        </Box>
                                    </Flex>
                                </Flex>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
            )
        }

        const Combinations = () => {
            return (
                <Box>
                    <Text fontSize={'2xl'} fontWeight={'bold'}>Combinations [beta]</Text>
                    <Box bg={"rgba(0,0,0,.5)"} borderRadius={14} padding={3} >
                        <Flex flexDir={props.isMobile && 'column'}>
                            <Box flex={1}>
                                <Flex flexDir={'column'}>
                                    <Box textAlign={props.isMobile === false && 'center'} paddingLeft={props.isMobile && 6}>
                                        <Text fontWeight={'bold'}>
                                            super boost
                                        </Text>
                                    </Box>
                                    <Box bgGradient='linear(to-l, blue.300, green.500)' borderRadius={10}  p={1} marginLeft={6} marginRight={6} marginTop={2}>
                                        <Flex>
                                            <Center flex={1} paddingLeft={2}>
                                                <Flex fontSize={'sm'} fontWeight={'bold'} flexDir={'column'}>
                                                    <Center>
                                                        <Text>sativa/hybrid</Text>
                                                    </Center>
                                                    <Center>
                                                        <Text>2.5mg edible</Text>
                                                    </Center>
                                                </Flex>
                                            </Center>
                                            <Center flex={1} fontWeight={'black'} fontSize={'2xl'}>
                                                +
                                            </Center>
                                            <Center flex={1}>
                                                <Flex fontSize={'sm'} fontWeight={'bold'} flexDir={'column'}>
                                                    <Center>
                                                        <Text>THCV</Text>
                                                    </Center>
                                                    <Center>
                                                        <Text>edible</Text>
                                                    </Center>
                                                </Flex>
                                            </Center>
                                        </Flex>
                                    </Box>
                                    <Center>
                                        <Button variant={'ghost'}>try now</Button>
                                    </Center>
                                </Flex>
                            </Box>
                            <Box flex={1}>
                                <Flex flexDir={'column'}>
                                    <Box textAlign={props.isMobile === false && 'center'} paddingLeft={props.isMobile && 6}>
                                        <Text fontWeight={'bold'}>
                                            creativity
                                        </Text>
                                    </Box>
                                    <Box bgGradient='linear(to-r, red.300, yellow.500)' borderRadius={10}  p={1} marginLeft={6} marginRight={6} marginTop={2}>
                                        <Flex>
                                            <Center flex={1}>
                                                <Flex fontSize={'sm'} fontWeight={'bold'} flexDir={'column'}>
                                                    <Center>
                                                        <Text>CBG</Text>
                                                    </Center>
                                                    <Center>
                                                        <Text>edible</Text>
                                                    </Center>
                                                </Flex>
                                            </Center>
                                            <Center flex={1} fontWeight={'black'} fontSize={'2xl'}>
                                                +
                                            </Center>
                                            <Center flex={1}>
                                                <Flex fontSize={'sm'} fontWeight={'bold'} flexDir={'column'} paddingRight={2}>
                                                    <Center>
                                                        <Text>sativa/hybrid</Text>
                                                    </Center>
                                                    <Center>
                                                        <Text>edible</Text>
                                                    </Center>
                                                </Flex>
                                            </Center>
                                        </Flex>
                                    </Box>
                                    <Center>
                                        <Button variant={'ghost'}>try now</Button>
                                    </Center>
                                </Flex>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
                )
        }

        const ProductImage = () => {
            return (
                props.isMobile ? (
                    <Box >
                        <Center p={5}>
                            <Image h={"220px"} src={productData.productAttributes && productData.productAttributes.productImages[0] ? productData.productAttributes.productImages[0] : "https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png"} borderRadius={14}/>
                        </Center>
                    </Box>
                ) : (
                    <Box >
                        <Center p={5} >
                            <Image src={productData.productAttributes && productData.productAttributes.productImages[0] ? productData.productAttributes.productImages[0] : "https://flowerly-app-storage.s3.amazonaws.com/tempImages/sample-flower.png"} borderRadius={14}/>
                        </Center>
                    </Box>
                )
               
            )
        }

        const ProductNameBrand = () => {
            return (
                <Box>
                    <Heading>{productName}</Heading>
                    <Text fontSize={'xl'}>by {brand}</Text>
                </Box>
            )
        }

        const PurchaseOptions = () => {
            const toast = useToast()
            return (
                    <Box paddingTop={"4"}>
                        <Flex>
                            <Center flex={1} p={2} >
                                <Button variant={"outline"} h={"100%"} isFullWidth >
                                    <Stat>
                                        <Center>
                                            <StatNumber>${roundedPrice}</StatNumber>
                                        </Center>
                                        <Center>
                                            <StatHelpText>{units}</StatHelpText>
                                        </Center>
                                    </Stat>
                                </Button>
                            </Center>
                            {/* <Center flex={1} p={2}>
                                <Button variant={"outline"} h={"100%"} isFullWidth>
                                    <Stat>
                                        <Center>
                                            <StatNumber>${roundedPrice}</StatNumber>
                                        </Center>
                                        <Center>
                                            <StatHelpText>{units}</StatHelpText>
                                        </Center>
                                    </Stat>
                                </Button>
                            </Center> */}
                            <Box flex={2} p={2}>
                                {/* <Flex>
                                    <Box flex={1}></Box>
                                    <Box flex={1}>
                                        <Center>
                                            <Text fontSize={'small'}>
                                                quantity
                                            </Text>
                                        </Center>
                                        <Box>
                                            <Flex>
                                                <Center>
                                                    <Box flex={1}>
                                                        <IconButton variant="ghost" aria-label='Decrease Quantity In Cart' icon={<AiOutlineMinusCircle />}/>
                                                    </Box>
                                                    <Box flex={1} p={4}>
                                                            <Text fontWeight={'extrabold'} >
                                                                1
                                                            </Text>
                                                    </Box>
                                                    <Box flex={1}>
                                                        <IconButton variant="ghost"  aria-label='Increase Quantity In Cart' icon={<AiOutlinePlusCircle />}/>
                                                    </Box>
                                                </Center>
                                            </Flex>
                                        </Box>
                                    </Box>
                                    <Box flex={1}>
                                    </Box>
                                </Flex> */}
                                <Center textAlign={'center'}>
                                    <Button isDisabled={productId === ""} isFullWidth color={"black"} onClick={() => { 
                                        props.handleUpdateCart(productData);
                                        toast({
                                            title: `${productName} in your cart.`,
                                            description: Math.round(Math.random()) > 0 ? "Nice choice 😉" : "Good pick 👌",
                                            status: 'success',
                                            duration: 4000,
                                            isClosable: true,
                                            position:'top'
                                          }); }}>
                                        add to cart
                                    </Button>
                                </Center>  
                            </Box>
                        </Flex>
                    </Box>
            )
        }

        const MobileProductNameAmount = () => {
            const toast = useToast()
            return (
                    <Box paddingTop={"4"}>
                        <Flex flexDir={'column'}>
                            <Box flex={1}  >
                                <Flex>
                                    <Box flex={1}>
                                        <Heading>{productName}</Heading>
                                        <Text fontSize={'xl'}>by {brand}</Text>
                                    </Box>
                                    <Spacer />
                                    <Box flex={1}>
                                        <Button variant={"outline"} h={"100%"} isFullWidth >
                                            <Stat>
                                                <Center>
                                                    <StatNumber>${roundedPrice}</StatNumber>
                                                </Center>
                                                <Center>
                                                    <StatHelpText>{units}</StatHelpText>
                                                </Center>
                                            </Stat>
                                        </Button>
                                    </Box>
                                </Flex>
                            </Box>
                            <Box flex={2} paddingTop={"4"}>
                                <Center textAlign={'center'}>
                                    <Button isDisabled={productId === ""} isFullWidth color={"black"} onClick={() => { 
                                        props.handleUpdateCart(productData);
                                        toast({
                                            title: `${productName} in your cart.`,
                                            description: Math.round(Math.random()) > 0 ? "Nice choice 😉" : "Good pick 👌",
                                            status: 'success',
                                            duration: 2000,
                                            isClosable: true,
                                            position:'bottom'
                                          }); }}>
                                        add to cart
                                    </Button>
                                </Center>  
                            </Box>
                        </Flex>
                    </Box>
            )
        }

        const Summary = () => {
            return (
                    <Box>
                        <Text fontSize={'2xl'} fontWeight={'bold'}>Summary</Text>
                        <Box bg={"rgba(0,0,0,.5)"} borderRadius={14} padding={3} >
                            <Flex>
                                <Center flex={1}>
                                    <Image maxH={100} src="https://flowerly-app-storage.s3.amazonaws.com/assets/summaryIcons/sativaSun.png"/>
                                </Center>
                                <Center flex={2}>
                                    <Box>
                                        <Heading>{_.capitalize(strainType)}</Heading>
                                        <Text fontSize={'xs'}>{strainType === 'sativa' ? 'Best for calming mornings, creative focus, or a sunny hike.' : 'Best for relaxing afternoons and calming nights.'}</Text>
                                    </Box>

                                </Center>
                                <Center flex={1}>
                                    <Box>
                                        <Center>
                                            <Text fontWeight={'bold'}>effects</Text>
                                        </Center>
                                        <Center>
                                            <Image h={50} src="https://flowerly-app-storage.s3.amazonaws.com/assets/summaryIcons/sativaSun.png"/>
                                        </Center>
                                        <Center>
                                            <Text fontSize={'xs'}>energy</Text>
                                        </Center>
                                    </Box>
                                </Center>
                                <Center flex={1}>
                                    <Box>
                                        <Center>
                                            <Text fontWeight={'bold'}>aroma</Text>
                                        </Center>
                                        <Center>
                                            <Image h={50} src="https://flowerly-app-storage.s3.amazonaws.com/assets/summaryIcons/sativaSun.png"/>
                                        </Center>
                                        <Center>
                                            <Text fontSize={'xs'}>lemon</Text>
                                        </Center>
                                    </Box>
                                </Center>
                            </Flex>
                        </Box>
                    </Box>
            )
        }

        const DesktopView = () => {
            return (
                <Flex color={"white"} > 
                    <Box flex={2} p={2}>
                        <ProductImage />
                        <MatchDefault />
                    </Box>
                    <Box flex={3} p={2}>
                        <ProductNameBrand />
                        <PurchaseOptions />
                        <Summary />
                        <DescriptionDefault />
                        <Combinations />
                        <FullSpectrum />
                        <DosageUsage />
                        

                    </Box>
                    <Box flex={2} p={2}>
                        <BeginnerPotencyWarning />
                        <CannabinoidsDefault />
                        <TerpenesDefault />
                        <TerpeneIdealTaste />
                        
                        
                        
                        <Box>
                            <Text fontSize={'2xl'} fontWeight={'bold'}>Effects from Terpenes</Text>
                            <Box bg={"rgba(0,0,0,.5)"} borderRadius={14} padding={3} >
                                
                            </Box>
                        </Box>
                    
                    </Box>
                </Flex>
            )
        }

        const MobileView = () => {
            return (
            
                <Flex color={"white"} flexDir={'column'}  p={4}> 
                
                        <ProductImage />
                        <MobileProductNameAmount />
                        <Summary />
                        <DescriptionDefault />
                        <MatchDefault />
                        <Combinations />
                        
                        <FullSpectrum />
                        <DosageUsage />
                        <BeginnerPotencyWarning />
                        <CannabinoidsDefault />
                        <TerpenesDefault />
                        <TerpeneIdealTaste />
        
                        <Box>
                            <Text fontSize={'2xl'} fontWeight={'bold'}>Effects from Terpenes</Text>
                            <Box bg={"rgba(0,0,0,.5)"} borderRadius={14} padding={3} >
                                
                            </Box>
                        </Box>
                    
                    
                </Flex>
            )
        }
        
    return (
        <>
        {productData !== {} && ( props.isMobile ? <MobileView /> : <DesktopView />)}
        </>
    )
}
  
export default ProductDetail;

