const getCoords = async () => {
    const promiseLocation = new Promise((resolve, reject) => { 
      navigator.geolocation.getCurrentPosition(function(position) {
        const location = {lat: position.coords.latitude, long: position.coords.longitude}
        resolve(location)
      });
    })
    return promiseLocation;
}

const coordsToAddressViaGoogle = async (location) => {
    const reverseGeocode = (location) => {
      // console.log("WHAT IS THIS", location)
      return new Promise((resolve, reject) => {
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.lat},${location.long}&result_type=sublocality&key=AIzaSyD1JUmMby6ng_PQ1xE6TbyNEIUelJrSaiM`)
        .then(response => response.json())
        // .then(data => console.log('map data in location', data.results.length === 0))
        .then(data => (data.results.length === 0) ? resolve(false) : resolve(data.results[0]["formatted_address"]))
        .catch(error => reject(error))
      })
    }
    return reverseGeocode(location);
}
  
const getLocation = async () => {
    return getCoords.then((location) => {
      console.log("in get location", location);
      return coordsToAddressViaGoogle(location)
    });
}

export { getCoords, coordsToAddressViaGoogle, getLocation }; 