import UniversalQuestion from './UniversalQuestion';
import { medicalOptions } from './../../utilities/menuOptions'

const MedicalReasons = (props) => {
  
    return <UniversalQuestion 
              isMobile={props.isMobile} 
              handleAddUserData={props.handleAddUserData} 
              firstLineText={medicalOptions.firstLineText}
              secondLineText={medicalOptions.secondLineText} 
              answers={medicalOptions.options}
              progressValue={medicalOptions.progressValue}
    />
  }
  
  export default MedicalReasons;