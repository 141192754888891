import UniversalQuestion from './UniversalQuestion';
import { intentionsOptions } from './../../utilities/menuOptions'

const Intentions = (props) => {
    
    return <UniversalQuestion 
              isMobile={props.isMobile} 
              handleAddUserData={props.handleAddUserData} 
              firstLineText={intentionsOptions.firstLineText}
              secondLineText={intentionsOptions.secondLineText} 
              answers={intentionsOptions.options}
              extraColumns={3}
              progressValue={intentionsOptions.progressValue}
    />
  }
  
  export default Intentions;