import { useState, useEffect } from 'react';
import {
    AmplifyProvider,
    Authenticator,
    Theme
  } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css'; // default theme
import { Box, Flex, Heading, Spacer, Button, Center,  Image, } from '@chakra-ui/react'
import { Amplif, Auth } from 'aws-amplify';
import aws_exports from './../aws-exports'
Auth.configure(aws_exports)

const authConfig = {
  Auth: {

      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: 'us-east-1:97155172-6863-40e7-bdcc-45e3cb91e582',
      
      // REQUIRED - Amazon Cognito Region
      region: 'us-east-1',

      // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
      // Required only if it's different from Amazon Cognito Region
      // identityPoolRegion: 'XX-XXXX-X',

      // OPTIONAL - Amazon Cognito User Pool ID
      // userPoolId: 'us-east-1_YY0b8zW7s',

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      // userPoolWebClientId: '5b33j8ibjtt04e0qfg2n6a4vos',

  }
}

// Auth.configure(authConfig)

const AuthView = () => {
    return (
    //  <Authenticator >
     
    <Authenticator loginMechanisms={['email']} socialProviders={['google']}> 
      {({ signOut, user }) => {
        console.log('testerMcTest', user)
        return (  <main>
            <h1>Hello {user.username}</h1>
            <button onClick={signOut}>Sign out</button>
          </main>
        )}
      }
    </Authenticator>
    )
  }


export default function Authentication() {

    return (
        <AmplifyProvider >
            <Flex paddingTop={6}>
                <Box flex={1}/>
                <AuthView />
                <Box flex={1}/>
            </Flex>
        </AmplifyProvider>
    );
  }

