import UniversalQuestion from './UniversalQuestion';
import { customerType } from './../../utilities/menuOptions'

const VisitReason = (props) => {

    return <UniversalQuestion 
              isMobile={props.isMobile} 
              handleAddUserData={props.handleAddUserData} 
              firstLineText={customerType.firstLineText}
              secondLineText={customerType.secondLineText} 
              answers={customerType.options}
              progressValue={customerType.progressValue}
  
    />
  }
  
  export default VisitReason;
