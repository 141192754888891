import { Link } from "react-router-dom";
import { Box, Flex, Heading, Container, Center, Text, Image } from '@chakra-ui/react'

const UnderAge = (props) => {

    return (
      <>
        <div className={'animate__animated animate__bounceInLeft'}>
          <Flex paddingTop={20} >
            <Box minW={props.isMobile ? 3 : 100} >
            </Box>
            <Box h='25vh' flex={1} bg={"rgba(0, 0, 0, 0.4);"} maxW='sm' color="white" style={{borderRadius: '2rem'}}>
              <Container style={{paddingTop: props.isMobile ? '60px' : '7vh'}}>
                <Heading fontSize='4xl'>Oh No!</Heading>
                <Text fontSize='2xl'>Looks like you're not old enough yet - you need to be at least 18!</Text>
              </Container>
            </Box>
            <Box minW={3}>
            </Box>
          </Flex>
  
          <Flex paddingTop={8} >
            <Box flex={1}>
            </Box>
            <Center flex={props.isMobile ? 2 : 1}>
              <Image maxW={props.isMobile ? '220px' : '300px'} src="https://flowerly-app-storage.s3.amazonaws.com/assets/ageVerification/ageWarning.webp"/>
            </Center>
            <Box flex={1}>
            </Box>
          </Flex>
        <Center color={'white'}>
          <Link to="/">Back</Link>
        </Center>
        </div>
      </> 
    )
  }
  
  export default UnderAge;