import { Link } from "react-router-dom";
import { FaShoppingCart } from 'react-icons/fa'
import { Box, Flex, Heading, Spacer, Button, Center, Text, Image, Divider, Tooltip, } from '@chakra-ui/react'

const MobileFooter = () => {
    return (
        <Box position={'relative'} left={'0px'} bottom={'0px'} width={'100%'} marginTop={'50vh'} bg={"rgba(0,0,0,.5)"} color={'white'} >
            <Center p={3} fontWeight={'bold'}>
                <a target="_blank" href='https://www.flowerly.io'> Dispensaries, Brands, Investors </a>
            </Center>
            <Center p={1}>
                <a target="_blank" href='https://www.flowerly.io'>
                    <Button m={2} variant={'outline'}> 
                        learn more 
                    </Button>
                </a>
                <a target="_blank" href="mailto:contact@flowerly.io?subject=Flowerly AI Inquiry" >
                    <Button m={2} variant={'outline'}> 
                        email us
                    </Button>
                </a>
            </Center>
            <Center p={1} paddingBottom={4} m={1}>
                Copyright © 2022 by Flowerly AI Inc.
            </Center>
        </Box>
    )
} 

const DesktopFooter = () => {
    return(
        <Box position={'relative'} left={'0px'} bottom={'0px'} width={'100vw'} marginTop={'50vh'} bg={"rgba(0,0,0,.5)"} >
            <Flex flexDir={'column'}>
                <Center color={'white'} p={4}>
                    Sitemap
                </Center>
                <Box>
                    <Flex color={'white'} p={4} fontWeight={'bold'}>
                        <Center flex={1}><Link to="/">Home</Link></Center>
                        <Center flex={1}><Link to="/">Questionaire</Link></Center>
                        <Center flex={1}><Link to="/summary">Summary</Link></Center>
                        <Center flex={1}><Link to="/checkout">Cart</Link></Center>
                        <Center flex={1}><Link to="/authenticate">Login</Link></Center>
                        <Center flex={1}><Link to="/profile">Profile</Link></Center>
                    </Flex>
                </Box>
                <Center p={3}>
                    <Divider />
                </Center>
                <Box>
                    <Flex p={2} m={4}>
                        <Center >
                            <Image src='https://flowerly-app-storage.s3.amazonaws.com/logos/3D-Flowerly-Icon-md.webp' boxSize='40px' alt='Flowerly AI Logo'/>
                        </Center>
                        <Center p="1">
                            <Heading size='md' color="white">
                            flowerly.ai
                            </Heading>
                        </Center>
                        {/* <Spacer /> */}
                        <Box m={'auto'} >
                            <a target="_blank" href='https://www.flowerly.io'>
                                <Button m={2} color="white" variant={'link'} w={'120px'}> 
                                    learn more 
                                </Button>
                            </a>
                        </Box>
                        <Box m={'auto'} >
                            <a target="_blank" href="mailto:contact@flowerly.io?subject=Flowerly AI Inquiry" >
                                <Button m={2} color="white" variant={'link'} w={'120px'}> 
                                    email us
                                </Button>
                            </a>
                        </Box>
                        <Box m={'auto'} >
                            <Tooltip  label='coming soon' background={'yellow.400'} color={'blackAlpha.700'}>
                                <Button m={2} color="white" variant={'link'} w={'120px'} > 
                                    terms of use
                                </Button>
                            </Tooltip>
                        </Box>
                        <Box m={'auto'} >
                            <Tooltip hasArrow label='coming soon' background={'yellow.400'} color={'blackAlpha.700'}>
                                <Button m={2} color="white" variant={'link'} w={'120px'}> 
                                    privacy policy
                                </Button>
                            </Tooltip>
                        </Box>
                        <Box m={'auto'} color={'white'} fontWeight={'medium'}>
                            Copyright © 2022 by Flowerly AI Inc.
                        </Box>
                    </Flex>
                </Box>
            </Flex>
          
        </Box>
    )
} 

const Footer = (props) => {
    return (
        <>{props.isMobile ? <MobileFooter /> : <DesktopFooter />}</>
    )
  }

  export default Footer;