import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Progress, Flex, Heading, Spacer, Container, Center, Text, Slider, SliderThumb, SliderTrack, SliderFilledTrack, SliderMark, Tooltip, Image } from '@chakra-ui/react';
import MostImportant from "./MostImportant";

const TimeOfDay = (props) => {
    const route = props.user && (props.user.experienceLevel === "experienced") ? "/MostImportant" :  "/typeOfEffect"// check if user is experienced or not then send them to type of effect or most important
    const [timeValue, setTimeValue] = useState("day")
    const [sliderValue, setSliderValue] = useState(5)
    const [showTooltip, setShowTooltip] = useState(false)

    const firstLineText = "What time";
    const secondLineText = "of day are you thinking?"
    const detailText = "Select your preferred time of day "

    const handleTranslatedTimeUpdate = (value) => {
        console.log('handleTranslatedTimeUpdate', value)
        if (value <= 28) {
            console.log('handleTranslatedTimeUpdate', 'morning')
            setTimeValue("morning")
        } else if (value > 29 && value < 56) {
            setTimeValue(() => "day")
            console.log('handleTranslatedTimeUpdate', 'day')
        } else if (value >= 57 && value < 82) {
            setTimeValue(() => "afternoon")
            console.log('handleTranslatedTimeUpdate', 'afternoon')
        } else if (value >= 83 && value < 100) {
            setTimeValue(() => "night")
            console.log('handleTranslatedTimeUpdate', 'night')
        }
    }

    return (
      <div style={{paddingBottom: "80px" }}>
        <div className={'animate__animated animate__bounceInLeft'}>
          <Center paddingTop={props.isMobile ? 8 : 6}>
          <Progress w={props.isMobile ? '200px' : '40%' } colorScheme={'green'} size={props.isMobile && 'lg'} height={props.isMobile ? "1rem" : "32px"} value={60} borderRadius={20} bg={"rgba(0, 0, 0, 0.4);"}/>
          </Center>
          <Flex paddingTop={props.isMobile ? 4 : 10} >
            <Box minW={props.isMobile ? 3 : 100} >
            </Box>
            <Box h='25vh' flex={1} bg={"rgba(0, 0, 0, 0.4);"} maxW='sm' color="white" style={{borderRadius: '2rem'}}>
              <Container style={{paddingTop: props.isMobile ? '60px' : '7vh'}}>
                <Heading fontSize='4xl'>{firstLineText}</Heading>
                <Text fontSize='2xl'>{secondLineText}</Text>
              </Container>
            </Box>
            <Box minW={3}>
            </Box>
          </Flex>
  
          <Flex paddingTop={4} >
            <Box >
            </Box>
            {props.isMobile ? null : <Spacer />}
            <Box h='20vh' flex={1} minW='3' color="white" style={{borderRadius: '2rem', paddingLeft: '10px'}} textAlign={"-webkit-center"}>
              <Center>


                <div style={{padding: "20px"}}>
                    <Center >
                        <Image className="animate__animated animate__fadeIn animate__slow" maxW={"340px"} src={`https://flowerly-app-storage.s3.amazonaws.com/assets/timeOfDay/${timeValue}.webp`} borderRadius="full" />
                    </Center>
                  {timeValue && (
                      <Center 
                      fontWeight='semibold'
                      letterSpacing='wide'
                      fontSize='lg'>
                          {timeValue}
                      </Center>
                  )}
                </div>
              </Center>
              <Box flex={1} bg={"rgba(0, 0, 0, 0.4);"} maxW='sm' color="white" style={{borderRadius: '2rem'}} >
                <Flex paddingTop={2} padding={8} display={"inline-block"}>
                    <Box >
                    </Box>
                    <Center>
                    <Slider
                        
                        width={325}
                        id='slider'
                        defaultValue={50}
                        min={0}
                        max={100}
                        
                        colorScheme='teal'
                        onChange={(v) => setSliderValue(v)}
                        onChangeEnd={(v) => handleTranslatedTimeUpdate(v)}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                        >
                        <SliderMark value={3} mt='1' ml='-2.5' fontSize='sm' paddingTop={2}>
                            morning
                        </SliderMark>
                        <SliderMark value={30} mt='1' ml='5.5' fontSize='sm' paddingTop={2}>
                            day
                        </SliderMark>
                        <SliderMark value={60} mt='1' ml='-2.5' fontSize='sm' paddingTop={2}>
                            afternoon
                        </SliderMark>
                        
                        <SliderMark value={90} mt='1' ml='.5' fontSize='sm' paddingTop={2}>
                            night
                        </SliderMark>
                        <SliderTrack>
                            <Box position='relative'></Box>
                            <SliderFilledTrack />
                        </SliderTrack>
                        {/* <Tooltip
                            hasArrow
                            bg='teal.500'
                            color='white'
                            placement='top'
                            isOpen={showTooltip}
                            label={`${sliderValue}%`}
                        >
                            <SliderThumb />
                        </Tooltip> */}
                        <SliderThumb boxSize={6}/>
                        </Slider>
                      </Center>
                    <Box >
                    </Box>
                </Flex>  
                
              </Box>
              {/* <SimpleGrid columns={extraColumns || 2} spacing={extraSpacing || 10}>
              {answers.map((item, i) => {
                return (
                <Link key={item.displayName + i} to={item.route}>
                  <Button _hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} variant='outline' size='md' borderRadius={9} height='60px' width='100%' onClick={() => props.handleAddUserData(item.saveDataEntryValue, item.saveDataEntryName)}><Center>{item.displayName}</Center></Button>
                </Link>
                )
              })}
              </SimpleGrid> */}
            </Box>
            <Box minW={props.isMobile ? 3 : 100}>
            </Box>
          </Flex>
          <Center style={{paddingTop: "220px"}}>
            <Link to={route}>
                <Button width={props.isMobile ? "70vw" : "30vw"} style={{color: "white"}}_hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} variant={'outline'} colorScheme='grey' isDisabled={timeValue === ""} onClick={() => {props.handleAddUserData(timeValue, "preferredTimeOfDay")}}>Continue</Button>
            </Link>
          </Center>
        </div>
      </div> 
    )
  }

  export default TimeOfDay;