import UniversalQuestion from './UniversalQuestion';
import { mostImportantOptions } from './../../utilities/menuOptions'

const MostImportant = (props) => {

    return <UniversalQuestion 
              isMobile={props.isMobile} 
              handleAddUserData={props.handleAddUserData} 
              firstLineText={mostImportantOptions.firstLineText}
              secondLineText={mostImportantOptions.secondLineText} 
              answers={mostImportantOptions.options}
              progressValue={mostImportantOptions.progressValue}
  
    />
  }
  
export default MostImportant;