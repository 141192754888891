import { useState, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import { Box, Grid, Center } from '@chakra-ui/react'
import { cloneDeep, values, groupBy } from 'lodash';
import "./App.css";
import Nav from './components/Nav';
import Footer from './components/Footer';
import LocationIndicator from './components/LocationIndicator';
import Welcome from './components/Welcome';
import AgeVerify from './components/questions/AgeVerify';
import UnderAge from './components/questions/UnderAge';
import VisitReason from './components/questions/VisitReason';
import WhatExperience from './components/questions/WhatExperience';
import MedicalReasons from './components/questions/MedicalReasons';
import Intentions from './components/questions/Intentions';
import TechnicalEffects from './components/questions/TechnicalEffects';
import TimeOfDay from './components/questions/TimeOfDay';
import TypeOfEffect from './components/questions/TypeOfEffect';
import MostImportant from './components/questions/MostImportant';
import Format from './components/questions/Format';
import ConsumptionMethod from './components/questions/ConsumptionMethod';
import Summary from './components/Summary';
import SummaryPreview from './components/SummaryPreview';
import ProductDetail from './components/ProductDetail';
import {getCoords, coordsToAddressViaGoogle} from './utilities/location';
import {getInventory} from './utilities/flowerlyApi'
import Checkout from './components/Checkout';
import PlaceOrder from './components/PlaceOrder';
import CompleteOrder from './components/CompleteOrder';
import Profile from './components/AccountProfile';
import Authentication from './components/Authenticate';
import AuthenticatedUser from './components/AuthenticatedUser';
import SignOut from './components/SignOut';



function App() {
  const [width, setWidth] = useState(window.innerWidth);
  const [location, setLocation] = useState("locating...");
  const [inventory, setInventory] = useState([]);
  const [user, setUser] = useState({deviceWidth: 0, lat: "", long: "", googleCoords: ""});
  const [cart, setCart] = useState([])
  const [totalItemsInCart, setTotalItemsInCart] = useState(0)
  // const [selectedProduct, setSelectedProduct] = useState({});

  const handleAddUserData = (e, name) => {
    // console.log('in handle user add', e, name)
    let newValue = {}
    newValue[name] = e
    setUser(user => ({
      ...user, 
      ...newValue
    }))
  }

  const handleUpdateCart = (item) => {
    setTotalItemsInCart(totalItemsInCart + 1)
    let newCart = []
    newCart = cloneDeep(cart)
    
    console.log('handleUpdateCart', item)
    console.log('is this running handleUpdateCart', newCart, ':      length:', newCart.length)

    if (newCart.length > 1) {
      console.log('in the length is greater than one if ')
      newCart.every((checkedItem, i) => {
        if (item['productId'] === checkedItem['productId']) {
          console.log('greater than one in if FIRST')
          checkedItem['quantityInCart']++
          setCart(newCart)
          return;
        } else if (i === newCart.length - 1) {
          if (item['productId'] === checkedItem['productId']) {
            console.log('greater than one else if  MIDDLE NUMBER 1')
            checkedItem['quantityInCart']++
            setCart(newCart)
            return;
          } else {
            console.log('greater than one else if  MIDDLE NUMBER 2')
            item['quantityInCart'] = 1
            newCart.push(item)
            setCart(newCart)
            return;
          }
        } else {
          console.log('continue processing - hit last else in first block')
          return true;
        }
      })
    } else if (newCart.length === 1) {
      console.log('in the length is one elseif ')
        if (item['productId'] === newCart[0]['productId']) {
          console.log('in the else if if if if ')
          newCart[0]['quantityInCart']++
          setCart(newCart)
          return;
         } else {
           console.log('in the else else ')
          item['quantityInCart'] = 1
          newCart.push(item)
          setCart(newCart)
          return;
         }
    } else {
        console.log('in the length is zero else')
        item['quantityInCart'] = 1
        newCart.push(item)
    }

    setCart(newCart)
    
  }

  const handleAddInventory = (inventoryData) => {
    setInventory(inventoryData['data']);
  }

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }

  async function updateUserData() {
    handleAddUserData(width, "deviceWidth")
    // const inventoryData = await getInventory()
    const {lat, long} = await getCoords()

    handleAddUserData(lat, "lat");
    handleAddUserData(long, "long");
    // handleAddInventory(inventoryData)
    // turned off google api for testing to reduce usage
    if (lat !== "" && lat !== Number.isInteger(lat)){ 
      const googleData = await coordsToAddressViaGoogle({lat, long})
      handleAddUserData(googleData, "googleCoords")
      setLocation(googleData)
      
    }
  }

  useEffect(() => {
      updateUserData()  
      // handleLocation();
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

 

  const isMobile = width <= 768;
  // console.log('userLocation', location);

  return (
    <Grid w='100%' h={'100vh'} className="gradient-background">
      <Box w='100%' h={'100%'} className="gradient-background">
        <div >
          <Nav cart={cart} totalItemsInCart={totalItemsInCart} isMobile={isMobile} />
        </div>
        {/* <div>
          <LocationIndicator deviceLocation={location} isMobile={isMobile}/>
        </div> */}
        <Routes>
          <Route path="/" element={<Welcome isMobile={isMobile} />} />
          <Route path='/authenticate' element={<Authentication isMobile={isMobile} user={user} handleAddUserData={handleAddUserData} />} />
          <Route path="ageVerify" element={<AgeVerify isMobile={isMobile} handleAddUserData={handleAddUserData} />} />
          <Route path="visitReason" element={<VisitReason isMobile={isMobile} handleAddUserData={handleAddUserData} />} />
          <Route path="experience" element={<WhatExperience isMobile={isMobile} handleAddUserData={handleAddUserData} />} />
          <Route path="medical" element={<MedicalReasons isMobile={isMobile} handleAddUserData={handleAddUserData} />} />
          <Route path="intentions" element={<Intentions isMobile={isMobile} handleAddUserData={handleAddUserData} />} />
          <Route path="underAge" element={<UnderAge isMobile={isMobile} />} />
          <Route path="technicalEffects" element={<TechnicalEffects isMobile={isMobile} handleAddUserData={handleAddUserData} />} />
          <Route path="timeOfDay" element={<TimeOfDay isMobile={isMobile} handleAddUserData={handleAddUserData} />} />
          <Route path="typeOfEffect" element={<TypeOfEffect isMobile={isMobile} handleAddUserData={handleAddUserData} user={user}/>} />
          <Route path="mostImportant" element={<MostImportant isMobile={isMobile} handleAddUserData={handleAddUserData} />} />
          <Route path="format" element={<Format isMobile={isMobile} handleAddUserData={handleAddUserData} />} />
          <Route path="consumptionMethod" element={<ConsumptionMethod isMobile={isMobile} handleAddUserData={handleAddUserData} />} />
          <Route path="summaryPreview" element={<SummaryPreview isMobile={isMobile} user={user}  />} />
          <Route path="summary" element={<Summary isMobile={isMobile} handleAddUserData={handleAddUserData} user={user} inventory={inventory} handleAddInventory={handleAddInventory} handleUpdateCart={handleUpdateCart} />} />
          <Route path=":storeId/:productName" element={<ProductDetail isMobile={isMobile} handleAddUserData={handleAddUserData} user={user} inventory={inventory} handleUpdateCart={handleUpdateCart} cart={cart}/>} />
          <Route path="checkout" element={<Checkout isMobile={isMobile} handleUpdateCart={handleUpdateCart} cart={cart}/>} />
          <Route path="checkout/placeOrder" element={<PlaceOrder user={user} isMobile={isMobile} handleUpdateCart={handleUpdateCart} cart={cart}/>} />
          <Route path="checkout/completeOrder" element={<CompleteOrder user={user} isMobile={isMobile} cart={cart}/>} />
          <Route path="profile" element={<Profile isMobile={isMobile} user={user} cart={cart}/>} />
          <Route path="cb" element={<AuthenticatedUser isMobile={isMobile} user={user} cart={cart}/>} />
          <Route path="signout" element={<SignOut isMobile={isMobile} user={user} cart={cart}/>} />

        </Routes>
        <Footer isMobile={isMobile} />
      </Box>

    </Grid>
  );
}

export default App;
